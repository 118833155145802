/*
 * File: app.routing.module.ts                                                 *
 * Author: mafo (maximilian.fossler@teamshufflr.com)"                          *
 * Last Modified: Sat Jan 29 2022
 * -----                                                                       *
 * Copyright (C) 2021, teamshufflr                                             *
 * All rights reserved.                                                        *
 * -----                                                                       *
 * Unauthorized copying of this file, via any medium is strictly prohibited    *
 * Proprietary and confidential                                                *
 */

import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { initializeSessionUserProviderGuard } from '@teamshufflr/common/guards/initialize-session-user-provider';
import {
  TEAMSHUFFLR_PAGE,
  TsCustomPreloadingStrategy,
  TsCustomRoute,
  TsPageRouteTable,
} from '@teamshufflr/common/routing';
import { TsPermissionService } from '@teamshufflr/common/services/permission';

const routes: TsCustomRoute[] = [
  {
    path: TsPageRouteTable.get(TEAMSHUFFLR_PAGE.LANDING_PAGE).path,
    loadChildren: () =>
      import('./pages/landing-page/landing-page.module').then(
        (m) => m.TsLandingPageModule
      ),
    canActivate: [initializeSessionUserProviderGuard],
  },
  {
    path: TsPageRouteTable.get(TEAMSHUFFLR_PAGE.SIMPLE_CARDS_PAGE).path,
    loadChildren: () =>
      import('./pages/simple-cards/simple-cards.module').then(
        (m) => m.TsSimpleCardsModule
      ),
    canActivate: [initializeSessionUserProviderGuard],
  },
  {
    path: TsPageRouteTable.get(TEAMSHUFFLR_PAGE.TEAMSHUFFLR_SESSION_PAGE).path,
    loadChildren: () =>
      import(
        './pages/teamshufflr-session/teamshufflr-session-root.module'
      ).then((m) => m.TsTeamshufflrSessionRootModule),
    canActivate: [initializeSessionUserProviderGuard],
  },
  {
    path: TsPageRouteTable.get(TEAMSHUFFLR_PAGE.NOT_FOUND_PAGE).path,
    loadChildren: () =>
      import('./pages/not-found-page/not-found-page.module').then(
        (m) => m.TsNotFoundPageModule
      ),
  },
  {
    path: '**',
    redirectTo: TsPageRouteTable.get(TEAMSHUFFLR_PAGE.NOT_FOUND_PAGE).path,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabledBlocking',
      preloadingStrategy: TsCustomPreloadingStrategy,
    }),
  ],
  providers: [TsPermissionService],
  exports: [RouterModule],
})
export class TsAppRoutingModule {}
