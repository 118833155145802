/*
 * File: fadeInOutStaggeredByIndex.animation.ts                                *
 * Author: mafo (maximilian.fossler@teamshufflr.com)"                          *
 * Last Modified: Fri Dec 17 2021                                              *
 * -----                                                                       *
 * Copyright (C) 2021, teamshufflr                                             *
 * All rights reserved.                                                        *
 * -----                                                                       *
 * Unauthorized copying of this file, via any medium is strictly prohibited    *
 * Proprietary and confidential                                                *
 */

import {
  animate,
  group,
  query,
  stagger,
  style,
  transition,
  trigger,
} from '@angular/animations';

export const fadeInOutStaggeredByIndexAnimation = trigger(
  'fadeInOutStaggeredByIndex',
  [
    transition(':increment', [
      group([
        query(
          ':enter',
          [
            style({
              'z-index': 2,
            }),
            query(
              ':self, .ts-fade-element',
              [
                style({
                  opacity: 0,
                }),
                stagger(100, [
                  animate(
                    '.4s ease',
                    style({
                      opacity: 1,
                    })
                  ),
                ]),
              ],
              { optional: true }
            ),
          ],
          { optional: true }
        ),
        query(
          ':leave',
          [
            style({ position: 'absolute' }),
            query(
              ':self, .ts-fade-element',
              [
                style({
                  opacity: 1,
                }),
                stagger(100, [
                  animate(
                    '.4s ease',
                    style({
                      opacity: 0,
                    })
                  ),
                ]),
              ],
              { optional: true }
            ),
          ],
          { optional: true }
        ),
      ]),
    ]),
    transition(':decrement', [
      group([
        query(
          ':enter',
          [
            style({
              'z-index': 2,
              position: 'relative',
            }),
            query(
              ':self, .ts-fade-element',
              [
                style({
                  opacity: 0,
                }),
                stagger(100, [
                  animate(
                    '.4s ease',
                    style({
                      opacity: 1,
                    })
                  ),
                ]),
              ],
              { optional: true }
            ),
          ],
          { optional: true }
        ),
        query(
          ':leave',
          [
            style({
              position: 'absolute',
              'z-index': 1,
            }),
            query(
              ':self, .ts-fade-element',
              [
                style({
                  opacity: 1,
                }),
                stagger(100, [
                  animate(
                    '.4s ease',
                    style({
                      opacity: 0,
                    })
                  ),
                ]),
              ],
              { optional: true }
            ),
          ],
          { optional: true }
        ),
      ]),
    ]),
  ]
);
