/*
 * File: visuals.service.ts                                                    *
 * Author: mafo (maximilian.fossler@teamshufflr.com)"                          *
 * Last Modified: Fri Dec 17 2021                                              *
 * -----                                                                       *
 * Copyright (C) 2021, teamshufflr                                             *
 * All rights reserved.                                                        *
 * -----                                                                       *
 * Unauthorized copying of this file, via any medium is strictly prohibited    *
 * Proprietary and confidential                                                *
 */

import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

/**
 * All available font-families.
 */
export enum CARD_FONT_FAMILY {
  /**
   * Google Font´s `Work Sans` font.
   *
   * https://fonts.google.com/specimen/Work+Sans
   */
  WORK_SANS = 'Work Sans',

  /**
   * Google Font´s `Quicksand` font.
   *
   * https://fonts.google.com/specimen/Quicksand
   */

  QUICKSAND = 'Quicksand',
  /**
   * Google Font´s `Mali` font.
   *
   * https://fonts.google.com/specimen/Mali
   */
  MALI = 'Mali',
}

/**
 * Service for managing all settings regarding a card´s
 * visuals, e.g. the card´s primary font-family.
 */
@Injectable({
  providedIn: 'root',
})
export class TsVisualsService {
  /**
   * Whether or not the body´s content should be blurred.
   *
   * Defaults to `false`.
   */
  private readonly _blurContent = new BehaviorSubject<boolean>(false);
  /**
   * The card´s primary font-family as `BehaviorSubject`.
   *
   * Defaults to {@link CARD_FONT_FAMILY.WORK_SANS}.
   */
  private readonly _cardsFontFamily = new BehaviorSubject(
    CARD_FONT_FAMILY.WORK_SANS
  );
  /**
   * Distinct Observable of the card´s current font-family.
   */
  public get cardsFontFamily$(): Observable<CARD_FONT_FAMILY> {
    return this._cardsFontFamily.pipe(distinctUntilChanged());
  }

  /**
   * Distinct Observable of whether or not the
   * body´s content should be blurred.
   */
  public get isContentBlurred$(): Observable<boolean> {
    return this._blurContent.pipe(distinctUntilChanged());
  }

  /**
   * Changes the card´s font-family.
   *
   * @param fontFamily The card´s new font-family.
   */
  updateCardsFontFamily(fontFamily: CARD_FONT_FAMILY): void {
    this._cardsFontFamily.next(fontFamily);
  }

  /**
   * Changes the body´s content blur status.
   *
   * @param blurContent Whether or not the
   * body´s content should be blurred.
   */
  updateIsContentBlurred(blurContent: boolean): void {
    this._blurContent.next(blurContent);
  }
}
