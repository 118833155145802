import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { TsDialogModule } from '@teamshufflr/common/dialogs/dialog';
import { TsTimeAgoModule } from '@teamshufflr/common/pipes/time-ago';
import { TsTranslationModule } from '@teamshufflr/common/services/translation';
import { TsConsentBannerComponent } from './components/consent-banner/consent-banner.component';
import { TsCookieConsentSettingsComponent } from './components/cookie-consent-settings/cookie-consent-settings.component';
import { TsCookieOptions } from './interfaces/cookie-options';
import { TsCookieConsentService } from './services/cookie-consent.service';
import { TsCookieOptionsProvider } from './services/cookie-options-provider.service';
import { TsCookieWriterService } from './services/cookie-writer.service';
import { TsCookieService } from './services/cookie.service';
import { TS_COOKIE_OPTIONS, TS_COOKIE_WRITER } from './tokens';

@NgModule({
  declarations: [TsConsentBannerComponent, TsCookieConsentSettingsComponent],
  providers: [TsCookieConsentService, TsCookieOptionsProvider],
  imports: [
    CommonModule,
    MatBottomSheetModule,
    MatButtonModule,
    MatCheckboxModule,
    MatIconModule,
    MatDividerModule,
    TsDialogModule,
    TsTimeAgoModule,
    TsTranslationModule.withCustomLoader(
      (lang) => import(`../i18n/${lang}.json`),
      {
        translationScope: 'cookieConsentModule',
      }
    ),
  ],
  exports: [TsConsentBannerComponent, TsCookieConsentSettingsComponent],
})
export class TsCookieModule {
  /**
   * Use this method in your other (non root) modules to import the directive/pipe
   */
  static forChild(
    options: TsCookieOptions = {}
  ): ModuleWithProviders<TsCookieModule> {
    return TsCookieModule.forRoot(options);
  }

  /**
   * Use this method in your root module to provide the TsCookieService
   */
  static forRoot(
    options: TsCookieOptions = {}
  ): ModuleWithProviders<TsCookieModule> {
    return {
      ngModule: TsCookieModule,
      providers: [
        { provide: TS_COOKIE_OPTIONS, useValue: options },
        { provide: TS_COOKIE_WRITER, useClass: TsCookieWriterService },
        {
          provide: TsCookieService,
          useFactory: (
            cookieOptionsProvider: TsCookieOptionsProvider,
            cookieWriterService: TsCookieWriterService
          ) => new TsCookieService(cookieOptionsProvider, cookieWriterService),
          deps: [TsCookieOptionsProvider, TS_COOKIE_WRITER],
        },
      ],
    };
  }
}
