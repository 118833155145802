/*
 * File: constants.ts                                                          *
 * Author: mafo (maximilian.fossler@teamshufflr.com)"                          *
 * Last Modified: Fri Dec 17 2021                                              *
 * -----                                                                       *
 * Copyright (C) 2021, teamshufflr                                             *
 * All rights reserved.                                                        *
 * -----                                                                       *
 * Unauthorized copying of this file, via any medium is strictly prohibited    *
 * Proprietary and confidential                                                *
 */

/**
 * Array of currently supported languages.
 */
export enum SUPPORTED_LANGUAGE {
  ENGLISH = 'en',
  GERMAN = 'de',
}

export const SUPPORTED_LANGUAGES: Array<SUPPORTED_LANGUAGE> =
  Object.values(SUPPORTED_LANGUAGE);

/**
 * The minimum allowed number of participants,
 * hence the minimum amount of cards teamshufflr
 * generates.
 */
export const MIN_AMOUNT_OF_PARTICIPANTS = 5;

/**
 * The maximum allowed number of participants,
 * hence the maximum amount of cards teamshufflr
 * can generate.
 */
export const MAX_AMOUNT_OF_PARTICIPANTS = 100;

/**
 * The minimum allowed number of features a
 * card should have.
 */
export const MIN_AMOUNT_OF_FEATURES = 1;

/**
 * The maximum allowed number of features a card
 * can have.
 */
export const MAX_AMOUNT_OF_FEATURES = 4;

/**
 * The min-length of a participant´s name.
 *
 * Currently set to 1.
 */
export const PARTICIPANT_NAME_MIN_LENGTH = 1;

/**
 * The max-length of a participant´s name.
 *
 * Currently set to 25.
 */
export const PARTICIPANT_NAME_MAX_LENGTH = 25;

/**
 * Both a {@link SessionUserDatabaseModel}´s and {@link SessionDatabaseModel}´s inactivity threshold
 * in MILLISECONDS, after which a user/session is considered inactive.
 *
 * Defaults to `15 minutes`.
 */
export const DEFAULT_INACTIVITY_THRESHOLD = 15 * 60 * 1000;
