/*
 * File: permission.service.ts                                                 *
 * Author: mafo (maximilian.fossler@teamshufflr.com)"                          *
 * Last Modified: Fri Dec 17 2021                                              *
 * -----                                                                       *
 * Copyright (C) 2021, teamshufflr                                             *
 * All rights reserved.                                                        *
 * -----                                                                       *
 * Unauthorized copying of this file, via any medium is strictly prohibited    *
 * Proprietary and confidential                                                *
 */

import { Injectable, OnDestroy } from '@angular/core';
import { TsSessionProviderService } from '@teamshufflr/common/providers';
import { TsAuthenticationService } from '@teamshufflr/common/services/authentication';
import { BehaviorSubject, combineLatest, Observable, Subscription } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';

/**
 * Service for handling the authenticated user´s
 * permissions.
 */
@Injectable({
  providedIn: 'root',
})
export class TsPermissionService implements OnDestroy {
  /**
   * BehaviorSubject of whether or no the currently signed in user is the
   * host of the currently active session, initialized with `false`.
   */
  private _isSessionHost: BehaviorSubject<boolean>;
  private _subscriptions: Subscription;
  constructor(
    private sessionProviderService: TsSessionProviderService,
    private authenticationService: TsAuthenticationService
  ) {
    this._subscriptions = new Subscription();
    this._isSessionHost = new BehaviorSubject<boolean>(false);
    this._initializeIsSessionHostSubscription();
  }

  /**
   * Whether or no the currently signed in user is the
   * host of the currently active session.
   */
  isSessionHost(): boolean {
    return this._isSessionHost.value;
  }

  /**
   * Observable of whether or no the currently signed in user is the
   * host of the currently active session.
   */
  isSessionHost$(): Observable<boolean> {
    return this._isSessionHost.asObservable();
  }

  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

  private _initializeIsSessionHostSubscription(): void {
    this._subscriptions.add(
      combineLatest([
        this.sessionProviderService.currentSession$,
        this.authenticationService.currentUser$,
      ])
        .pipe(
          map(([currentSession, currentUser]) => {
            return (
              currentUser?.uid != null &&
              currentSession?.host.id === currentUser.uid
            );
          }),
          distinctUntilChanged()
        )
        .subscribe((res) => this._isSessionHost.next(res))
    );
  }
}
