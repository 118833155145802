/*
 * File: teamshufflr-page.ts
 * Author: mafo (maximilian.fossler@teamshufflr.com)"
 * Last Modified: Sat Jan 22 2022
 * -----
 * Copyright (C) 2021 - 2022, teamshufflr
 * All rights reserved.
 * -----
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

/**
 * Enum of all available {@link TsPageRouteTable} configurations
 * throughout all teamshufflr-modules.
 */
export enum TEAMSHUFFLR_PAGE {
  LANDING_PAGE,
  NOT_FOUND_PAGE,
  SIMPLE_CARDS_PAGE,
  SIMPLE_CARDS_SETTINGS_PAGE,
  SIMPLE_CARDS_RESULT_PAGE,
  SIMPLE_CARDS_RESULT_PRINT_PAGE,
  TEAMSHUFFLR_SESSION_PAGE,
  TEAMSHUFFLR_SESSION_LOBBY_PAGE,
  TEAMSHUFFLR_SESSION_SHUFFLE_TEAMS_PAGE,
  TEAMSHUFFLR_SESSION_SHUFFLE_RESULT_PAGE,
  TEAMSHUFFLR_SESSION_JOIN_PAGE,
  TEAMSHUFFLR_SESSION_CREATE_PAGE,
  TEAMSHUFFLR_SESSION_SELECT_RECENT_SESSION,
}
