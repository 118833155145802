/*
 * File: session-member-type.ts
 * Author: mafo (maximilian.fossler@teamshufflr.com)"
 * Last Modified: mafo, Thursday, 30th December 2021",
 * -----
 * Copyright (C) 2021, teamshufflr
 * All rights reserved.
 * -----
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

/**
 * The role of the user in a session.
 */
export enum SESSION_MEMBER_ROLE {
  HOST = 'host',
  DEFAULT_MEMBER = 'default_member',
}
