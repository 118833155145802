<ng-container>
  <ng-container
    *transloco="
      let t;
      read: 'profilePreviewDialogModule.TsProfilePreviewDialogComponent'
    "
  >
    <ts-dialog-content>
      <div class="ts-flex-r ts-gap-s ts-align-spaceBetween-start">
        <div
          class="ts-flex-c ts-align-start"
          *ngIf="
            sessionUserProviderService.currentUser$ | async as currentUser;
            else unknownUser
          "
        >
          <span class="mat-headline-6">
            {{ t("knownUserHeader.title", { userName: currentUser.name }) }}
          </span>
          <p>{{ t("knownUserHeader.subTitle") }}</p>
        </div>

        <ng-template #unknownUser>
          <div class="ts-flex-c ts-align-start">
            <span class="mat-headline-6">
              {{ t("unknownUserHeader.title") }}
            </span>
            <p>{{ t("unknownUserHeader.subTitle") }}</p>
          </div>
        </ng-template>
        <div
          class="ts-flex-c ts-align-start ts-rounded-card ts-light-background ts-padding-s"
        >
          <span class="mat-caption">{{ t("settingsSectionTitle") }}:</span>
          <div class="ts-flex-r ts-align-start">
            <ts-language-picker></ts-language-picker>
            <button mat-icon-button (click)="themeService.toggleTheme()">
              <mat-icon>{{
                (themeService.isDarkTheme | async) ? "light_mode" : "dark_mode"
              }}</mat-icon>
            </button>
          </div>
        </div>
      </div>

      <ts-recent-sessions-list
        (sessionSelected)="dialogRef.close()"
      ></ts-recent-sessions-list>
    </ts-dialog-content>
  </ng-container>
</ng-container>
