/*
 * File: initialize-session-user-provider.guard.ts                             *
 * Author: mafo (maximilian.fossler@teamshufflr.com)"                          *
 * Last Modified: Sun Jan 02 2022
 * -----                                                                       *
 * Copyright (C) 2021, teamshufflr                                             *
 * All rights reserved.                                                        *
 * -----                                                                       *
 * Unauthorized copying of this file, via any medium is strictly prohibited    *
 * Proprietary and confidential                                                *
 */

import { inject } from '@angular/core';
import { User } from '@angular/fire/auth';
import { CanActivateFn } from '@angular/router';
import { TsSessionUserProviderService } from '@teamshufflr/common/providers';
import { TsAuthenticationService } from '@teamshufflr/common/services/authentication';
import { filter, firstValueFrom, map } from 'rxjs';

/**
 * A Guard which immediately returns true, but ensures the {@link TsSessionUserProviderService} is properly initialized.
 *
 * This guard asynchronously awaits the first valid {@link TsAuthenticationService.currentUser$ signed in user}
 * before initializing the {@link TsSessionUserProviderService}.
 */
export const initializeSessionUserProviderGuard: CanActivateFn =
  (): boolean => {
    const sessionUserProviderService = inject(TsSessionUserProviderService);
    const authenticationService = inject(TsAuthenticationService);

    firstValueFrom(
      authenticationService.currentUser$.pipe(
        filter((user): user is User => user != null),
        map((user) => {
          sessionUserProviderService.initialize(user.uid);
          return true;
        })
      )
    );

    return true;
  };
