/*
 * File: translation.module.ts
 * Author: mafo (maximilian.fossler@teamshufflr.com)"
 * Last Modified: Wed Jan 19 2022
 * -----
 * Copyright (C) 2021 - 2022, teamshufflr
 * All rights reserved.
 * -----
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { ModuleWithProviders, NgModule } from '@angular/core';
import {
  TRANSLOCO_SCOPE,
  Translation,
  TranslocoModule,
} from '@ngneat/transloco';
import { TranslocoLocaleModule } from '@ngneat/transloco-locale';

@NgModule({
  exports: [TranslocoLocaleModule, TranslocoModule],
})
export class TsTranslationModule {
  static withCustomLoader(
    _importer: (lang: string) => Translation,
    config?: {
      translationScope?: string;
    }
  ): ModuleWithProviders<TsTranslationModule> {
    return {
      ngModule: TsTranslationModule,
      providers: [
        {
          provide: TRANSLOCO_SCOPE,
          useValue: {
            scope: config?.translationScope,
          },
        },
      ],
    };
  }
}
