/*
 * File: session-user-provider.service.ts                                      *
 * Author: mafo (maximilian.fossler@teamshufflr.com)"                          *
 * Last Modified: Sat Jan 29 2022
 * -----                                                                       *
 * Copyright (C) 2021, teamshufflr                                             *
 * All rights reserved.                                                        *
 * -----                                                                       *
 * Unauthorized copying of this file, via any medium is strictly prohibited    *
 * Proprietary and confidential                                                *
 */

import { Injectable } from '@angular/core';
import { TsSessionUserDatabaseRepository } from '@teamshufflr/common/repositories/database';
import {
  TsSessionFirestoreRepository,
  TsSessionUserFirestoreRepository,
} from '@teamshufflr/common/repositories/firestore';
import { TsAuthenticationService } from '@teamshufflr/common/services/authentication';
import {
  SessionModel,
  SessionUserDatabaseModel,
  SessionUserModel,
} from '@teamshufflr/core-api-models';
import {
  BehaviorSubject,
  firstValueFrom,
  Observable,
  Subscription,
} from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';

/**
 * Service for providing and handling the currently
 * active {@link SessionUserModel}.
 *
 * This service is provided in the application´s ROOT module.
 */
@Injectable({
  providedIn: 'root',
})
export class TsSessionUserProviderService {
  private _currentUser$: BehaviorSubject<SessionUserModel | null | undefined>;
  private _currentUserDatabaseModel$: BehaviorSubject<
    SessionUserDatabaseModel | null | undefined
  >;
  private _initialized = false;

  private _recentSessions: BehaviorSubject<Array<SessionModel> | undefined>;
  /**
   * Holds references to all subscriptions created in this provider.
   */
  private _subscriptions: Subscription;

  get currentUser(): SessionUserModel | null | undefined {
    return this._currentUser$.value;
  }

  get currentUser$(): Observable<SessionUserModel | null | undefined> {
    return this._currentUser$.asObservable();
  }

  get currentUserDatabaseModel(): SessionUserDatabaseModel | null | undefined {
    return this._currentUserDatabaseModel$.value;
  }

  get currentUserDatabaseModel$(): Observable<
    SessionUserDatabaseModel | null | undefined
  > {
    return this._currentUserDatabaseModel$.asObservable();
  }

  get initialized(): boolean {
    return this._initialized;
  }

  get recentSessions(): Array<SessionModel> | undefined {
    return this._recentSessions.value;
  }

  get recentSessions$(): Observable<Array<SessionModel> | undefined> {
    return this._recentSessions;
  }

  constructor(
    private sessionUserDatabaseRepository: TsSessionUserDatabaseRepository,
    private sessionUserFirestoreRepository: TsSessionUserFirestoreRepository,
    private sessionFirestoreRepository: TsSessionFirestoreRepository,
    private _authenticationService: TsAuthenticationService
  ) {
    this._recentSessions = new BehaviorSubject<Array<SessionModel> | undefined>(
      undefined
    );
    this._currentUser$ = new BehaviorSubject<
      SessionUserModel | null | undefined
    >(undefined);
    this._currentUserDatabaseModel$ = new BehaviorSubject<
      SessionUserDatabaseModel | null | undefined
    >(undefined);
    this._subscriptions = new Subscription();

    this._subscribeToUserChanges();
  }

  async initialize(
    userID: string,
    allowReset = true
  ): Promise<SessionUserModel> {
    if (this._initialized) {
      if (!allowReset) {
        throw `[TsSessionUserProviderService] is already initialized! \n\nTo avoid this error, call [TsSessionUserProviderService.reset] before initialization.`;
      }
      const didUserChange = this.currentUser?.id !== userID;
      if (didUserChange) {
        this.reset();
      }
    }

    this._initializeRecentSessionsSubscription();
    this._initializeUserModelSnapshotSubscription(userID);
    this._initializeUserDatabaseModelSnapshotSubscription(userID);

    const user = await firstValueFrom(
      this._currentUser$.pipe(
        filter((res): res is SessionUserModel => res !== undefined)
      )
    );
    this._initialized = true;
    return user;
  }

  /**
   * Resets this TsSessionProviderService by both unsubscribing
   * to current and re-initializing all existing Subscriptions.
   */
  reset(): void {
    this._subscriptions.unsubscribe();
    this._subscriptions = new Subscription();

    this._currentUser$.next(null);
    this._currentUserDatabaseModel$.next(null);
    this._recentSessions.next([]);

    this._initialized = false;
  }

  /**
   * Resets the {@link SessionUserDatabaseModel user´s} amount of unread-messages of the
   * {@link SessionUserChatRoomPreviewDatabaseModel chat-room preview} corresponding to the `chatRoomID`.
   *
   * @param chatRoomID
   * @param userID
   */
  async resetUnreadMessages(chatRoomID: string, userID: string) {
    await this.sessionUserDatabaseRepository.resetUnreadMessagesCounter(
      chatRoomID,
      userID
    );
  }

  async updateUser(
    user: Partial<SessionUserDatabaseModel> & { id: string }
  ): Promise<void> {
    await this.sessionUserDatabaseRepository.updateUser(user);
  }

  private _initializeRecentSessionsSubscription(): void {
    this._subscriptions.add(
      this._authenticationService.currentCustomClaims$
        .pipe(
          switchMap((claims) => {
            const currentSessionIDs = Object.keys(
              claims?.currentSessions ?? {}
            );
            const _recentSessionsObservable =
              this.sessionFirestoreRepository.getRecentSessions(
                ...currentSessionIDs
              );
            return _recentSessionsObservable;
          })
        )
        .subscribe((recentSessions) => {
          this._recentSessions.next(recentSessions);
        })
    );
  }

  private _initializeUserDatabaseModelSnapshotSubscription(
    userID: string
  ): void {
    this._subscriptions.add(
      this.sessionUserDatabaseRepository
        .getUser$(userID)
        .subscribe((user) => this._currentUserDatabaseModel$.next(user))
    );
  }

  private _initializeUserModelSnapshotSubscription(userID: string): void {
    this._subscriptions.add(
      this.sessionUserFirestoreRepository
        .getUserSnapshots(userID)
        .subscribe(this._currentUser$)
    );
  }

  private async _subscribeToUserChanges(): Promise<void> {
    this._authenticationService.currentUser$.subscribe(async (user) => {
      const newUserID = user?.uid;
      if (newUserID == null) {
        this.reset();
      } else {
        this.initialize(newUserID);
      }
    });
  }
}
