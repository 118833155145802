/*
 * File: fadeInBottomStaggered.animation.ts                                    *
 * Author: mafo (maximilian.fossler@teamshufflr.com)"                          *
 * Last Modified: Fri Dec 17 2021                                              *
 * -----                                                                       *
 * Copyright (C) 2021, teamshufflr                                             *
 * All rights reserved.                                                        *
 * -----                                                                       *
 * Unauthorized copying of this file, via any medium is strictly prohibited    *
 * Proprietary and confidential                                                *
 */

import {
  animate,
  query,
  stagger,
  style,
  transition,
  trigger,
} from '@angular/animations';

export const fadeInBottomStaggeredAnimation = trigger('fadeInBottomStaggered', [
  transition(':enter', [
    query('.ts-fade-element', [
      style({
        opacity: 0.0,
        transform: 'translateY(100px)',
      }),
      stagger(100, [
        animate(
          '200ms ease-in-out',
          style({
            opacity: 1.0,
            transform: 'translateY(0)',
          })
        ),
      ]),
    ]),
  ]),
]);
