/*
 * File: teamshufflr-logo.component.ts
 * Author: mafo (maximilian.fossler@teamshufflr.com)"
 * Last Modified: Wed Dec 29 2021
 * -----
 * Copyright (C) 2021, teamshufflr
 * All rights reserved.
 * -----
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
} from '@angular/core';

/**
 * The style of the {@link TsTeamshufflrLogoComponent logo}.
 */
export enum TEAMSHUFFLR_LOGO_STYLE {
  /**
   * Only teamshufflr icon.
   */
  ICON = 'icon',

  /**
   * The teamshufflr icon together with 'teamshufflr' as subtitle.
   */
  ICON_WITH_NAME = 'icon_with_name',
}

/**
 * Component to conveniently display the teamshufflr logo
 * in its different variants.
 *
 * The following {@link TEAMSHUFFLR_LOGO_STYLE styles} are supported:
 * - ICON
 * - ICON_WITH_NAME
 *
 * ___
 * Example usage:
 *
 * ```html
 * <ts-teamshufflr-logo
 *  [style]="'icon'"
 *  [width]="80"
 *  [height]="'auto'"
 *  [withLinkToHome]="true"
 * >
 * </ts-teamshufflr-logo>
 * ```
 */
@Component({
  selector: 'ts-teamshufflr-logo',
  templateUrl: './teamshufflr-logo.component.html',
  styleUrls: ['./teamshufflr-logo.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TsTeamshufflrLogoComponent {
  TEAMSHUFFLR_LOGO_STYLE = TEAMSHUFFLR_LOGO_STYLE;

  /**
   * The height of the logo.
   *
   * Defaults to `auto`.
   */
  @Input()
  height: string | number = 'auto';

  /**
   * The style of the logo.
   */
  @Input()
  style: TEAMSHUFFLR_LOGO_STYLE = TEAMSHUFFLR_LOGO_STYLE.ICON;

  /**
   * The width of the logo.
   *
   * Defaults to `80px`.
   */
  @Input()
  width: string | number = 80;

  /**
   * Whether or not the logo should include
   * a link to the home page.
   *
   * Defaults to `true`.
   */
  @Input()
  withLinkToHome = true;

  constructor() {}

  /**
   * Returns the `height` translated into a string-value.
   */
  @HostBinding('style.height')
  get _height() {
    return typeof this.height === 'number' ? this.height + 'px' : this.height;
  }

  /**
   * Returns the `width` translated into a string-value.
   */
  @HostBinding('style.width')
  get _width() {
    return typeof this.width === 'number' ? this.width + 'px' : this.width;
  }
}
