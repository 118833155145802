/*
 * File: sessionChatRoom.ts
 * Author: mafo (maximilian.fossler@teamshufflr.com)"
 * Last Modified: Thu Jan 20 2022
 * -----
 * Copyright (C) 2021 - 2022, teamshufflr
 * All rights reserved.
 * -----
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

/**
 * The type of the {@link SessionChatRoomModel chat-room}.
 */
export enum CHAT_ROOM_TYPE {
  LOBBY_CHAT = 'LOBBY_CHAT',
  TEAM_CHAT = 'TEAM_CHAT',
  PRIVATE_CHAT = 'PRIVATE_CHAT',
}

/**
 * A chat-room of a {@link SessionModel session} with an
 * optional {@link name} in which the {@link memberIDs members} can
 * exchange {@link SessionChatMessageDatabaseModel messages} with optional attachments.
 *
 * @see {@link SessionChatMessageDatabaseModel}
 */
export interface SessionChatRoomModel {
  /**
   * The ID of the chat-room.
   */
  id: string;
  /**
   * Timestamp of the chat-room´s creation
   * in MILLISECONDS.
   */
  createdAt: number;
  /**
   * The ID of the {@link SessionModel session} this chat room
   * belongs to.
   */
  sessionID: string;

  /**
   * Whether or not the chat-room is archived.
   *
   * Defaults to `undefined, hence false`.
   */
  archived?: boolean;

  /**
   * The type of this chat-room.
   */
  type: CHAT_ROOM_TYPE;

  /**
   * Optional name of this chat room.
   *
   * If no name is provided, the chat room is labeled
   * with a comma-separated list of the names of all members.
   */
  name?: string;

  /**
   * List of IDs of all {@link SessionUser members} in this chat-room.
   */
  memberIDs: Array<string>;
}
