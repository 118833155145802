/*
 * File: fadeInOutHeight.animation.ts                                          *
 * Author: mafo (maximilian.fossler@teamshufflr.com)"                          *
 * Last Modified: Fri Dec 17 2021                                              *
 * -----                                                                       *
 * Copyright (C) 2021, teamshufflr                                             *
 * All rights reserved.                                                        *
 * -----                                                                       *
 * Unauthorized copying of this file, via any medium is strictly prohibited    *
 * Proprietary and confidential                                                *
 */

import { animate, style, transition, trigger } from '@angular/animations';

export const fadeInOutHeightAnimation = trigger('fadeInOutHeight', [
  transition(':enter', [
    style({
      height: 0,
      'margin-top': 0,
      'margin-bottom': 0,
      'padding-top': 0,
      'padding-bottom': 0,
      opacity: 0.0,
    }),
    animate(
      '200ms ease-in-out',
      style({
        height: '*',
        'margin-top': '*',
        'margin-bottom': '*',
        'padding-top': '*',
        'padding-bottom': '*',
        opacity: 1.0,
      })
    ),
  ]),
  transition(':leave', [
    style({
      height: '*',
      'margin-top': '*',
      'margin-bottom': '*',
      'padding-top': '*',
      'padding-bottom': '*',
      opacity: 1.0,
    }),
    animate(
      '200ms ease-in-out',
      style({
        height: 0,
        'margin-top': 0,
        'margin-bottom': 0,
        'padding-top': 0,
        'padding-bottom': 0,
        opacity: 0.0,
      })
    ),
  ]),
]);
