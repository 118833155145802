<ng-container
  *transloco="
    let t;
    read: 'cookieConsentModule.TsCookieConsentSettingsComponent'
  "
>
  <span class="mat-headline-6 ts-fade-element" [innerHTML]="t('title')"></span>
  <p class="ts-fade-element">
    {{ t("description_p2") }}
  </p>
  <div class="ts-cookie-consent-settings-categories-container">
    <section
      class="ts-cookie-consent-settings-category-section"
      *ngFor="
        let cookieCategory of COOKIE_REGISTER | keyvalue;
        trackBy: trackCategorySectionByKey
      "
    >
      <div class="ts-flex-r ts-align-spaceBetween-center ts-gap-s">
        <div class="ts-flex-r ts-align-start-center ts-gap-s">
          <mat-checkbox
            color="primary"
            [disabled]="cookieCategory.key === COOKIE_CATEGORY.ESSENTIAL"
            [checked]="isCategorySelected(cookieCategory.key)"
            (change)="toggleCategorySelection(cookieCategory.key)"
          >
            <span class="subheading-2">{{
              t("categoryTitle." + cookieCategory.key)
            }}</span>
          </mat-checkbox>
        </div>
        <button
          mat-icon-button
          (click)="toggleCategoryExpansion(cookieCategory.key)"
        >
          <mat-icon
            *ngIf="expandedCategory !== cookieCategory.key; else expandLessIcon"
          >
            expand_more
          </mat-icon>
          <ng-template #expandLessIcon>
            <mat-icon>expand_less</mat-icon>
          </ng-template>
        </button>
      </div>
      <p>{{ t("categoryDescription." + cookieCategory.key) }}</p>
      <div *ngIf="expandedCategory === cookieCategory.key" @fadeInOutHeight>
        <div
          *ngFor="
            let cookieDefinition of cookieCategory.value;
            trackBy: trackCookieDefinitionByID
          "
          class="ts-padding-s"
        >
          <h4 *ngIf="cookieDefinition.name !== undefined">
            <mat-checkbox
              color="primary"
              [disabled]="cookieCategory.key === COOKIE_CATEGORY.ESSENTIAL"
              (change)="toggleCookieDefinitionSelection(cookieDefinition)"
              [checked]="isCookieDefinitionEnabled(cookieDefinition)"
            >
              {{ translateCookieDefinitionName(cookieDefinition) }}
            </mat-checkbox>
          </h4>

          <div *ngFor="let cookieInfo of cookieDefinition.cookieInfos">
            <p>
              <ng-container
                [ngTemplateOutlet]="cookieDefinitionTemplate"
                [ngTemplateOutletContext]="{
                  cookieInfo: cookieInfo
                }"
              ></ng-container>
            </p>
          </div>
        </div>
      </div>
    </section>
  </div>

  <ng-template let-cookieInfo="cookieInfo" #cookieDefinitionTemplate>
    <table>
      <tr>
        <td>{{ t("cookieName") }}:</td>
        <td>{{ cookieInfo.name }}</td>
      </tr>
      <tr>
        <td>{{ t("cookieType") }}:</td>
        <td>{{ t("cookieTypeTitle." + cookieInfo.type) }}</td>
      </tr>
      <tr>
        <td>{{ t("cookieDuration") }}:</td>
        <td>{{ cookieInfo.duration }}</td>
      </tr>
      <tr>
        <td>{{ t("cookieProvider") }}:</td>
        <td>{{ cookieInfo.providerUrl }}</td>
      </tr>
    </table>
  </ng-template>
</ng-container>
