/*
 * File: permission-service.module.ts                                          *
 * Author: mafo (maximilian.fossler@teamshufflr.com)"                          *
 * Last Modified: Fri Dec 17 2021                                              *
 * -----                                                                       *
 * Copyright (C) 2021, teamshufflr                                             *
 * All rights reserved.                                                        *
 * -----                                                                       *
 * Unauthorized copying of this file, via any medium is strictly prohibited    *
 * Proprietary and confidential                                                *
 */

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TsIsSessionHostDirective } from './directives/is-session-host.directive';
import { TsPermissionService } from './permission.service';

/**
 * Provides Directives and Services
 * for handling the authenticated user´s
 * permissions.
 *
 * ___
 * Example usage:
 *
 * ```html
 *  <!-- Render templates based on whether the
 *  authenticated user is the session´s host. -->
 *  <div *tsIsSessionHost>Content to render when the user is the host.</div
 * ```
 */
@NgModule({
  declarations: [TsIsSessionHostDirective],
  imports: [CommonModule],
  providers: [TsPermissionService],
  exports: [TsIsSessionHostDirective],
})
export class TsPermissionServiceModule {}
