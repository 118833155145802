/*
 * File: app.browser.module.ts
 * Author: mafo (maximilian.fossler@teamshufflr.com)"
 * Last Modified: Wed Jan 19 2022
 * -----
 * Copyright (C) 2021 - 2022, teamshufflr
 * All rights reserved.
 * -----
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { NgModule } from '@angular/core';
import { provideClientHydration } from '@angular/platform-browser';
import { ServiceWorkerModule } from '@angular/service-worker';
import { REQUEST } from '@nguniversal/express-engine/tokens';
import { TsRepositoriesModule } from '@teamshufflr/common/repositories';
import { TsAnalyticsModule } from '@teamshufflr/common/services/analytics';
import { TsAuthenticationRootModule } from '@teamshufflr/common/services/authentication';
import { environment } from '@teamshufflr/core-environments';
import { TsAppComponent } from './app.component';
import { TsAppModule } from './app.module';
import { TsTranslationBrowserModule } from './translation-browser.module';

// the Request object only lives on the server
export function getRequest(): any {
  return { headers: { cookie: document.cookie } };
}

/**
 * The application´s entry-component when running as Angular Universal app.
 *
 * Bootstraps the {@link TsAppComponent}.
 *
 * Must be called **`AppServerModule`**, as the [prerendering](https://angular.io/guide/prerendering)
 * of the application´s static routes would otherwise fail.
 */
@NgModule({
  imports: [
    TsAppModule,
    TsAuthenticationRootModule,
    TsTranslationBrowserModule,
    TsRepositoriesModule,
    TsAnalyticsModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
    }),
  ],
  providers: [
    provideClientHydration(),
    {
      // The server provides these in main.server
      provide: REQUEST,
      useFactory: getRequest,
    },
  ],
  bootstrap: [TsAppComponent],
})
// eslint-disable-next-line @typescript-eslint/naming-convention
export class AppBrowserModule {}
