import { Pipe, PipeTransform } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { BaseLocalePipe } from '@ngneat/transloco-locale';
const _TIME_INTERVALS: Record<string, number> = {
  year: 31536000,
  month: 2592000,
  week: 604800,
  day: 86400,
  hour: 3600,
  minute: 60,
  second: 1,
};

/**
 * A pipe for transforming a date into a human readable string of how long the provided date is ago
 * (e.g. "4 minutes ago", "2 weeks ago")
 *
 * The resulting readable string is translated using Transloco.
 *
 * ___
 * Example Usage:
 * ```html
 * {{ timestamp | timeAgo }}
 * ```
 */
@Pipe({
  name: 'timeAgo',
  pure: false,
})
export class TsTimeAgoPipe extends BaseLocalePipe implements PipeTransform {
  constructor(private translocoService: TranslocoService) {
    super();
  }

  /**
   * Transforming a date into a human readable string
   * of how long the provided date is ago (e.g. "4 minutes ago", "2 weeks ago")
   *
   * @param timestamp The timestamp to transform into a human readable string
   * (e.g. "4 minutes ago", "2 weeks ago")
   *
   * @returns
   */
  transform(timestamp: number | Date | undefined): string {
    if (timestamp) {
      const seconds = Math.floor(
        (Date.now() - new Date(timestamp).getTime()) / 1000
      );
      if (seconds < 29) {
        // less than 30 seconds ago will show as 'Just now'
        return this.translocoService.translate(
          'timeAgoModule.TsTimeAgoPipe.justNow'
        );
      }

      let counter: number;
      for (const intervalKey in _TIME_INTERVALS) {
        counter = Math.floor(seconds / _TIME_INTERVALS[intervalKey]);
        if (counter > 0)
          return this.translocoService.translate(
            `timeAgoModule.TsTimeAgoPipe.ago.${intervalKey}`,
            { value: counter }
          );
      }
    }
    return this.translocoService.translate(
      'timeAgoModule.TsTimeAgoPipe.unknownDate'
    );
  }
}
