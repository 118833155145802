/*
 * File: session.firestore.repository.ts                                       *
 * Author: mafo (maximilian.fossler@teamshufflr.com)"                          *
 * Last Modified: Sun Nov 05 2023
 * -----                                                                       *
 * Copyright (C) 2021, teamshufflr                                             *
 * All rights reserved.                                                        *
 * -----                                                                       *
 * Unauthorized copying of this file, via any medium is strictly prohibited    *
 * Proprietary and confidential                                                *
 */

import { Injectable, Optional } from '@angular/core';
import {
  CollectionReference,
  DocumentData,
  Firestore,
  collection,
  collectionData,
  collectionGroup,
  doc,
  docSnapshots,
  getDoc,
  query,
  where,
} from '@angular/fire/firestore';
import { Functions, httpsCallable } from '@angular/fire/functions';
import { TsAuthenticationService } from '@teamshufflr/common/services/authentication';
import { SessionFirestorePaths } from '@teamshufflr/core-api-firebase-paths';
import {
  CreateSessionData,
  CreateSessionResponse,
  JoinSessionData,
  SessionChatRoomModel,
  SessionModel,
  SessionUserModel,
} from '@teamshufflr/core-api-models';
import { Observable, firstValueFrom, of } from 'rxjs';
import { map } from 'rxjs/operators';

/**
 * Service for handling manipulations to {@link SessionModel}s
 * in the Firebase Firestore {@link SessionFirestorePaths.sessionCollection session collection}.
 */
@Injectable({
  providedIn: 'root',
})
export class TsSessionFirestoreRepository {
  constructor(
    private authenticationService: TsAuthenticationService,
    @Optional() private firestore?: Firestore,
    @Optional() private functions?: Functions
  ) {}

  /**
   * Creates a new session by calling the
   * `SessionService-createSession` cloud function.
   *
   * Updates the {@link AuthenticationService}´s token on success, applying
   * changes to the user´s `custom-claims`.
   *
   * @param createSessionData The {@link CreateSessionData} to create a {@link TeamshufflrSession} with.
   *
   * @returns
   */
  async createSession(
    createSessionData: CreateSessionData
  ): Promise<CreateSessionResponse> {
    if (this.functions == null) {
      throw 500;
    }

    await this.authenticationService.signInAnonymously();

    const _sessionID = await httpsCallable<
      CreateSessionData,
      CreateSessionResponse
    >(
      this.functions,
      'SessionService-createSession'
    )(createSessionData);
    await this.authenticationService.updateToken();
    return _sessionID.data;
  }

  getChatRooms$(
    userID: string,
    sessionID: string
  ): Observable<Array<SessionChatRoomModel>> {
    if (this.firestore == null) {
      return of([]);
    }

    const _ref = collectionGroup(
      this.firestore,
      SessionFirestorePaths.chatRoomCollectionSlug
    );

    return collectionData<SessionChatRoomModel>(
      query<SessionChatRoomModel, DocumentData>(
        _ref as CollectionReference<SessionChatRoomModel>,
        where('memberIDs', 'array-contains', userID),
        where('sessionID', '==', sessionID)
      )
    );
  }

  /**
   * Returns an Observable to all {@link SessionUserModel}s from the
   * {@link SessionFirestorePaths.userCollection user collection}
   * corresponding to `sessionID`.
   *
   * @param sessionID The ID of the {@link SessionModel} to return an Observable
   * from the {@link SessionFirestorePaths.userCollection user collection} to all its members.
   *
   * @returns
   */
  getMembersSnapshots(sessionID: string): Observable<Array<SessionUserModel>> {
    if (this.firestore == null) {
      return of([]);
    }
    const _ref = collection(
      this.firestore,
      SessionFirestorePaths.userCollection(sessionID)
    );

    return collectionData(_ref).pipe(
      map((result) => {
        return result.map<SessionUserModel>(
          (docSnap) => docSnap as SessionUserModel
        );
      })
    );
  }

  /**
   * Fetches all {@link SessionModel}s from the
   * {@link SessionFirestorePaths.sessionCollection session collection}
   * corresponding to `sessionIDs`.
   *
   * @param sessionIDs The IDs of the {@link SessionModel}s to fetch
   * from the {@link SessionFirestorePaths.sessionCollection session collection}.
   *
   * @returns
   */
  async getRecentSessions(
    ...sessionIDs: Array<string>
  ): Promise<Array<SessionModel>> {
    if (sessionIDs.length === 0 || this.firestore == null) {
      return [];
    }
    const _ref = collection(
      this.firestore,
      SessionFirestorePaths.sessionCollection
    );

    return firstValueFrom(
      collectionData<SessionModel>(
        query<SessionModel, DocumentData>(
          _ref as CollectionReference<SessionModel>,
          where('id', 'in', sessionIDs)
        )
      )
    ).then(
      (res) => {
        return res;
      },
      (err) => {
        throw err;
      }
    );
  }

  /**
   * Fetches a {@link SessionModel} from the
   * {@link SessionFirestorePaths.sessionCollection session collection}
   * corresponding to `sessionID`.
   *
   * @param sessionID The ID of the {@link SessionModel} to fetch
   * from the {@link SessionFirestorePaths.sessionCollection session collection}.
   *
   * @returns
   */
  async getSession(sessionID: string): Promise<SessionModel | undefined> {
    if (this.firestore == null) {
      return;
    }

    const _ref = doc(
      this.firestore,
      SessionFirestorePaths.sessionDocument(sessionID)
    );

    return getDoc(_ref).then((result) => {
      if (result.exists()) {
        return result.data() as SessionModel;
      } else {
        throw 404;
      }
    });
  }
  /**
   * Returns an Observable to a {@link SessionModel} from the
   * {@link SessionFirestorePaths.sessionCollection session collection}
   * corresponding to `sessionID`.
   *
   * @param sessionID The ID of the {@link SessionModel} to return an Observable
   * from the {@link SessionFirestorePaths.sessionCollection session collection}.
   *
   * @returns
   */
  getSessionSnapshots(sessionID: string): Observable<SessionModel | undefined> {
    if (this.firestore == null) {
      return of(undefined);
    }

    const _ref = doc(
      this.firestore,
      SessionFirestorePaths.sessionDocument(sessionID)
    );

    return docSnapshots(_ref).pipe(
      map((result) => {
        if (result.exists() === true) {
          return result.data() as SessionModel;
        } else {
          throw 404;
        }
      })
    );
  }

  /**
   * Fetches a {@link SessionUserModel} from the
   * {@link SessionFirestorePaths.userCollection user collection}
   * corresponding to `userID` and `sessionID`.
   *
   * @param userID The ID of the {@link SessionUserModel} to fetch
   * from the {@link SessionFirestorePaths.userCollection user collection}.
   * @param sessionID The ID of the {@link SessionModel}´s
   * {@link SessionFirestorePaths.userCollection user collection}.
   *
   * @returns
   */
  async getSessionUser(
    userID: string,
    sessionID: string
  ): Promise<SessionUserModel | undefined> {
    if (this.firestore == null) {
      return;
    }

    const _ref = doc(
      this.firestore,
      SessionFirestorePaths.userDocument(userID, sessionID)
    );

    return getDoc(_ref).then((result) => {
      if (result.exists()) {
        return result.data() as SessionUserModel;
      } else {
        throw 404;
      }
    });
  }

  /**
   * Returns an Observable to a {@link SessionUserModel} from the
   * {@link SessionFirestorePaths.userCollection user collection}
   * corresponding to `userID` and `sessionID`.
   *
   * @param userID The ID of the {@link SessionUserModel} to fetch
   * from the {@link SessionFirestorePaths.userCollection user collection}.
   * @param sessionID The ID of the {@link SessionModel}´s
   * {@link SessionFirestorePaths.userCollection user collection}.
   *
   * @returns
   */
  getSessionUserSnapshots(
    userID: string,
    sessionID: string
  ): Observable<SessionUserModel | undefined> {
    if (this.firestore == null) {
      return of(undefined);
    }

    const _ref = doc(
      this.firestore,
      SessionFirestorePaths.userDocument(userID, sessionID)
    );

    return docSnapshots(_ref).pipe(
      map((result) => {
        if (result.exists() === true) {
          return result.data() as SessionUserModel;
        } else {
          throw 404;
        }
      })
    );
  }

  /**
   * Joins a session by calling the
   * `SessionService-joinSession` cloud function.
   *
   * Updates the {@link AuthenticationService}´s token on success, applying
   * changes to the user´s `custom-claims`.
   *
   * @param sessionID
   * @param passphrase
   */
  async joinSession(
    sessionID: string,
    passphrase: string,
    user: Pick<SessionUserModel, 'name'>
  ): Promise<void> {
    if (this.functions == null) {
      return;
    }

    await this.authenticationService.signInAnonymously();

    await httpsCallable<JoinSessionData>(
      this.functions,
      'SessionService-joinSession'
    )({
      passphrase: passphrase,
      sessionID: sessionID,
      user: user,
    });

    await this.authenticationService.updateToken();
  }
}
