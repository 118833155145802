/*
 * File: default-transloco-config.factory.ts
 * Author: mafo (maximilian.fossler@teamshufflr.com)"
 * Last Modified: Tue Jan 25 2022
 * -----
 * Copyright (C) 2021 - 2022, teamshufflr
 * All rights reserved.
 * -----
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { TranslocoConfig, translocoConfig } from '@ngneat/transloco';
import {
  SUPPORTED_LANGUAGE,
  SUPPORTED_LANGUAGES,
} from '@teamshufflr/core-constants';
import { environment } from '@teamshufflr/core-environments';

export const DEFAULT_TRANSLOCO_CONFIG = (lang?: string): TranslocoConfig => {
  const defaultLang = SUPPORTED_LANGUAGES.find(
    (supportedLang: SUPPORTED_LANGUAGE) => supportedLang === lang
  );

  return translocoConfig({
    availableLangs: Object.values(SUPPORTED_LANGUAGE),
    fallbackLang: SUPPORTED_LANGUAGE.ENGLISH,
    defaultLang: defaultLang ?? SUPPORTED_LANGUAGE.ENGLISH,
    missingHandler: {
      allowEmpty: false,
      logMissingKey: true,
      useFallbackTranslation: true,
    },
    reRenderOnLangChange: true,
    prodMode: environment.production,
  });
};
