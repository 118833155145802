import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatRippleModule } from '@angular/material/core';
import { RouterModule } from '@angular/router';
import { TsRecentSessionOverviewCardModule } from '@teamshufflr/common/components/recent-session-overview-card';
import { TsTranslationModule } from '@teamshufflr/common/services/translation';
import { TsRecentSessionsListComponent } from './recent-sessions-list.component';

/**
 * Provides the {@link TsRecentSessionsListComponent} to conveniently display
 * all {@link SessionModel recent sessions} the currently signed in user is a member of,
 * using {@link TsSessionUserProviderService.recentSessions$}. Hereby, each session is displayed
 * as {@link TsRecentSessionOverviewCardComponent}.
 *
 * Clicking on one of the cards triggers a navigation to the desired session´s
 * {@link TEAMSHUFFLR_PAGE.TEAMSHUFFLR_SESSION_LOBBY_PAGE}.
 *
 * ___
 * Example usage:
 *
 * ```html
 * <ts-recent-sessions-list></ts-recent-sessions-list>
 * ```
 */
@NgModule({
  declarations: [TsRecentSessionsListComponent],
  imports: [
    CommonModule,
    MatRippleModule,
    RouterModule,
    TsRecentSessionOverviewCardModule,
    TsTranslationModule.withCustomLoader(
      (lang) => import(`../i18n/${lang}.json`),
      {
        translationScope: 'recentSessionsListModule',
      }
    ),
  ],
  exports: [TsRecentSessionsListComponent],
})
export class TsRecentSessionsListModule {}
