/*
 * File: session.database.repository.ts                                        *
 * Author: mafo (maximilian.fossler@teamshufflr.com)"                          *
 * Last Modified: Wed Jan 19 2022
 * -----                                                                       *
 * Copyright (C) 2021, teamshufflr                                             *
 * All rights reserved.                                                        *
 * -----                                                                       *
 * Unauthorized copying of this file, via any medium is strictly prohibited    *
 * Proprietary and confidential                                                *
 */

import { Injectable, Optional } from '@angular/core';
import {
  Database,
  child,
  get,
  onValue,
  ref,
  remove,
  set,
  update,
} from '@angular/fire/database';
import {
  SessionDatabasePaths,
  SessionTeamshufflrSettingsDatabasePaths,
} from '@teamshufflr/core-api-firebase-paths';
import {
  SessionDatabaseModel,
  SessionUserPreviewDatabaseModel,
  TeamshufflrSettingsDatabaseModel,
} from '@teamshufflr/core-api-models';
import {
  TeamshufflrParticipant,
  TeamshufflrResultFeatureSummary,
  TeamshufflrResultTeamSummary,
} from '@teamshufflr/core-interfaces';
import { Observable, of } from 'rxjs';

/**
 * Service for handling manipulations to {@link TsSessionDatabaseModel}s and all descending
 * child-documents in the Firebase Database {@link SessionDatabasePaths.sessionCollection session collection}
 */
@Injectable({
  providedIn: 'root',
})
export class TsSessionDatabaseRepository {
  constructor(@Optional() private database?: Database) {}

  /**
   * Fetches a {@link SessionDatabaseModel} from the
   * {@link SessionDatabasePaths.sessionCollection session collection}
   * corresponding to `sessionID`.
   *
   * @param sessionID The ID of the {@link SessionDatabaseModel} to fetch
   * from the {@link SessionDatabasePaths.sessionCollection session collection}.
   *
   * @returns
   */
  async getSession(sessionID: string): Promise<SessionDatabaseModel | null> {
    if (this.database == null) {
      return null;
    }

    const sessionCollectionRef = ref(
      this.database,
      SessionDatabasePaths.sessionCollection
    );

    return get(child(sessionCollectionRef, sessionID)).then((snapshot) => {
      if (snapshot.exists()) {
        return snapshot.val() as SessionDatabaseModel;
      } else {
        console.error(404);
        return null;
      }
    });
  }

  /**
   * Returns an Observable of a {@link SessionDatabaseModel} from the
   * {@link SessionDatabasePaths.sessionCollection session collection}
   * corresponding to `sessionID`.
   *
   * @param sessionID The ID of the {@link SessionDatabaseModel} to fetch
   * from the {@link SessionDatabasePaths.sessionCollection session collection}.
   *
   * @returns
   */
  getSession$(sessionID: string): Observable<SessionDatabaseModel | null> {
    if (this.database == null) {
      return of(null);
    }

    const sessionDocumentRef = ref(
      this.database,
      SessionDatabasePaths.sessionDocument(sessionID)
    );
    return new Observable<SessionDatabaseModel | null>((subscriber) => {
      const unsubscribe = onValue(
        sessionDocumentRef,
        (snapshot) => {
          subscriber.next(snapshot.val() ?? null);
        },
        (err) => {
          subscriber.error(err);
        }
      );

      subscriber.add(() => unsubscribe());
    });
  }

  /**
   * Fetches a {@link TeamshufflrSettingsDatabaseModel} from the
   * {@link SessionTeamshufflrSettingsDatabasePaths.settingsCollection settings collection}
   * corresponding to `sessionID`.
   *
   * Returns null if the desired document does not exist.
   *
   * @param sessionID The ID of the {@link TeamshufflrSettingsDatabaseModel} to fetch
   * from the {@link SessionTeamshufflrSettingsDatabasePaths.settingsCollection settings collection}.
   *
   */
  async getSessionSettings(
    sessionID: string
  ): Promise<TeamshufflrSettingsDatabaseModel | null> {
    if (this.database == null) {
      return null;
    }

    const sessionCollectionRef = ref(
      this.database,
      SessionTeamshufflrSettingsDatabasePaths.settingsCollection
    );

    return get(child(sessionCollectionRef, sessionID)).then((snapshot) => {
      if (snapshot.exists()) {
        return snapshot.val() as TeamshufflrSettingsDatabaseModel;
      } else {
        console.error(404);
        return null;
      }
    });
  }

  /**
   * Updates a {@link TeamshufflrSettingsDatabaseModel} in
   * {@link SessionTeamshufflrSettingsDatabasePaths.settingsCollection settings collection}
   *
   * @param teamshufflrSettings The {@link TeamshufflrSettingsDatabaseModel} to update in the
   * {@link SessionTeamshufflrSettingsDatabasePaths.settingsCollection settings collection}
   * @returns
   */
  async setSessionSettings(
    teamshufflrSettings: TeamshufflrSettingsDatabaseModel
  ): Promise<void> {
    if (this.database == null) {
      return;
    }

    const sessionRef = ref(
      this.database,
      SessionTeamshufflrSettingsDatabasePaths.settingsDocument(
        teamshufflrSettings.sessionID
      )
    );

    return set(sessionRef, teamshufflrSettings);
  }
  /**
   * Updates a {@link SessionDatabaseModel} in
   * {@link SessionDatabasePaths.sessionCollection session collection}
   *
   * @param session The {@link SessionDatabaseModel} to update in the
   * {@link SessionDatabasePaths.sessionCollection session collection}
   * @returns
   */
  async updateSession(session: SessionDatabaseModel): Promise<void> {
    if (this.database == null) {
      return;
    }
    const sessionRef = ref(
      this.database,
      SessionDatabasePaths.sessionDocument(session.id)
    );

    return update(sessionRef, session);
  }

  /**
   * Updates a {@link SessionUserPreviewDatabaseModel} in the
   * {@link SessionDatabasePaths.userCollection user collection}.
   *
   * @param sessionID The ID of the session this `user` belongs to.
   * @param user The {@link SessionUserPreviewDatabaseModel} to update in the
   * {@link SessionDatabasePaths.userCollection user collection}.
   *
   * @returns
   */
  async updateSessionUser(
    sessionID: string,
    user: SessionUserPreviewDatabaseModel
  ): Promise<void> {
    if (this.database == null) {
      return;
    }

    const sessionRef = ref(
      this.database,
      SessionDatabasePaths.userDocument(user.id, sessionID)
    );
    const data = {
      id: user.id,
      lastActive: Date.now(),
    };
    return update(sessionRef, data);
  }

  /**
   * Updates a {@link SessionDatabaseModel.streamingFeatureSummary} in the Firebase Database
   * by either setting or removing a {@link SessionDatabaseModel}´s corresponding
   * {@link SessionDatabasePaths.streamingFeatureSummaryDocument featureSummary document}.
   *
   * @param sessionID The ID of the session to either remove or delete from the Firebase Database.
   * @param streamingFeatureSummary The {@link SessionDatabaseModel.streamingFeatureSummary streamingFeatureSummary}
   * to either update or remove in/from the Firebase Database. If null or undefined, the session´s featureSummary is removed.
   *
   * @returns
   */
  async updateStreamingFeature(
    sessionID: string,
    streamingFeatureSummary?: TeamshufflrResultFeatureSummary
  ): Promise<void> {
    if (this.database == null) {
      return;
    }

    const featureSummaryRef = ref(
      this.database,
      SessionDatabasePaths.streamingFeatureSummaryDocument(sessionID)
    );
    if (streamingFeatureSummary != null) {
      const generatedTeams: { [key: string]: TeamshufflrResultTeamSummary } =
        {};

      for (const gimmickID in streamingFeatureSummary.generatedTeams) {
        const currentTeam = streamingFeatureSummary.generatedTeams[gimmickID];
        let membersWithIDs: Array<TeamshufflrParticipant> = [];

        if (currentTeam != null) {
          membersWithIDs = currentTeam.members.filter(
            (member): member is TeamshufflrParticipant => member.id != null
          );
        }
        generatedTeams[gimmickID] = {
          gimmickID: currentTeam.gimmickID,
          gimmickOptionID: currentTeam.gimmickOptionID,
          members: membersWithIDs,
        };
      }
      const _streamingFeatureSummary: TeamshufflrResultFeatureSummary = {
        amountOfMembersPerTeam: streamingFeatureSummary.amountOfMembersPerTeam,
        gimmickID: streamingFeatureSummary.gimmickID,
        generatedTeams,
      };
      return set(featureSummaryRef, _streamingFeatureSummary);
    } else {
      return remove(featureSummaryRef);
    }
  }
}
