/*
 * File: cookie-category.ts
 * Author: mafo (maximilian.fossler@teamshufflr.com)"
 * Last Modified: Sun Jan 23 2022
 * -----
 * Copyright (C) 2021 - 2022, teamshufflr
 * All rights reserved.
 * -----
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

/**
 * The category of a cookie
 *
 * Defines whether a cookie is optional or required.
 */
export enum COOKIE_CATEGORY {
  ESSENTIAL = 'ESSENTIAL',
  FUNCTIONAL = 'FUNCTIONAL',
  STATISTIC = 'STATISTIC',
}
