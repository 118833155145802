/*
 * File: app.module.ts                                                         *
 * Author: mafo (maximilian.fossler@teamshufflr.com)"                          *
 * Last Modified: Wed Jan 19 2022
 * -----                                                                       *
 * Copyright (C) 2021, teamshufflr                                             *
 * All rights reserved.                                                        *
 * -----                                                                       *
 * Unauthorized copying of this file, via any medium is strictly prohibited    *
 * Proprietary and confidential                                                *
 */

import { HttpClientModule } from '@angular/common/http';
import { APP_ID, APP_INITIALIZER, NgModule } from '@angular/core';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import {
  MAT_SNACK_BAR_DEFAULT_OPTIONS,
  MatSnackBarModule,
} from '@angular/material/snack-bar';
import { MatToolbarModule } from '@angular/material/toolbar';
import { BrowserModule, Meta, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TransferHttpCacheModule } from '@nguniversal/common';
import { TsLoadingWallModule } from '@teamshufflr/common/components/loading-wall';
import { TsRoutingProgressIndicatorModule } from '@teamshufflr/common/components/routing-progress-indicator';
import { TsTeamshufflrInfoModule } from '@teamshufflr/common/components/teamshufflr-info';
import { TsTeamshufflrLogoModule } from '@teamshufflr/common/components/teamshufflr-logo';
import { TsProfilePreviewDialogModule } from '@teamshufflr/common/dialogs/profile-preview';
import { TsAuthenticationService } from '@teamshufflr/common/services/authentication';
import { TsCookieModule } from '@teamshufflr/common/services/cookie';
import { TsPageIdentityModule } from '@teamshufflr/common/services/page-identity';
import { TsThemeService } from '@teamshufflr/common/services/theme-service';
import { TsTranslationRootModule } from '@teamshufflr/common/services/translation';
import { environment } from '@teamshufflr/core-environments';
import { TsAppComponent } from './app.component';
import { TsAppRoutingModule } from './app.routing.module';

const initializeTeamshufflrApp = (): (() => void) => {
  return function (): void {
    // initializeAnalyticsModule();
  };
};

/**
 * Provides the teamshufflr application´s root component that primarily
 * consists of:
 * - a MatToolbar as the applications global header.
 * - a MatDrawer consisting of the primary navigation options.
 * - a RouterOutlet of the application´s root-router.
 *
 * The drawer´s contents may eventually be blurred depending on
 * {@link TsVisualsService.isContentBlurred$}´s current value.
 *
 * @see {@link TsAppComponent}
 */
@NgModule({
  declarations: [TsAppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MatSidenavModule,
    MatToolbarModule,
    MatIconModule,
    MatButtonModule,
    MatSnackBarModule,
    TransferHttpCacheModule,
    TsAppRoutingModule,
    TsLoadingWallModule,
    TsPageIdentityModule,
    TsProfilePreviewDialogModule,
    TsRoutingProgressIndicatorModule,
    TsTeamshufflrInfoModule,
    TsTeamshufflrLogoModule,
    TsTranslationRootModule,
    TsCookieModule.forRoot(),
    provideFirebaseApp(() => initializeApp(environment.firebase)),
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initializeTeamshufflrApp,
      deps: [TsThemeService, TsAuthenticationService],
      multi: true,
    },
    Meta,
    Title,
    {
      provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
      useValue: {
        duration: 2000,
      },
    },
    {
      provide: APP_ID,
      useValue: 'teamshufflr-app',
    },
  ],
})
export class TsAppModule {
  constructor() {}
}
