<!--
File: routing-progress-indicator.component.html
Author: mafo (maximilian.fossler@teamshufflr.com)"
Last Modified: Fri Dec 17 2021
-----
Copyright (C) 2021, teamshufflr
All rights reserved.
-----
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
-->

<mat-progress-bar
  [@fadeInOut]
  *ngIf="showProgressBar | async"
  mode="indeterminate"
></mat-progress-bar>
