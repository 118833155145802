/*
 * File: loading-wall.service.ts                                               *
 * Author: mafo (maximilian.fossler@teamshufflr.com)"                          *
 * Last Modified: Fri Dec 17 2021                                              *
 * -----                                                                       *
 * Copyright (C) 2021, teamshufflr                                             *
 * All rights reserved.                                                        *
 * -----                                                                       *
 * Unauthorized copying of this file, via any medium is strictly prohibited    *
 * Proprietary and confidential                                                *
 */

import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

/**
 * The type of the `{@link TsLoadingWallComponent}.`
 * responsible for the component´s contents.
 */
export enum TS_LOADING_WALL {
  /**
   * A loading wall displayed during a
   * session`s creation process.
   */
  CREATE_SESSION = 'CREATE_SESSION',

  /**
   * A loading wall displayed during a
   * session`s joining process.
   */
  JOIN_SESSION = 'JOIN_SESSION',

  /**
   * A default loading wall that only consists
   * of the {@link TsLoadingSpinnerComponent}.
   */
  DEFAULT = 'DEFAULT',
}

/**
 * Service for showing/hiding the
 * {@link TsLoadingWallComponent}.
 */
@Injectable()
export class TsLoadingWallService {
  private _isLoadingWallVisible = new BehaviorSubject<TS_LOADING_WALL | null>(
    null
  );
  /**
   * Whether or not the {@link TsLoadingWallComponent}
   * is currently visible.
   * @returns
   */
  get isLoadingWallVisible(): TS_LOADING_WALL | null {
    return this._isLoadingWallVisible.value;
  }
  /**
   * Whether or not the {@link TsLoadingWallComponent}
   * is currently visible as distinct Observable.
   * @returns
   */
  get isLoadingWallVisible$(): Observable<TS_LOADING_WALL | null> {
    return this._isLoadingWallVisible.pipe(distinctUntilChanged());
  }
  /**
   * Hides the `{@link TsLoadingWallComponent}`
   */
  hide(): void {
    this._isLoadingWallVisible.next(null);
  }
  /**
   * Shows the `{@link TsLoadingWallComponent}` with the contents
   * corresponding to type.
   * @param type
   */
  show(type = TS_LOADING_WALL.DEFAULT): void {
    this._isLoadingWallVisible.next(type);
  }
}
