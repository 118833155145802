/*
 * File: profile-preview-dialog.module.ts
 * Author: mafo (maximilian.fossler@teamshufflr.com)"
 * Last Modified: Mon Jan 10 2022
 * -----
 * Copyright (C) 2021, teamshufflr
 * All rights reserved.
 * -----
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { TsLanguagePickerModule } from '@teamshufflr/common/components/language-picker';
import { TsRecentSessionsListModule } from '@teamshufflr/common/components/recent-sessions-list';
import { TsDialogModule } from '@teamshufflr/common/dialogs/dialog';
import { TsTranslationModule } from '@teamshufflr/common/services/translation';
import { TsOpenProfilePreviewDialogButtonComponent } from './components/open-profile-preview-dialog-button/open-profile-preview-dialog-button.component';
import { TsProfilePreviewDialogComponent } from './components/profile-preview-dialog/profile-preview-dialog.component';

/**
 * Provides a pre-configured MatIconButton to conveniently open the {@link TsProfilePreviewDialogComponent},
 * enabling the currently signed in user to preview its current profile. The dialog consists of
 * the user´s name, a list of the {@link SessionModel sessions} it currently is a member of
 * as well as buttons to both toggle the app´s theme and language.
 *
 * ___
 * Example usage:
 *
 * ```html
 * <ts-open-profile-preview-dialog-button></ts-open-profile-preview-dialog-button>
 * ```
 *
 * @see {@link TsProfilePreviewDialogComponent}
 */
@NgModule({
  declarations: [
    TsOpenProfilePreviewDialogButtonComponent,
    TsProfilePreviewDialogComponent,
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    TsDialogModule,
    TsLanguagePickerModule,
    TsRecentSessionsListModule,
    TsTranslationModule.withCustomLoader(
      (lang) => import(`../i18n/${lang}.json`),
      {
        translationScope: 'profilePreviewDialogModule',
      }
    ),
  ],
  exports: [
    TsOpenProfilePreviewDialogButtonComponent,
    TsProfilePreviewDialogComponent,
  ],
})
export class TsProfilePreviewDialogModule {}
