<!--
File: app.component.html
Author: mafo (maximilian.fossler@teamshufflr.com)"
Last Modified: Wed Oct 11 2023
-----
Copyright (C) 2021, teamshufflr
All rights reserved.
-----
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
-->

<mat-drawer-container>
  <mat-drawer #drawer mode="over" position="end">
    <div id="sidenav" *transloco="let t; read: 'sidenav'">
      <div class="ts-flex-c ts-gap-s">
        <a routerLink="/" (click)="drawer.close()">
          <button mat-button>
            <div class="ts-flex-r ts-gap-s ts-align-start-center">
              <mat-icon color="primary">home</mat-icon> {{ t("home") }}
            </div>
          </button>
        </a>
        <a
          href="https://teamshufflr.com/faq"
          target="_blank"
          (click)="drawer.close()"
        >
          <button mat-button>
            <div class="ts-flex-r ts-gap-s ts-align-start-center">
              <mat-icon color="primary">help</mat-icon>
              FAQ
            </div>
          </button>
        </a>
        <a
          href="https://teamshufflr.com/"
          target="_blank"
          (click)="drawer.close()"
        >
          <button mat-button>
            <div class="ts-flex-r ts-gap-s ts-align-start-center">
              <mat-icon color="primary">open_in_new</mat-icon>
              teamshufflr.com
            </div>
          </button>
        </a>
      </div>

      <ts-teamshufflr-info (itemClicked)="drawer.close()"></ts-teamshufflr-info>
    </div>
  </mat-drawer>
  <mat-drawer-content
    class="ts-content-may-blur"
    [ngClass]="{
      'ts-blurred-content': visualsService.isContentBlurred$ | async
    }"
  >
    <mat-toolbar class="ts-flex ts-flex-r ts-align-spaceBetween-center">
      <ts-teamshufflr-logo
        [withLinkToHome]="true"
        [width]="'auto'"
        [height]="'100%'"
      >
      </ts-teamshufflr-logo>
      <div id="toolbarActions">
        <ts-open-profile-preview-dialog-button></ts-open-profile-preview-dialog-button>
        <button (click)="drawer.toggle()" mat-icon-button class="example-icon">
          <mat-icon>menu</mat-icon>
        </button>
      </div>
    </mat-toolbar>

    <ts-routing-progress-indicator></ts-routing-progress-indicator>
    <div
      id="contentSection"
      [@routerAnimation]="routerOutlet?.activatedRouteData?.['num']"
    >
      <router-outlet #routerOutlet="outlet"></router-outlet>
    </div>
  </mat-drawer-content>
</mat-drawer-container>
<ts-loading-wall></ts-loading-wall>
