<!--
File: recent-session-overview-card.component.html
Author: mafo (maximilian.fossler@teamshufflr.com)"
Last Modified: Mon Jan 10 2022
-----
Copyright (C) 2021, teamshufflr
All rights reserved.
-----
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
-->

<ng-container
  *transloco="
    let t;
    read: 'recentSessionOverviewCardModule.TsRecentSessionOverviewCardComponent'
  "
>
  <div class="ts-flex-r ts-gap-m ts-align-spaceBetween-start">
    <h3 class="mat-headline-6 mat-h3">{{ session?.name }}</h3>
    <mat-icon color="primary">cloud_queue</mat-icon>
  </div>
  <div class="ts-flex-r ts-align-spaceBetween-end ts-gap-m">
    <span
      class="mat-caption"
      [innerHTML]="
        t('amountOfMembersDescriptionHTML', {
          amountOfMembers: session?.amountOfMembers
        })
      "
    ></span>
    <mat-icon color="primary">navigate_next</mat-icon>
  </div>
</ng-container>
