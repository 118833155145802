/*
 * File: teamshufflr-logo.module.ts
 * Author: mafo (maximilian.fossler@teamshufflr.com)"
 * Last Modified: Mon Jan 10 2022
 * -----
 * Copyright (C) 2021, teamshufflr
 * All rights reserved.
 * -----
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TsTranslationModule } from '@teamshufflr/common/services/translation';
import { TsTeamshufflrLogoComponent } from './teamshufflr-logo.component';

/**
 * Provides the {@link TsTeamshufflrLogoComponent} to conveniently
 * display the teamshufflr logo in its different variants.
 *
 * The following {@link TEAMSHUFFLR_LOGO_STYLE styles} are supported:
 * - ICON
 * - ICON_WITH_NAME
 *
 * ___
 * Example usage:
 *
 * ```html
 * <ts-teamshufflr-logo
 *  [style]="TEAMSHUFFLR_LOGO_STYLE.ICON"
 *  [width]="80"
 *  [height]="'auto'"
 *  [withLinkToHome]="true"
 * >
 * </ts-teamshufflr-logo>
 * ```
 *
 * @see {@link TsTeamshufflrLogoComponent}
 */
@NgModule({
  declarations: [TsTeamshufflrLogoComponent],
  imports: [
    CommonModule,
    RouterModule,
    TsTranslationModule.withCustomLoader(
      (lang) => import(`../i18n/${lang}.json`),
      {
        translationScope: 'teamshufflrLogoModule',
      }
    ),
  ],
  exports: [TsTeamshufflrLogoComponent],
})
export class TsTeamshufflrLogoModule {}
