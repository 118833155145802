/*
 * File: languagePicker.module.ts                                              *
 * Author: mafo (maximilian.fossler@teamshufflr.com)"                          *
 * Last Modified: Mon Jan 10 2022
 * -----                                                                       *
 * Copyright (C) 2021, teamshufflr                                             *
 * All rights reserved.                                                        *
 * -----                                                                       *
 * Unauthorized copying of this file, via any medium is strictly prohibited    *
 * Proprietary and confidential                                                *
 */

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { TsTranslationModule } from '@teamshufflr/common/services/translation';
import { TsLanguagePickerComponent } from './component/language-picker.component';

/**
 * Provides a [MatMenu] for changing the app's language.
 *
 * Currently supported languages: 'de' & 'en'
 */
@NgModule({
  declarations: [TsLanguagePickerComponent],
  imports: [
    CommonModule,
    MatSelectModule,
    MatIconModule,
    MatMenuModule,
    FormsModule,
    MatButtonModule,
    TsTranslationModule.withCustomLoader(
      (lang) => import(`../i18n/${lang}.json`),
      {
        translationScope: 'languagePickerModule',
      }
    ),
  ],
  exports: [TsLanguagePickerComponent],
  providers: [],
})
export class TsLanguagePickerModule {}
