/*
 * File: sessionUser.database.repository.ts                                    *
 * Author: mafo (maximilian.fossler@teamshufflr.com)"                          *
 * Last Modified: Wed Jan 19 2022
 * -----                                                                       *
 * Copyright (C) 2021, teamshufflr                                             *
 * All rights reserved.                                                        *
 * -----                                                                       *
 * Unauthorized copying of this file, via any medium is strictly prohibited    *
 * Proprietary and confidential                                                *
 */

import { Injectable, Optional } from '@angular/core';
import {
  Database,
  get,
  onValue,
  ref,
  remove,
  runTransaction,
  update,
} from '@angular/fire/database';
import { SessionUserDatabasePaths } from '@teamshufflr/core-api-firebase-paths';
import {
  SessionUserChatPreviewsDatabaseModel,
  SessionUserDatabaseModel,
} from '@teamshufflr/core-api-models';
import { Observable, of } from 'rxjs';

/**
 * Service for handling manipulations to {@link SessionUserDatabaseModel}s
 * in the Firebase Database {@link SessionUserDatabasePaths.userCollection user collection}.
 */
@Injectable({
  providedIn: 'root',
})
export class TsSessionUserDatabaseRepository {
  constructor(@Optional() private database?: Database) {}

  /**
   * Fetches a {@link SessionUserDatabaseModel} from the
   * {@link SessionUserDatabasePaths.userCollection user collection}
   * corresponding to `userID`.
   *
   * @param userID The ID of the {@link SessionUserDatabaseModel} to fetch
   * from the {@link SessionUserDatabasePaths.userCollection user collection}.
   *
   * @returns
   */
  async getUser(userID: string): Promise<SessionUserDatabaseModel | undefined> {
    if (this.database == null) {
      return undefined;
    }

    const userRef = ref(
      this.database,
      SessionUserDatabasePaths.userDocument(userID)
    );

    return get(userRef).then((snapshot) => {
      if (snapshot.exists()) {
        return snapshot.val() as SessionUserDatabaseModel;
      } else {
        throw 404;
      }
    });
  }

  /**
   * Returns an Observable to a {@link SessionUserDatabaseModel} from the
   * {@link SessionUserDatabasePaths.userCollection user collection}
   * corresponding to `userID`.
   *
   * @param userID The ID of the {@link SessionUserDatabaseModel} to fetch
   * from the {@link SessionUserDatabasePaths.userCollection user collection}.
   *
   * @returns
   */
  getUser$(userID: string): Observable<SessionUserDatabaseModel | null> {
    if (this.database == null) {
      return of(null);
    }

    const userDocumentRef = ref(
      this.database,
      SessionUserDatabasePaths.userDocument(userID)
    );
    return new Observable<SessionUserDatabaseModel | null>((subscriber) => {
      const unsubscribe = onValue(
        userDocumentRef,
        (snapshot) => {
          subscriber.next(snapshot.val() ?? null);
        },
        (err) => {
          subscriber.error(err);
        }
      );

      subscriber.add(() => unsubscribe());
    });
  }

  /**
   * Removes a {@link SessionUserDatabaseModel} from the
   * {@link SessionUserDatabasePaths.userCollection user collection}
   * corresponding to `userID`.
   *
   * @param userID The ID of the {@link SessionUserDatabaseModel} to remove
   * from the {@link SessionUserDatabasePaths.userCollection user collection}.
   *
   * @returns
   */
  async removeUser(userID: string): Promise<void> {
    if (this.database == null) {
      return;
    }

    const userRef = ref(
      this.database,
      SessionUserDatabasePaths.userDocument(userID)
    );
    await remove(userRef);
  }

  /**
   * Resets the {@link SessionUserDatabaseModel user´s} amount of unread-messages of the
   * {@link SessionUserChatRoomPreviewDatabaseModel chat-room preview} corresponding to the `chatRoomID`
   * by setting it´s {@link SessionUserChatRoomPreviewDatabaseModel.amountOfUnreadMessages counter-value}
   * to `0` as well as decreasing the {@link SessionUserChatPreviewsDatabaseModel.amountOfUnreadMessages total counter} with the value before.
   *
   * @param chatRoomID The ID of the {@link SessionUserChatRoomPreviewDatabaseModel chat-room preview} for which to reset the
   * {@link SessionUserChatRoomPreviewDatabaseModel.amountOfUnreadMessages amount of unread messages}.
   * @param userID The ID of the {@link SessionUserDatabaseModel session user} for which to reset the
   * {@link SessionUserChatRoomPreviewDatabaseModel.amountOfUnreadMessages amount of unread messages} corresponding to `chatRoomID`.
   */
  async resetUnreadMessagesCounter(
    chatRoomID: string,
    userID: string
  ): Promise<void> {
    if (this.database == null) {
      return;
    }

    const chatPreviewsDocumentRef = ref(
      this.database,
      SessionUserDatabasePaths.chatPreviewsDocument(userID)
    );

    await runTransaction(
      chatPreviewsDocumentRef,
      (chatPreviews: SessionUserChatPreviewsDatabaseModel) => {
        if (chatPreviews != null) {
          chatPreviews.amountOfUnreadMessages =
            chatPreviews.amountOfUnreadMessages == null
              ? 0
              : chatPreviews.amountOfUnreadMessages -
                chatPreviews.chatRooms[chatRoomID].amountOfUnreadMessages;

          chatPreviews.chatRooms[chatRoomID].amountOfUnreadMessages = 0;
        }
        return chatPreviews;
      }
    );
  }

  /**
   * Updates a {@link SessionUserDatabaseModel} in the
   * {@link SessionUserDatabasePaths.userCollection user collection}
   *
   * @param user The {@link SessionUserDatabaseModel} to update
   * in the {@link SessionUserDatabasePaths.userCollection user collection}.
   *
   * @returns
   */
  async updateUser(
    user: Partial<SessionUserDatabaseModel> & { id: string }
  ): Promise<void> {
    if (this.database == null) {
      return;
    }

    const userRef = ref(
      this.database,
      SessionUserDatabasePaths.userDocument(user.id)
    );
    await update(userRef, user);
  }
}
