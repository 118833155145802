/*
 * File: authentication.service.ts                                             *
 * Author: mafo (maximilian.fossler@teamshufflr.com)"                          *
 * Last Modified: Sun Nov 05 2023
 * -----                                                                       *
 * Copyright (C) 2021, teamshufflr                                             *
 * All rights reserved.                                                        *
 * -----                                                                       *
 * Unauthorized copying of this file, via any medium is strictly prohibited    *
 * Proprietary and confidential                                                *
 */

import { Injectable, OnDestroy, Optional } from '@angular/core';
import {
  Auth,
  IdTokenResult,
  User,
  getIdTokenResult,
  signInAnonymously,
  user,
} from '@angular/fire/auth';
import { TsAuthTokenUtil, TsCustomClaims } from '@teamshufflr/core-utils';
import {
  BehaviorSubject,
  Observable,
  Subscription,
  firstValueFrom,
} from 'rxjs';
import { filter, switchMap, tap } from 'rxjs/operators';

/**
 * Provides functions and streams regarding the client´s
 * authentication status using Firebase Authentication.
 *
 * This service is provided in the application´s `root`.
 */
@Injectable({
  providedIn: 'root',
})
export class TsAuthenticationService implements OnDestroy {
  private _currentCustomClaims$: BehaviorSubject<
    TsCustomClaims | null | undefined
  >;
  /**
   * Observable of the currently authenticated user.
   *
   * May be null, if the client is
   * not yet authenticated or signed out.
   *
   * The value may also be `undefined`, if no user
   * has been received from Firebase Authentication yet.
   */
  private _currentUser$: BehaviorSubject<User | null | undefined>;
  private _subscriptions: Subscription;
  get currentCustomClaims(): TsCustomClaims | null | undefined {
    return this._currentCustomClaims$.value;
  }

  get currentCustomClaims$(): Observable<TsCustomClaims | null | undefined> {
    return this._currentCustomClaims$.asObservable();
  }

  /**
   * The currently authenticated user.
   *
   * May be null, if the client is
   * not yet authenticated or signed out.
   *
   * The value may also be `undefined`, if no user
   * has been received from Firebase Authentication yet.
   */
  get currentUser(): User | null | undefined {
    return this._currentUser$.value;
  }

  /**
   * Observable of the currently authenticated user.
   *
   * May be null, if the client is
   * not yet authenticated or signed out.
   *
   * Filters out the {@link _currentUser$}´s initial `undefined` value
   * to ensure this stream only contains values fed by Firebase Authentication.
   */
  get currentUser$(): Observable<User | null> {
    return this._currentUser$.pipe(
      filter((user): user is User | null => user !== undefined)
    );
  }

  constructor(@Optional() private _auth?: Auth) {
    this._subscriptions = new Subscription();

    this._currentUser$ = new BehaviorSubject<User | null | undefined>(
      undefined
    );
    this._currentCustomClaims$ = new BehaviorSubject<
      TsCustomClaims | null | undefined
    >(undefined);

    this._subscribeToFirebaseAuthUser();
  }

  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }
  /**
   * Asynchronously signs in as an anonymous user.
   *
   * @returns
   */
  async signInAnonymously(): Promise<void> {
    if (this._auth != null && this.currentUser == null) {
      await signInAnonymously(this._auth);
    }
  }
  /**
   * Forces a refresh of the user´s id-token
   * to retrieve the user´s updated{@link TsCustomClaims}.
   *
   * @returns The newly refreshed & up-to-date {@link IdTokenResult}, consisting
   * the user´s {@link TsCustomClaims}.
   */
  updateToken(): Promise<IdTokenResult> {
    return firstValueFrom(
      this._currentUser$.pipe(
        filter((user): user is User => user != null),
        switchMap((user) => getIdTokenResult(user, true)),
        tap((idTokenResult) => {
          this._currentCustomClaims$.next(
            TsAuthTokenUtil.fromClaims(idTokenResult.claims).customClaims
          );
        })
      )
    );
  }

  /**
   * Subscribes to the {@link user Firebase user} to
   * feed the {@link _currentUser$} stream with the
   * current Firebase user.
   */
  private _subscribeToFirebaseAuthUser(): void {
    if (this._auth == null) {
      return;
    }

    this._subscriptions.add(
      user(this._auth).subscribe((user) => {
        console.log(user);
        this._currentUser$.next(user);
      })
    );
    this.updateToken();
  }
}
