/*
 * File: fadeParticipantsPageContents.animation.ts                             *
 * Author: mafo (maximilian.fossler@teamshufflr.com)"                          *
 * Last Modified: Fri Dec 17 2021                                              *
 * -----                                                                       *
 * Copyright (C) 2021, teamshufflr                                             *
 * All rights reserved.                                                        *
 * -----                                                                       *
 * Unauthorized copying of this file, via any medium is strictly prohibited    *
 * Proprietary and confidential                                                *
 */

import {
  animate,
  group,
  query,
  stagger,
  style,
  transition,
  trigger,
} from '@angular/animations';

export const fadeParticipantsPageContentsAnimation = trigger(
  'fadeParticipantsPageContents',
  [
    transition('named_participants => anonymous_participants', [
      group([
        query(
          ':enter',
          [
            style({
              position: 'relative',
              'z-index': 2,
            }),
            stagger(100, [
              style({
                opacity: 0.0,
              }),
              animate(
                '200ms ease-in-out',
                style({
                  opacity: 1.0,
                })
              ),
            ]),
          ],
          { optional: true }
        ),
        query(
          ':leave',
          [
            style({
              position: 'absolute',
              top: 0,
              left: 0,
              opacity: 1.0,
              'z-index': 0,
            }),
            animate(
              '200ms ease-in-out',
              style({
                opacity: 0.0,
              })
            ),
          ],
          { optional: true }
        ),
      ]),
    ]),
    transition('anonymous_participants => named_participants', [
      group([
        query(
          ':enter',
          [
            style({
              position: 'relative',
              'z-index': 2,
            }),
            stagger(100, [
              style({
                opacity: 0.0,
              }),
              animate(
                '200ms ease-in-out',
                style({
                  opacity: 1.0,
                })
              ),
            ]),
          ],
          { optional: true }
        ),
        query(
          ':leave',
          [
            style({
              position: 'absolute',
              top: 0,
              left: 0,
              opacity: 1.0,
              'z-index': 0,
            }),
            animate(
              '200ms ease-in-out',
              style({
                opacity: 0.0,
              })
            ),
          ],
          { optional: true }
        ),
      ]),
    ]),
  ]
);
