/*
 * File: auth-token.util.ts                                                    *
 * Author: mafo (maximilian.fossler@teamshufflr.com)"                          *
 * Last Modified: Sun Dec 26 2021
 * -----                                                                       *
 * Copyright (C) 2021, teamshufflr                                             *
 * All rights reserved.                                                        *
 * -----                                                                       *
 * Unauthorized copying of this file, via any medium is strictly prohibited    *
 * Proprietary and confidential                                                *
 */

import { TS_CUSTOM_CLAIM } from './enums/custom-claim';
import { SESSION_MEMBER_ROLE } from './enums/session-member-role';
import { TsCustomClaims } from './interfaces/custom-claims';

/**
 * Utility for parsing & manipulating the {@link TS_CUSTOM_CLAIM `custom claims`} set to the user´s
 * Firebase Authentication Token.
 *
 * See https://firebase.google.com/docs/auth/admin/custom-claims.
 */
export class TsAuthTokenUtil {
  static fromClaims(claims: Record<string, unknown>): TsAuthTokenUtil {
    return new TsAuthTokenUtil(claims);
  }
  customClaims: TsCustomClaims;
  get currentSessions(): Array<string> {
    return Object.keys(this.customClaims.currentSessions);
  }
  private constructor(claims: Record<string, unknown>) {
    this.customClaims = this._parseCustomClaims(claims);
  }

  removeRoleOfSession(sessionID: string): void {
    delete this.customClaims.currentSessions[sessionID];
  }

  setRoleOfSession(
    sessionID: string,
    role: SESSION_MEMBER_ROLE = SESSION_MEMBER_ROLE.HOST
  ): void {
    this.customClaims.currentSessions[sessionID] = role;
  }

  /**
   * Parses the {@link customClaims} into a Record that
   * can may be used to set the `custom claims`
   * on the user´s Firebase Authentication token.
   *
   * @returns
   */
  toCustomClaims(): Record<string, unknown> {
    return {
      [TS_CUSTOM_CLAIM.CURRENT_SESSIONS]: this.customClaims.currentSessions,
    };
  }

  /**
   * Parsing all {@link TS_CUSTOM_CLAIM custom claims} stored in the
   * `claims`.
   *
   * @param claims The Firebase Auth custom claims to parse the {@link TS_CUSTOM_CLAIM custom claims} from.
   *
   * @returns
   */
  private _parseCustomClaims(claims?: Record<string, unknown>): TsCustomClaims {
    const _currentSessionsClaim = claims?.[TS_CUSTOM_CLAIM.CURRENT_SESSIONS];

    return {
      currentSessions: (_currentSessionsClaim ?? {}) as Record<
        string,
        SESSION_MEMBER_ROLE
      >,
    };
  }
}
