import { HttpClient } from '@angular/common/http';
import {
  Injectable,
  NgModule,
  makeStateKey,
  StateKey,
  TransferState,
} from '@angular/core';
import {
  getBrowserLang,
  TranslocoLoader,
  TRANSLOCO_CONFIG,
  TRANSLOCO_LOADER,
} from '@ngneat/transloco';
import { TranslocoLoaderData } from '@ngneat/transloco/lib/transloco.loader';
import {
  COOKIE_NAME,
  TsCookieConsentService,
} from '@teamshufflr/common/services/cookie';
import { DEFAULT_TRANSLOCO_CONFIG } from '@teamshufflr/common/services/translation';
import { forkJoin, map, Observable, of } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class TsTranslocoBrowserLoaderService implements TranslocoLoader {
  constructor(private transferState: TransferState, private http: HttpClient) {}

  public getTranslation(
    lang: string,
    loaderData?: TranslocoLoaderData
  ): Observable<any> {
    const key: StateKey<number> = makeStateKey<number>(
      `transfer-translate-${lang}`
    );
    const data: any = this.transferState.get(key, null);
    if (data) {
      return of(data);
    } else {
      const base = this.http.get(`/assets/i18n/${lang}.json`);

      if (loaderData?.scope != null) {
        return base;
      } else {
        return forkJoin([
          base,
          this.http.get(`/assets/i18n/${lang}.vendor.json`),
        ]).pipe(
          map(([translation, vendor]) => {
            return { ...translation, ...vendor };
          })
        );
      }
    }
  }
}

@NgModule({
  providers: [
    {
      provide: TRANSLOCO_CONFIG,
      useFactory: (cookieConsentService: TsCookieConsentService) => {
        let defaultLang = cookieConsentService.getCookie(
          COOKIE_NAME.PREFERRED_LANGUAGE
        );

        if (defaultLang == null) {
          defaultLang = getBrowserLang();

          if (defaultLang != null) {
            cookieConsentService.updateCookie(
              COOKIE_NAME.PREFERRED_LANGUAGE,
              defaultLang
            );
          }
        }

        return DEFAULT_TRANSLOCO_CONFIG(defaultLang);
      },
      deps: [TsCookieConsentService],
    },
    {
      provide: TRANSLOCO_LOADER,
      useClass: TsTranslocoBrowserLoaderService,
      deps: [TransferState, HttpClient],
    },
  ],
})
export class TsTranslationBrowserModule {}
