import { ChangeDetectionStrategy, Component } from '@angular/core';
import { TsDialogRef } from '@teamshufflr/common/dialogs/dialog';
import { TsSessionUserProviderService } from '@teamshufflr/common/providers';
import { TsThemeService } from '@teamshufflr/common/services/theme-service';

/**
 * A dialog enabling the currently signed in user
 * to preview its current profile.
 *
 * The dialog consists of:
 * - the user´s name
 * - a list of the {@link SessionModel online-sessions} it currently is a member of
 * - buttons to both toggle the app´s theme and language
 *
 * To open the profile preview, use the
 * {@link TsOpenProfilePreviewDialogButtonComponent corresponding button}
 */
@Component({
  selector: 'ts-profile-preview-dialog',
  templateUrl: './profile-preview-dialog.component.html',
  styleUrls: ['./profile-preview-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TsProfilePreviewDialogComponent {
  constructor(
    public sessionUserProviderService: TsSessionUserProviderService,
    public themeService: TsThemeService,
    public dialogRef: TsDialogRef
  ) {}
}
