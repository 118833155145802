/*
 * File: teamshufflr-info.module.ts                                            *
 * Author: mafo (maximilian.fossler@teamshufflr.com)"                          *
 * Last Modified: Mon Jan 10 2022
 * -----                                                                       *
 * Copyright (C) 2021, teamshufflr                                             *
 * All rights reserved.                                                        *
 * -----                                                                       *
 * Unauthorized copying of this file, via any medium is strictly prohibited    *
 * Proprietary and confidential                                                *
 */

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { TsTranslationModule } from '@teamshufflr/common/services/translation';
import { TsTeamshufflrInfoComponent } from './teamshufflr-info.component';

@NgModule({
  declarations: [TsTeamshufflrInfoComponent],
  imports: [
    CommonModule,
    MatIconModule,
    MatDividerModule,
    MatButtonModule,
    RouterModule,
    TsTranslationModule.withCustomLoader(
      (lang) => import(`../i18n/${lang}.json`),
      {
        translationScope: 'teamshufflrInfoModule',
      }
    ),
  ],
  exports: [TsTeamshufflrInfoComponent],
})
export class TsTeamshufflrInfoModule {}
