/*
 * File: loading-wall.component.ts                                             *
 * Author: mafo (maximilian.fossler@teamshufflr.com)"                          *
 * Last Modified: Sat Jan 08 2022
 * -----                                                                       *
 * Copyright (C) 2021, teamshufflr                                             *
 * All rights reserved.                                                        *
 * -----                                                                       *
 * Unauthorized copying of this file, via any medium is strictly prohibited    *
 * Proprietary and confidential                                                *
 */

import { ChangeDetectionStrategy, Component } from '@angular/core';
import { fadeInOutAnimation } from '@teamshufflr/common/animations';
import { TsLoadingWallService, TS_LOADING_WALL } from './loading-wall.service';

/**
 * A wall that is overlaying the application
 * indication a loading process with dynamic contents
 * corresponding to {@link TS_LOADING_WALL}.
 *
 * The TsLoadingWallComponent is automatically shown,
 * whenever {@link TsLoadingWallService.isLoadingWallVisible} is not null.
 *
 * This component should only be used inside the application´s root component.
 */
@Component({
  selector: 'ts-loading-wall',
  templateUrl: './loading-wall.component.html',
  styleUrls: ['./loading-wall.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [fadeInOutAnimation],
})
export class TsLoadingWallComponent {
  TS_LOADING_WALL = TS_LOADING_WALL;
  constructor(public loadingWallService: TsLoadingWallService) {}
}
