/*
 * File: custom-claim.ts
 * Author: mafo (maximilian.fossler@teamshufflr.com)"
 * Last Modified: mafo, Thursday, 30th December 2021",
 * -----
 * Copyright (C) 2021, teamshufflr
 * All rights reserved.
 * -----
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

export enum TS_CUSTOM_CLAIM {
  CURRENT_SESSIONS = 'TS_CURRENT_SESSIONS',
}
