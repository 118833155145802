/*
 * File: cookie-id.ts
 * Author: mafo (maximilian.fossler@teamshufflr.com)"
 * Last Modified: Mon Jan 24 2022
 * -----
 * Copyright (C) 2021 - 2022, teamshufflr
 * All rights reserved.
 * -----
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

/**
 * A {@link TsCookieDefinition}´s unique identifier.
 */
export enum COOKIE_ID {
  ESSENTIAL_TEAMSHUFFLR_COOKIES = 'ESSENTIAL_TEAMSHUFFLR_COOKIES',
  FUNCTIONAL_TEAMSHUFFLR_COOKIES = 'FUNCTIONAL_TEAMSHUFFLR_COOKIES',
  GOOGLE_ANALYTICS_COOKIES = 'GOOGLE_ANALYTICS_COOKIES',
}
