/*
 * File: analytics.service.ts                                                  *
 * Author: mafo (maximilian.fossler@teamshufflr.com)"                          *
 * Last Modified: Wed Oct 11 2023
 * -----                                                                       *
 * Copyright (C) 2021, teamshufflr                                             *
 * All rights reserved.                                                        *
 * -----                                                                       *
 * Unauthorized copying of this file, via any medium is strictly prohibited    *
 * Proprietary and confidential                                                *
 */

import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, Injector, OnDestroy } from '@angular/core';
import {
  Analytics,
  isSupported,
  logEvent,
  setAnalyticsCollectionEnabled,
} from '@angular/fire/analytics';
import { TsCookieConsentService } from '@teamshufflr/common/services/cookie';
import { CHAT_ROOM_TYPE } from '@teamshufflr/core-api-models';
import { Subscription } from 'rxjs';
import { TeamshufflrAnalyticsEvent } from './analytics-events';
import { googleAnalyticsScript } from './googleAnalyticsScript';
/**
 * Service for logging analytics events
 * by using Firebase Analytics.
 */
@Injectable({ providedIn: 'root' })
export class TsAnalyticsService implements OnDestroy {
  private _subscriptions: Subscription;
  analytics: Analytics | null = null;

  constructor(
    private cookieConsentService: TsCookieConsentService,
    @Inject(DOCUMENT) private document: Document,
    private injector: Injector
  ) {
    isSupported().then((supported: boolean) => {
      if (supported) {
        this.analytics = this.injector.get(Analytics, null, { optional: true });
      }
    });

    this._subscriptions = new Subscription();
    this._subscribeToCookieConsent();
  }
  /**
   * Logging the event of adding a team/feature
   * to the `TeamshufflrSettings`.
   *
   * @param gimmickID The ID of the {@link Gimmick} of a {@link TeamshufflrFeature}
   * that was added to a {@link TeamshufflrSettings} object.
   * @param amountOfMembers The {@link TeamshufflrFeature.amountOfMembers amountOfMembers}
   * of a {@link TeamshufflrFeature} that was added to a {@link TeamshufflrSettings} object.
   */
  logAddFeatureEvent(gimmickID: string, amountOfMembers: number): void {
    this._logAnalyticsEvent('add-feature', {
      gimmickID,
      amountOfMembers,
    });
  }

  /**
   * Logging the event of a `TeamshufflrResult`´s
   * PDF export request.
   */
  logExportPdfEvent(): void {
    this._logAnalyticsEvent('export-pdf');
  }
  /**
   * Logging the event of importing participants
   * via file-upload
   *
   * @param amountOfParticipants The number of participants imported.
   */
  logImportParticipantsEvent(amountOfParticipants: number): void {
    this._logAnalyticsEvent('import-participants', {
      amountOfParticipants,
    });
  }

  /**
   * Logging the event of a {@link TeamshufflrSession}´s creation.
   *
   * @param amountOfParticipants The amount of participants expected to participate in the online session.
   */
  logOnlineSessionCreatedEvent(amountOfExpectedParticipants: number): void {
    this._logAnalyticsEvent('create-online-session', {
      amountOfExpectedParticipants,
    });
  }

  /**
   * Logging the event of a user´s joining to a {@link TeamshufflrSession}.
   */
  logOnlineSessionJoinedEvent(): void {
    this._logAnalyticsEvent('join-online-session');
  }

  /**
   * Logging the event of opening a {@link TeamshufflrSession} chat.
   */
  logOnlineSessionOpenedChatEvent(): void {
    this._logAnalyticsEvent('open-online-session-chat');
  }

  /**
   * Logging the event of a session-host publishing an
   * online-session´s {@link TeamshufflrResultFeatureSummary feature summary}.
   *
   * @param gimmickID The ID of the {@link Gimmick} of a {@link TeamshufflrResultFeatureSummary}
   * that was published to all session-members.
   * @param amountOfMembers The amount of members currently participating in the online session at the time of the feature-summary´s publishing.
   * @param amountOfTeams The amount teams consisted by the published feature summary.
   */
  logOnlineSessionPublishFeatureEvent(
    gimmickID: string,
    amountOfMembers: number,
    amountOfTeams: number
  ): void {
    this._logAnalyticsEvent('publish-online-session-feature', {
      gimmickID,
      amountOfMembers,
      amountOfTeams,
    });
  }

  /**
   * Logging the event of a host publishing {@link SessionChatMessageDatabaseModel chat-message}.
   *
   * @param chatRoomType The {@link CHAT_ROOM_TYPE type} of the {@link SessionChatRoomModel chat-room} the message
   * was sent to.
   */
  logOnlineSessionSendChatMessageEvent(chatRoomType: CHAT_ROOM_TYPE): void {
    this._logAnalyticsEvent('send-online-session-chat-message', {
      chatRoomType,
    });
  }

  /**
   * Logging the event of a `TeamshufflrResult`´s
   * printing request.
   */
  logPrintCardsEvent(): void {
    this._logAnalyticsEvent('print-cards');
  }

  /**
   * Logging the event of a `TeamshufflrResult`´s
   * re-shuffling.
   *
   * @param amountOfParticipants
   * @param amountOfTeams
   */
  logReShuffleCardsEvent(
    amountOfParticipants: number,
    amountOfTeams: number
  ): void {
    this._logAnalyticsEvent('re-shuffle-cards', {
      amountOfParticipants,
      amountOfTeams,
    });
  }

  /**
   * Logging the event of setting the number
   * of participants.
   * @param amountOfParticipants
   */
  logSetParticipantsEvent(amountOfParticipants: number): void {
    this._logAnalyticsEvent('set-participants', {
      amountOfParticipants,
    });
  }

  /**
   * Logging the event of a `TeamshufflrResult`´s
   * creation.
   *
   * @param amountOfParticipants
   * @param amountOfTeams
   */
  logShuffleCardsEvent(
    amountOfParticipants: number,
    amountOfTeams: number
  ): void {
    this._logAnalyticsEvent('shuffle-cards', {
      amountOfParticipants,
      amountOfTeams,
    });
  }

  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

  private async _logAnalyticsEvent(
    event: TeamshufflrAnalyticsEvent,
    data: Record<string, unknown> = {}
  ): Promise<void> {
    const isAnalyticsSupported = await isSupported();
    if (isAnalyticsSupported && this.analytics != null) {
      logEvent(this.analytics, event, {
        ...data,
      });
    }
  }

  async _setAnalyticsConsent(hasAnalyticsConsent: boolean): Promise<void> {
    const _isSupported = await isSupported();
    if (_isSupported && this.analytics != null) {
      setAnalyticsCollectionEnabled(this.analytics, hasAnalyticsConsent);

      if (hasAnalyticsConsent) {
        const scriptElement = this.document.createElement('script');
        scriptElement.innerText = googleAnalyticsScript;
        this.document.head.appendChild(scriptElement);
      }
    }
  }

  private async _subscribeToCookieConsent(): Promise<void> {
    this._subscriptions.add(
      this.cookieConsentService.cookieConsents$.subscribe((cookieConsents) => {
        this._setAnalyticsConsent(
          cookieConsents.STATISTIC.GOOGLE_ANALYTICS_COOKIES ?? false
        );
      })
    );
  }
}
