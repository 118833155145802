/*
 * File: fadeInOutWidth.animation.ts                                           *
 * Author: mafo (maximilian.fossler@teamshufflr.com)"                          *
 * Last Modified: Tue Dec 28 2021
 * -----                                                                       *
 * Copyright (C) 2021, teamshufflr                                             *
 * All rights reserved.                                                        *
 * -----                                                                       *
 * Unauthorized copying of this file, via any medium is strictly prohibited    *
 * Proprietary and confidential                                                *
 */

import { animate, style, transition, trigger } from '@angular/animations';

/**
 * Creates an animation that fades the animation´s host by animating
 * the width and all related padding/margin values.
 *
 * ___
 * Example usage:
 *
 * ```html
 * <div @fadeInOutWidth></div>
 * ```
 */
export const fadeInOutWidthAnimation = trigger('fadeInOutWidth', [
  transition(':enter', [
    style({
      opacity: 0,
      width: '0px',
      minWidth: '0px',
      marginLeft: '0px',
      marginRight: '0px',
      paddingLeft: '0px',
      paddingRight: '0px',
      overflow: 'hidden',
    }),
    animate(
      '350ms ease-in-out',
      style({
        opacity: 1.0,
        width: '*',
        minWidth: '*',
        marginLeft: '*',
        marginRight: '*',
        paddingLeft: '*',
        paddingRight: '*',
        overflow: 'hidden',
      })
    ),
  ]),
  transition(':leave', [
    style({
      opacity: 1.0,
      width: '*',
      minWidth: '*',
      marginLeft: '*',
      marginRight: '*',
      paddingLeft: '*',
      paddingRight: '*',
      overflow: 'hidden',
    }),
    animate(
      '350ms ease-in-out',
      style({
        opacity: 0,
        width: '0px',
        minWidth: '0px',
        marginLeft: '0px',
        marginRight: '0px',
        paddingLeft: '0px',
        paddingRight: '0px',
      })
    ),
  ]),
]);
