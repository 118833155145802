<!--
File: loading-spinner.component.html
Author: mafo (maximilian.fossler@teamshufflr.com)"
Last Modified: Fri Dec 17 2021
-----
Copyright (C) 2021, teamshufflr
All rights reserved.
-----
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
-->

<!--
File: loading-spinner.component.html
Author: mafo (maximilian.fossler@teamshufflr.com)"
Last Modified: Fri Dec 17 2021
-----
Copyright (C) 2021, teamshufflr
All rights reserved.
-----
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
-->

<div class="ts-loading-spinner__container ts-light-background">
  <div class="ts-loading-spinner__elementContainer">
    <div class="ts-loading-spinner__element ts-loading-spinner__hexagon"></div>
    <div class="ts-loading-spinner__element ts-loading-spinner__circle"></div>
    <div class="ts-loading-spinner__element ts-loading-spinner__triangle"></div>
    <div class="ts-loading-spinner__element ts-loading-spinner__square"></div>
  </div>
</div>
