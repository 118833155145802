/*
 * File: session-user.firestore.repository.ts                                  *
 * Author: mafo (maximilian.fossler@teamshufflr.com)"                          *
 * Last Modified: Wed Jan 19 2022
 * -----                                                                       *
 * Copyright (C) 2021, teamshufflr                                             *
 * All rights reserved.                                                        *
 * -----                                                                       *
 * Unauthorized copying of this file, via any medium is strictly prohibited    *
 * Proprietary and confidential                                                *
 */

import { Injectable, Optional } from '@angular/core';
import { Firestore, doc, docSnapshots, getDoc } from '@angular/fire/firestore';
import { SessionUserFirestorePaths } from '@teamshufflr/core-api-firebase-paths';
import { SessionUserModel } from '@teamshufflr/core-api-models';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class TsSessionUserFirestoreRepository {
  constructor(@Optional() private firestore?: Firestore) {}

  /**
   * Fetches a {@link SessionUserModel} from the
   * {@link SessionUserFirestorePaths.userCollection user collection}
   * corresponding to `sessionID`.
   *
   * @param userID The ID of the {@link SessionUserModel} to fetch
   * from the {@link SessionUserFirestorePaths.userCollection user collection}.
   *
   * @returns
   */
  async getUser(userID: string): Promise<SessionUserModel | null> {
    if (this.firestore == null) {
      return null;
    }

    const _ref = doc(
      this.firestore,
      SessionUserFirestorePaths.userDocument(userID)
    );

    return getDoc(_ref).then((result) => {
      if (result.exists()) {
        return result.data() as SessionUserModel;
      } else {
        console.error('404');
        return null;
      }
    });
  }

  /**
   * Returns an Observable to a {@link SessionUserModel} from the
   * {@link SessionUserFirestorePaths.userCollection user collection}
   * corresponding to `userID`.
   *
   * @param userID The ID of the {@link SessionUserModel} to return an Observable
   * from the {@link SessionUserFirestorePaths.userCollection user collection}.
   *
   * @returns
   */
  getUserSnapshots(userID: string): Observable<SessionUserModel | null> {
    if (this.firestore == null) {
      return of(null);
    }

    const _ref = doc(
      this.firestore,
      SessionUserFirestorePaths.userDocument(userID)
    );

    return docSnapshots(_ref).pipe(
      map((result) => {
        if (result.exists() === true) {
          return result.data() as SessionUserModel;
        } else {
          console.error('404');
          return null;
        }
      })
    );
  }
}
