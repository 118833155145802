/*
 * File: fadeInOut.animation.ts                                                *
 * Author: mafo (maximilian.fossler@teamshufflr.com)"                          *
 * Last Modified: Fri Dec 17 2021                                              *
 * -----                                                                       *
 * Copyright (C) 2021, teamshufflr                                             *
 * All rights reserved.                                                        *
 * -----                                                                       *
 * Unauthorized copying of this file, via any medium is strictly prohibited    *
 * Proprietary and confidential                                                *
 */

import { animate, style, transition, trigger } from '@angular/animations';

export const fadeInOutAnimation = trigger('fadeInOut', [
  transition(
    ':enter',
    [
      style({
        opacity: 0.0,
      }),
      animate(
        '200ms ease-in-out',
        style({
          opacity: '{{maxOpacity}}',
        })
      ),
    ],
    {
      params: {
        maxOpacity: 1.0,
      },
    }
  ),
  transition(
    ':leave',
    [
      style({
        opacity: '{{maxOpacity}}',
      }),
      animate(
        '200ms ease-in-out',
        style({
          opacity: 0,
        })
      ),
    ],
    {
      params: {
        maxOpacity: 1.0,
      },
    }
  ),
]);
