/*
 * File: app.routing.table.ts                                                  *
 * Author: mafo (maximilian.fossler@teamshufflr.com)"                          *
 * Last Modified: Sat Jan 22 2022
 * -----                                                                       *
 * Copyright (C) 2021, teamshufflr                                             *
 * All rights reserved.                                                        *
 * -----                                                                       *
 * Unauthorized copying of this file, via any medium is strictly prohibited    *
 * Proprietary and confidential                                                *
 */

import { ROUTE_PARAMETER } from './enums/route-parameter';
import { ROUTE_QUERY_PARAMETER } from './enums/route-query-parameter';
import { TEAMSHUFFLR_PAGE } from './enums/teamshufflr-page';
import { TsPageRoute } from './interfaces/page-route';

/**
 * Both the single-source-of-truth and equally a static utility class
 * regarding all route configurations for providing
 * and repository-wide access to all routes and their absolute or relative paths.
 *
 * To retrieve a {@link TsPageRoute}-configuration, use {@link TsPageRouteTable.get}.
 *
 * Te get a route´s resolved path, hence all route- & query-parameters resolved to their values,
 * use {@link TsPageRouteTable.resolve}.
 */
export abstract class TsPageRouteTable {
  /**
   * The single-source-of-truth regarding all
   * route configurations as a Map of of {@link TEAMSHUFFLR_PAGE}
   * to its corresponding config.
   */
  private static readonly _routingPaths = new Map<
    TEAMSHUFFLR_PAGE,
    TsPageRoute
  >([
    [
      TEAMSHUFFLR_PAGE.LANDING_PAGE,
      TsPageRouteTable._buildTsRoutingPath('', 1),
    ],
    [
      TEAMSHUFFLR_PAGE.SIMPLE_CARDS_PAGE,
      TsPageRouteTable._buildTsRoutingPath('cards', 2),
    ],
    [
      TEAMSHUFFLR_PAGE.SIMPLE_CARDS_SETTINGS_PAGE,
      TsPageRouteTable._buildTsRoutingPath('cards/settings', 3),
    ],
    [
      TEAMSHUFFLR_PAGE.SIMPLE_CARDS_RESULT_PAGE,
      TsPageRouteTable._buildTsRoutingPath('cards/result', 4),
    ],
    [
      TEAMSHUFFLR_PAGE.SIMPLE_CARDS_RESULT_PRINT_PAGE,
      TsPageRouteTable._buildTsRoutingPath('cards/print', 5),
    ],
    [
      TEAMSHUFFLR_PAGE.TEAMSHUFFLR_SESSION_PAGE,
      TsPageRouteTable._buildTsRoutingPath('session', 6),
    ],
    [
      TEAMSHUFFLR_PAGE.TEAMSHUFFLR_SESSION_CREATE_PAGE,
      TsPageRouteTable._buildTsRoutingPath('session/create', 7),
    ],
    [
      TEAMSHUFFLR_PAGE.TEAMSHUFFLR_SESSION_LOBBY_PAGE,
      TsPageRouteTable._buildTsRoutingPath(
        `session/:${ROUTE_PARAMETER.SESSION_ID}/lobby`,
        8
      ),
    ],
    [
      TEAMSHUFFLR_PAGE.TEAMSHUFFLR_SESSION_SHUFFLE_TEAMS_PAGE,
      TsPageRouteTable._buildTsRoutingPath(
        `session/:${ROUTE_PARAMETER.SESSION_ID}/shuffle`,
        9
      ),
    ],
    [
      TEAMSHUFFLR_PAGE.TEAMSHUFFLR_SESSION_SHUFFLE_RESULT_PAGE,
      TsPageRouteTable._buildTsRoutingPath(
        `session/:${ROUTE_PARAMETER.SESSION_ID}/result`,
        10
      ),
    ],
    [
      TEAMSHUFFLR_PAGE.TEAMSHUFFLR_SESSION_JOIN_PAGE,
      TsPageRouteTable._buildTsRoutingPath(`session/join`, 11),
    ],
    [
      TEAMSHUFFLR_PAGE.NOT_FOUND_PAGE,
      TsPageRouteTable._buildTsRoutingPath(
        `not-found`,
        Number.POSITIVE_INFINITY
      ),
    ],
  ]);
  /**
   * Returns the {@link TsPageRoute} corresponding
   * to `page`.
   *
   * @param page The {@link TEAMSHUFFLR_PAGE} for which to return the corresponding route configuration.
   * @throws Throws if no configuration for `page` was found.
   *
   * @returns
   */
  static get(page: TEAMSHUFFLR_PAGE): TsPageRoute {
    const routingPath = TsPageRouteTable._routingPaths.get(page);
    if (routingPath == null) {
      throw `Unknown TEAMSHUFFLR_PAGE <${page}>`;
    }
    return routingPath;
  }
  /**
   * Resolves the page´s route- & query-parameters to their values,
   * provided by both `params` & `queryParams`.
   *
   * @param page The {@link TEAMSHUFFLR_PAGE} which´s full path is resolved.
   * @param params Record of the route´s {@link ROUTE_PARAMETER}s to resolve.
   * @param queryParams Record of the route´s {@link ROUTE_QUERY_PARAMETER}s to resolve.
   * @returns
   */
  static resolve<
    Params = Array<ROUTE_PARAMETER>,
    QueryParams = Array<ROUTE_QUERY_PARAMETER>
  >(
    page: TEAMSHUFFLR_PAGE,
    params?: Record<keyof Params, number | string> | undefined,
    queryParams?: Record<keyof QueryParams, number | string> | undefined
  ): string {
    let _resolvedUrl = '/' + TsPageRouteTable.get(page).path;

    _resolvedUrl = Object.entries<string | number>(params ?? {}).reduce<string>(
      (url, [paramKey, paramValue]) =>
        url.replace(new RegExp(`:${paramKey}`, 'g'), paramValue.toString()),
      _resolvedUrl
    );

    _resolvedUrl = Object.entries<string | number>(
      queryParams ?? {}
    ).reduce<string>((url, [paramKey, paramValue], index) => {
      if (index === 0) {
        url += `?${paramKey}=${paramValue}`;
      } else {
        url += `&${paramKey}=${paramValue}`;
      }
      return url;
    }, _resolvedUrl);
    return _resolvedUrl;
  }
  private static _buildTsRoutingPath(
    fullPath: string,
    index: number
  ): TsPageRoute {
    const _urlTree = fullPath.split('/');
    const _slug = _urlTree[_urlTree.length - 1] ?? '';
    return {
      pageIndex: index,
      path: fullPath,
      slug: _slug,
    };
  }
}
