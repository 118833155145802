/*
 * File: recent-session-overview-card.module.ts                                *
 * Author: mafo (maximilian.fossler@teamshufflr.com)"                          *
 * Last Modified: Mon Jan 10 2022
 * -----                                                                       *
 * Copyright (C) 2021, teamshufflr                                             *
 * All rights reserved.                                                        *
 * -----                                                                       *
 * Unauthorized copying of this file, via any medium is strictly prohibited    *
 * Proprietary and confidential                                                *
 */

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { TsTranslationModule } from '@teamshufflr/common/services/translation';
import { TsRecentSessionOverviewCardComponent } from './recent-session-overview-card.component';

/**
 * Provides A card-style component consisting of a brief overview of
 * the provided {@link session} that consists of the session´s name,
 * a description of how many members are currently left.
 *
 * ___
 * Example usage:
 *
 * ```html
 *  <ts-recent-session-overview-card
 *    [session]="session"
 *   >
 *  </ts-recent-session-overview-card>
 * ```
 */
@NgModule({
  declarations: [TsRecentSessionOverviewCardComponent],
  imports: [
    CommonModule,
    MatIconModule,
    MatRippleModule,
    TsTranslationModule.withCustomLoader(
      (lang) => import(`../i18n/${lang}.json`),
      {
        translationScope: 'recentSessionOverviewCardModule',
      }
    ),
  ],
  exports: [TsRecentSessionOverviewCardComponent],
})
export class TsRecentSessionOverviewCardModule {}
