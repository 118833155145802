/*
 * File: session.database.paths.ts                                             *
 * Author: mafo (maximilian.fossler@teamshufflr.com)"                          *
 * Last Modified: Sun Jan 02 2022
 * -----                                                                       *
 * Copyright (C) 2021, teamshufflr                                             *
 * All rights reserved.                                                        *
 * -----                                                                       *
 * Unauthorized copying of this file, via any medium is strictly prohibited    *
 * Proprietary and confidential                                                *
 */

/**
 * All Firebase Database paths related to {@link SessionModel}s.
 */
export abstract class SessionDatabasePaths {
  /**
   * Firebase Database Path of the {@link SessionModel}s collection.
   */
  static sessionCollection = 'online-session/session';

  /**
   * Firebase Database Path of a single {@link SessionModel} document
   * corresponding to `sessionID`.
   *
   * @param sessionID The ID of the {@link SessionModel} to get the Firebase Database path for.
   * @returns
   */
  static sessionDocument(sessionID: string): string {
    return SessionDatabasePaths.sessionCollection + '/' + sessionID;
  }

  static streamingFeatureSummaryDocument(sessionID: string): string {
    return (
      SessionDatabasePaths.sessionCollection +
      '/' +
      sessionID +
      '/streamingFeatureSummary'
    );
  }

  /**
   * Firebase Database Path of a single {@link SessionUserPreviewDatabaseModel} document
   * corresponding to `userID`.
   *
   * @param userID The ID of the {@link SessionUserPreviewDatabaseModel} to get the Firebase Database path for.
   * @param sessionID The ID of the {@link SessionModel} the user is in.
   *
   * @returns
   */
  static userDocument(userID: string, sessionID: string): string {
    return SessionDatabasePaths.sessionDocument(sessionID) + '/users/' + userID;
  }
}
