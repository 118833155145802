import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { TsDialogContainerComponent } from './components/dialog-container/dialog-container.component';
import { TsDialogTitleComponent } from './components/dialog-title/dialog-title.component';
import { TsDialogActionsDirective } from './directives/dialog-actions.directive';
import { TsDialogCloseDirective } from './directives/dialog-close.directive';
import { TsDialogContentDirective } from './directives/dialog-content.directive';
import { TsDialog } from './services/dialog.service';

/**
 * Provides the {@link TsDialog} service that can be used to open
 * modal dialogs with teamshufflr-Design styling and animations.
 *
 * ___
 * ## Open the dialog:
 *
 * ```ts
 * tsDialog.open(YourComponent, YourDialogData, TsDialogConfig);
 * ```
 *
 * ## Example template with title, content & actions:
 * ```html
 * <!--your-dialog.component.html -->
 * <ts-dialog-title
 *  [title]="title"
 *  [leadingIcon]="leadingIcon"
 *  [trailingIcon]="trailingIcon"
 *  [iconColor]="primary"
 * >
 * </ts-dialog-title>
 *
 * <ts-dialog-content>
 *  <!-- Dialog content -->
 * </ts-dialog-content>
 *
 * <ts-dialog-actions>
 *  <button mat-button ts-dialog-close>Close</button>
 * </ts-dialog-actions>
 * ```
 *
 * @see {@link TsDialog.open}
 * @see {@link TsDialogActionsDirective}
 * @see {@link TsDialogContentDirective}
 * @see {@link TsDialogCloseDirective}
 * @see {@link TsDialogTitleComponent}
 */
@NgModule({
  declarations: [
    TsDialogContainerComponent,
    TsDialogContentDirective,
    TsDialogActionsDirective,
    TsDialogTitleComponent,
    TsDialogCloseDirective,
  ],
  imports: [CommonModule, MatIconModule, OverlayModule, PortalModule],
  providers: [TsDialog],
  exports: [
    TsDialogContainerComponent,
    TsDialogContentDirective,
    TsDialogActionsDirective,
    TsDialogTitleComponent,
    TsDialogCloseDirective,
  ],
})
export class TsDialogModule {}
