<button
  *transloco="
    let t;
    read: 'profilePreviewDialogModule.TsOpenProfilePreviewDialogButtonComponent'
  "
  mat-icon-button
  [attr.title]="t('openProfilePreviewButtonTitle')"
  [attr.aria-label]="t('openProfilePreviewButtonTitle')"
  (click)="openDialog()"
>
  <mat-icon>account_circle</mat-icon>
</button>
