<!--
File: loading-wall.component.html
Author: mafo (maximilian.fossler@teamshufflr.com)"
Last Modified: Mon Jan 10 2022
-----
Copyright (C) 2021, teamshufflr
All rights reserved.
-----
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
-->

<!--
File: loading-wall.component.html
Author: mafo (maximilian.fossler@teamshufflr.com)"
Last Modified: mafo, Friday, 17th December 2021",
-----
Copyright (C) 2021, teamshufflr
All rights reserved.
-----
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
-->

<ng-container
  *transloco="let t; read: 'loadingWallModule.TsLoadingWallComponent'"
>
  <div
    id="tsLoadingWallOverlayContainer"
    *ngIf="
      loadingWallService.isLoadingWallVisible$ | async as loadingWallConfig
    "
    @fadeInOut
  >
    <div
      id="tsLoadingWallTextContent"
      *ngIf="loadingWallConfig !== TS_LOADING_WALL.DEFAULT"
    >
      <span class="mat-headline-2">
        {{ t(loadingWallConfig + ".title") }}
      </span>
      <p>
        {{ t(loadingWallConfig + ".description") }}
      </p>
    </div>
    <div id="tsLoadingWallOverlay" class="ts-light-background"></div>
    <ts-loading-spinner></ts-loading-spinner>
  </div>
</ng-container>
