/*
 * File: consent-banner.component.ts                                           *
 * Author: mafo (maximilian.fossler@teamshufflr.com)"                          *
 * Last Modified: Tue Jan 25 2022
 * -----                                                                       *
 * Copyright (C) 2021, teamshufflr                                             *
 * All rights reserved.                                                        *
 * -----                                                                       *
 * Unauthorized copying of this file, via any medium is strictly prohibited    *
 * Proprietary and confidential                                                *
 */

import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';
import {
  fadeInOutHeightAnimation,
  fadeInOutWidthAnimation,
} from '@teamshufflr/common/animations';
import { BehaviorSubject } from 'rxjs';
import {
  COOKIE_CATEGORY,
  COOKIE_ID,
  DEFAULT_COOKIE_CONSENTS,
  TsCookieConsentService,
} from '../..';
import { COOKIE_REGISTER } from '../../constants/cookie-register';
import { TsCookieConsents } from '../../interfaces/cookie-consents';
@Component({
  selector: 'ts-consent-banner',
  templateUrl: './consent-banner.component.html',
  styleUrls: ['./consent-banner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [fadeInOutHeightAnimation, fadeInOutWidthAnimation],
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    class: 'ts-flex-c ts-align-stretch-start ts-gap-m',
  },
})
export class TsConsentBannerComponent {
  isCookieConsentSettingsVisible: BehaviorSubject<boolean>;

  constructor(
    public matBottomSheetRef: MatBottomSheetRef<
      TsConsentBannerComponent,
      TsCookieConsents | undefined
    >,
    public cookieConsentService: TsCookieConsentService
  ) {
    this.isCookieConsentSettingsVisible = new BehaviorSubject<boolean>(false);
  }

  acceptAllCookies(): void {
    this.matBottomSheetRef.dismiss(
      Object.entries(COOKIE_REGISTER).reduce<TsCookieConsents>(
        (consents, [categoryID, cookieDefinitions]) => {
          const consentPerCookieID: Partial<
            Record<keyof typeof COOKIE_ID, boolean | undefined>
          > = {};
          cookieDefinitions.forEach((definition) => {
            consentPerCookieID[definition.id] = true;
          });

          consents[categoryID as COOKIE_CATEGORY] = consentPerCookieID;
          return consents;
        },
        {} as any
      )
    );
  }
  openPrivacySettingsDialog(): void {
    this.isCookieConsentSettingsVisible.next(
      !this.isCookieConsentSettingsVisible.value
    );
    return;
  }

  rejectAllCookies(): void {
    this.matBottomSheetRef.dismiss({ ...DEFAULT_COOKIE_CONSENTS });
  }

  saveCurrentConsents(): void {
    this.matBottomSheetRef.dismiss(this.cookieConsentService.cookieConsents);
  }
}
