import { ChangeDetectionStrategy, Component, ElementRef } from '@angular/core';
import { TsDialog } from '@teamshufflr/common/dialogs/dialog';
import { TsProfilePreviewDialogComponent } from '../../components/profile-preview-dialog/profile-preview-dialog.component';

/**
 * A pre-configured MatIconButton to conveniently open the {@link TsProfilePreviewDialogComponent},
 * enabling the currently signed in user to preview its current profile.
 *
 * The dialog consists of:
 * - the user´s name
 * - a list of the {@link SessionModel online-sessions} it currently is a member of
 * - buttons to both toggle the app´s theme and language
 *
 * ___
 * Example usage:
 *
 * ```html
 * <ts-open-profile-preview-dialog-button></ts-open-profile-preview-dialog-button>
 * ```
 *
 * @see {@link TsProfilePreviewDialogComponent}
 */
@Component({
  selector: 'ts-open-profile-preview-dialog-button',
  templateUrl: './open-profile-preview-dialog-button.component.html',
  styleUrls: ['./open-profile-preview-dialog-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TsOpenProfilePreviewDialogButtonComponent {
  constructor(private tsDialog: TsDialog, private elementRef: ElementRef) {}

  openDialog(): void {
    this.tsDialog.open(TsProfilePreviewDialogComponent, {
      maxWidth: '90vw',
      width: '30rem',
      backdrop: 'blurry',
      origin: this.elementRef,
    });
  }
}
