<!--
File: teamshufflr-logo.component.html
Author: mafo (maximilian.fossler@teamshufflr.com)"
Last Modified: Mon Jan 10 2022
-----
Copyright (C) 2021, teamshufflr
All rights reserved.
-----
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
-->

<ng-container
  *transloco="let t; read: 'teamshufflrLogoModule.TsTeamshufflrLogoComponent'"
>
  <a
    [routerLink]="withLinkToHome ? '/' : null"
    [attr.title]="
      withLinkToHome ? t('navigateToHomeButtonTitle') : t('imageTitle')
    "
    [ngSwitch]="style"
  >
    <img
      *ngSwitchCase="TEAMSHUFFLR_LOGO_STYLE.ICON"
      src="./assets/teamshufflr_logo_portrait_blue_icon.png"
      [attr.alt]="t('imageTitle')"
    />
    <img
      *ngSwitchCase="TEAMSHUFFLR_LOGO_STYLE.ICON_WITH_NAME"
      src="./assets/teamshufflr_logo_portrait_blue.png"
      [attr.alt]="t('imageTitle')"
    />
  </a>
</ng-container>
