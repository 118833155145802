import { Directive } from '@angular/core';

/**
 * Scrollable content container of a dialog.
 *
 * ___
 * Example usage:
 *
 * ```html
 * <ts-dialog-content>
 *  <!-- Dialog content -->
 * </ts-dialog-content>
 * ```
 */
@Directive({
  selector: '[ts-dialog-content], ts-dialog-content, [tsDialogContent]',
  exportAs: 'tsDialogContent',
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    class: 'ts-dialog-content ts-flex-c',
  },
})
export class TsDialogContentDirective {
  constructor() {}
}
