/*
 * File: app.component.ts                                                      *
 * Author: mafo (maximilian.fossler@teamshufflr.com)"                          *
 * Last Modified: Wed Jan 19 2022
 * -----                                                                       *
 * Copyright (C) 2021, teamshufflr                                             *
 * All rights reserved.                                                        *
 * -----                                                                       *
 * Unauthorized copying of this file, via any medium is strictly prohibited    *
 * Proprietary and confidential                                                *
 */

import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  OnDestroy,
  Optional,
} from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { routerAnimation } from '@teamshufflr/common/animations';
import { TsCookieConsentService } from '@teamshufflr/common/services/cookie';
import { TsVisualsService } from '@teamshufflr/common/services/visuals-service';
import { filter, Subscription } from 'rxjs';

/**
 * The teamshufflr application´s root component that primarily
 * consists of:
 * * a MatToolbar as the applications global header.
 * * a MatDrawer consisting of the primary navigation options.
 * * a RouterOutlet of the application´s root-router.
 *
 * The drawer´s contents may eventually be blurred depending on
 * {@link TsVisualsService.isContentBlurred$}´s current value.
 */
@Component({
  selector: 'ts-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [routerAnimation()],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TsAppComponent implements OnDestroy {
  private _subscriptions: Subscription;
  constructor(
    public visualsService: TsVisualsService,
    private snackBar: MatSnackBar,
    cookieConsentService: TsCookieConsentService,
    @Optional() private swUpdate?: SwUpdate
  ) {
    this._subscriptions = new Subscription();
    this._subscribeToApplicationUpdates();
    cookieConsentService.initialize();
  }

  /**
   * Whether or not the client is a mobile device
   * considering the window´s innerWidth.
   * @returns
   */
  @HostBinding('class.ts-is-mobile')
  isMobile(): boolean {
    return window.innerWidth < 750;
  }

  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

  /**
   * Forces the current page to reload.
   */
  private _reloadWindow(): void {
    window.location.reload();
  }

  /**
   * Subscribes to swUpdate and eventually shows a SnackBar
   * to inform the client about an available update of the application.
   */
  private _subscribeToApplicationUpdates(): void {
    if (this.swUpdate == null) {
      return;
    }
    const subscription = this.swUpdate.versionUpdates
      .pipe(
        filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY')
      )
      .subscribe(() => {
        const snackBarRef = this.snackBar.open(
          'Eine neue Version ist verfügbar!',
          'Jetzt updaten',
          {
            duration: 7000,
            panelClass: 'lightSnackBar',
          }
        );
        snackBarRef.onAction().subscribe(() => {
          snackBarRef.dismiss();
          this._reloadWindow();
        });
      });

    this._subscriptions.add(subscription);
  }
}
