import {
  animate,
  AnimationEvent,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { CdkPortalOutlet, ComponentPortal } from '@angular/cdk/portal';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ComponentRef,
  EventEmitter,
  ViewChild,
} from '@angular/core';
import { throwDialogContentAlreadyAttachedError } from '@angular/cdk/dialog';

const ANIMATION_TIMINGS = '350ms cubic-bezier(0.25, 0.8, 0.25, 1)';

/**
 * Internal component that wraps user-provided dialog content.
 */
@Component({
  selector: 'ts-dialog-container',
  templateUrl: './dialog-container.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('fadeOverlayInOut', [
      state(
        'void',
        style({
          transform: 'scale3d(0.6,0.6,1)',
          opacity: 0,
        })
      ),
      state('enter', style({ transform: 'none', opacity: 1 })),
      state(
        'leave',
        style({
          transform: 'scale3d(0.6,0.6,1)',
          opacity: 0,
        })
      ),
      transition('* => *', animate(ANIMATION_TIMINGS)),
    ]),
  ],
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    class:
      'ts-light-background-1 ts-rounded-card ts-padding-m mat-elevation-z15 ts-flex-c',
    '[style.width]': '"100%"',
    '[@fadeOverlayInOut]': 'animationState',
    '(@fadeOverlayInOut.start)': 'animationStateChanged.emit($event)',
    '(@fadeOverlayInOut.done)': 'animationStateChanged.emit($event)',
  },
})
export class TsDialogContainerComponent {
  /**
   * The portal outlet inside of this container into which the dialog content will be loaded.
   * */
  @ViewChild(CdkPortalOutlet, { static: true }) _portalOutlet?: CdkPortalOutlet;
  animationOrigin?: 'top-left' | 'top-right' | 'bottom-right' | 'bottom-left';
  /**
   * State of the dialog animation.
   */
  animationState: 'void' | 'enter' | 'leave' = 'enter';
  /**
   * Emits when an animation state changes.
   */
  animationStateChanged = new EventEmitter<AnimationEvent>();

  constructor(private cd: ChangeDetectorRef) {}

  /**
   * Attach a ComponentPortal as content to this dialog container.
   * @param portal Portal to be attached as the dialog content.
   */
  attachComponentPortal<T>(portal: ComponentPortal<T>): ComponentRef<T> {
    if (this._portalOutlet == null) {
      throw throwDialogContentAlreadyAttachedError();
    }

    return this._portalOutlet.attachComponentPortal(portal);
  }

  /**
   * Starts the dialog exit animation.
   */
  startExitAnimation() {
    this.animationState = 'leave';
    this.cd.detectChanges();
  }

  /**
   * Callback, invoked whenever an animation on the host completes.
   * */
  _onAnimationDone(event: AnimationEvent) {
    this.animationStateChanged.next(event);
  }

  /**
   * Callback, invoked when an animation on the host starts.
   *
   */
  _onAnimationStart(event: AnimationEvent) {
    this.animationStateChanged.next(event);
  }
}
