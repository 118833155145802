import { OverlayRef } from '@angular/cdk/overlay';
import { filter, Observable, Subject, take } from 'rxjs';
import { TsDialogContainerComponent } from '../components/dialog-container/dialog-container.component';

/**
 * Reference to a dialog opened via the {@link TsDialog}.
 */
export class TsDialogRef<R = unknown> {
  /**
   * Subject for notifying the user that the dialog has finished closing.
   */
  private _afterClosed = new Subject<R | undefined>();

  /**
   * Subject for notifying the user that the dialog has started closing.
   */
  private _beforeClose = new Subject<void>();
  /**
   * Reference to the wrapping dialog container.
   */
  _containerInstance: TsDialogContainerComponent | null = null;

  constructor(private overlayRef: OverlayRef) {}

  afterClosed(): Observable<R | undefined> {
    return this._afterClosed.asObservable();
  }

  beforeClose(): Observable<void> {
    return this._beforeClose.asObservable();
  }
  /**
   * Close the dialog.
   */
  close(result?: R): void {
    if (this._containerInstance == null) {
      return;
    }

    // Listen for animation 'start' events
    this._containerInstance.animationStateChanged
      .pipe(
        filter((event) => event.phaseName === 'start'),
        take(1)
      )
      .subscribe(() => {
        this._beforeClose.next();
        this._beforeClose.complete();
        this.overlayRef.detachBackdrop();
      });

    // Listen for animation 'done' events
    this._containerInstance.animationStateChanged
      .pipe(
        filter(
          (event) => event.phaseName === 'done' && event.toState === 'leave'
        ),
        take(1)
      )
      .subscribe(() => {
        this.overlayRef.dispose();
        this._afterClosed.next(result);
        this._afterClosed.complete();

        // Make sure to also clear the reference to the
        // component instance to avoid memory leaks
        this._containerInstance = null;
      });

    // Start exit animation
    this._containerInstance.startExitAnimation();
  }
}
