import {
  ComponentType,
  FlexibleConnectedPositionStrategyOrigin,
  Overlay,
  OverlayConfig,
  OverlayRef,
  PositionStrategy,
} from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import {
  Component,
  ComponentRef,
  Injectable,
  InjectionToken,
  Injector,
} from '@angular/core';
import { TsVisualsService } from '@teamshufflr/common/services/visuals-service';
import { TsDialogRef } from '../classes/dialog-ref';
import { TsDialogContainerComponent } from '../components/dialog-container/dialog-container.component';

/**
 * Injection token that can be used to access the data that was passed in to a dialog.
 */
export const TS_DIALOG_DATA = new InjectionToken<any>('TS_DIALOG_DATA');

/**
 * Initial configuration used when creating an dialog.
 */
export type TsDialogConfig<D = any> = Pick<
  OverlayConfig,
  | 'panelClass'
  | 'maxWidth'
  | 'width'
  | 'maxHeight'
  | 'height'
  | 'disposeOnNavigation'
  | 'backdropClass'
> & {
  origin?: FlexibleConnectedPositionStrategyOrigin;
  data?: D;
  backdrop?: 'none' | 'transparent' | 'blurry';

  /**
   * Whether or not the dialog is dismissible by clicking
   * on its backdrop.
   *
   * Defaults to `true`.
   *
   * This does not have any effect if `backdrop` is set to `none`.
   */
  dismissible?: boolean;
};

/**
 * Service to {@link TsDialog.open open} custom modal dialogs.
 *
 * ___
 * ## Open the dialog:
 *
 * ```ts
 * tsDialog.open(YourComponent, YourDialogData, TsDialogConfig);
 * ```
 *
 * ## Example template with title, content & actions:
 * ```html
 * <!--your-dialog.component.html -->
 * <ts-dialog-title
 *  [title]="title"
 *  [leadingIcon]="leadingIcon"
 *  [trailingIcon]="trailingIcon"
 *  [iconColor]="primary"
 * >
 * </ts-dialog-title>
 *
 * <ts-dialog-content>
 *  <!-- Dialog content -->
 * </ts-dialog-content>
 *
 * <ts-dialog-actions>
 *  <button ts-dialog-close>Close</button>
 * </ts-dialog-actions>
 * ```
 *
 * @see {@link TsDialogActionsDirective}
 * @see {@link TsDialogContentDirective}
 * @see {@link TsDialogCloseDirective}
 * @see {@link TsDialogTitleComponent}
 */
@Injectable()
export class TsDialog {
  constructor(
    private overlay: Overlay,
    private injector: Injector,
    private visualsService: TsVisualsService
  ) {}

  /**
   * Opens a modal dialog containing the given component.
   *
   * @param component Type of the component to load into the dialog.
   * @param config Extra configuration options.
   * @returns Reference to the newly-opened dialog.
   */
  open<T = Component, D = any, R = any>(
    component: ComponentType<T>,
    config?: TsDialogConfig<D>
  ): TsDialogRef<R> {
    // Returns an OverlayRef which is a PortalHost
    const overlayRef = this._createOverlay(config);

    // Instantiate remote control
    const customOverlayRef = new TsDialogRef<R>(overlayRef);

    const overlayComponent = this._attachDialogContainer<D>(
      overlayRef,
      TsDialogContainerComponent,
      customOverlayRef,
      config
    );

    this._attachDialogContent(
      customOverlayRef,
      overlayComponent.instance,
      component,
      config?.data
    );
    overlayRef.backdropClick().subscribe((_) => {
      if (config?.dismissible !== false) {
        customOverlayRef.close();
      }
    });

    // Blur the body via `TsVisualsService` if desired.
    if (config?.backdrop === 'blurry') {
      this.visualsService.updateIsContentBlurred(true);
      customOverlayRef.beforeClose().subscribe(() => {
        this.visualsService.updateIsContentBlurred(false);
      });
    }

    return customOverlayRef;
  }

  /**
   * Attaches a dialog container to an overlay's already-created
   * {@link TsDialogContainerComponent}.
   *
   * @param overlay Reference to the overlay's underlying overlay.
   * @param componentType The type of component being loaded into the dialog.
   * @param config The dialog configuration.
   */
  private _attachDialogContainer<D = {}, R = any>(
    overlay: OverlayRef,
    componentType: ComponentType<TsDialogContainerComponent>,
    dialogRef: TsDialogRef<R>,
    config?: TsDialogConfig<D>
  ): ComponentRef<TsDialogContainerComponent> {
    const injector = this._createInjector(dialogRef, config?.data);

    const containerPortal = new ComponentPortal(componentType, null, injector);
    const containerRef: ComponentRef<TsDialogContainerComponent> =
      overlay.attach(containerPortal);
    dialogRef._containerInstance = containerRef.instance;
    return containerRef;
  }

  /**
   * Attaches the user-provided component to the already-created dialog container.
   *
   * @param overlayRef Reference to the overlay in which the dialog resides.
   * @param componentType The type of component being loaded into the dialog.
   * @param dialogInstance Reference to the wrapping dialog container.
   * @param data Data to pass into the overlay.
   */
  private _attachDialogContent<T, D, R>(
    overlayRef: TsDialogRef<R>,
    dialogInstance: TsDialogContainerComponent,
    componentType: ComponentType<T>,
    data: D
  ): void {
    const injector = this._createInjector(overlayRef, data);

    const portal = new ComponentPortal(componentType, null, injector);
    dialogInstance.attachComponentPortal(portal);
  }

  /**
   * Creates a custom injector to be used inside the overlay. This allows a component loaded inside
   * of a {@link TsDialogContainerComponent} to close itself and, optionally, to return a value.
   *
   * @param data Config object that is used to construct the overlay.
   * @param overlayRef Reference to the custom overlay.
   * @returns The custom injector that can be used inside the dialog.
   */
  private _createInjector<D, R>(
    overlayRef: TsDialogRef<R>,
    data?: D
  ): Injector {
    // Instantiate new PortalInjector
    return Injector.create({
      parent: this.injector,
      providers: [
        {
          provide: TS_DIALOG_DATA,
          useValue: data,
        },
        {
          provide: TsDialogRef,
          useValue: overlayRef,
        },
      ],
    });
  }

  /**
   * Creates the Material overlay into which the overlay will be loaded.
   *
   * @param config The overlay configuration.
   * @returns The {@link OverlayRef} for the created overlay.
   */
  private _createOverlay(config?: TsDialogConfig): OverlayRef {
    const overlayConfig = this._getOverlayConfig(config);
    return this.overlay.create(overlayConfig);
  }

  /**
   * Creates an overlay-configuration from a {@link TsDialogConfig}.
   *
   * @param overlayConfig The custom overlay configuration.
   * @returns The Material overlay-configuration.
   */
  private _getOverlayConfig(config?: TsDialogConfig): OverlayConfig {
    const hasBackdrop = config?.backdrop == null || config.backdrop !== 'none';
    const backdropClasses = ['ts-dialog-backdrop'];

    if (config?.backdropClass != null) {
      if (typeof config.backdropClass === 'string') {
        backdropClasses.push(config.backdropClass);
      } else {
        backdropClasses.push(...config.backdropClass);
      }
    }

    if (config?.backdrop === 'transparent') {
      backdropClasses.push('ts-dialog-backdrop-transparent');
    } else if (config?.backdrop === 'blurry') {
      backdropClasses.push('ts-dialog-backdrop-blurry');
    }

    const overlayConfig = new OverlayConfig({
      hasBackdrop: hasBackdrop,
      backdropClass: backdropClasses,
      maxWidth: config?.maxWidth,
      width: config?.width,
      maxHeight: config?.maxHeight,
      height: config?.height,
      panelClass: config?.panelClass,
      disposeOnNavigation: config?.disposeOnNavigation,
      positionStrategy: this._getOverlayPositionStrategy(config),
    });

    return overlayConfig;
  }

  /**
   * Creates a {@link PositionStrategy} from the given {@link TsDialogConfig}.
   *
   * @param overlayConfig Config object that is used to construct the position strategy.
   */
  private _getOverlayPositionStrategy(
    config?: TsDialogConfig
  ): PositionStrategy | undefined {
    if (config == null) {
      return undefined;
    }

    if (config.origin != null) {
      return this.overlay
        .position()
        .flexibleConnectedTo(config.origin)
        .withFlexibleDimensions(true)
        .withViewportMargin(15)
        .withPositions([
          {
            originX: 'end',
            originY: 'bottom',
            overlayX: 'end',
            overlayY: 'top',
            panelClass: 'ts-dialog-position-origin-top-right',
          },
          {
            originX: 'start',
            originY: 'bottom',
            overlayX: 'start',
            overlayY: 'top',
            panelClass: 'ts-dialog-position-origin-top-left',
          },
          {
            originX: 'start',
            originY: 'top',
            overlayX: 'start',
            overlayY: 'bottom',
            panelClass: 'ts-dialog-position-origin-bottom-left',
          },
          {
            originX: 'end',
            originY: 'top',
            overlayX: 'end',
            overlayY: 'bottom',
            panelClass: 'ts-dialog-position-origin-bottom-right',
          },
        ]);
    } else {
      return this.overlay
        .position()
        .global()
        .centerHorizontally()
        .centerVertically();
    }
  }
}
