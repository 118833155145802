/*
 * File: session.ts                                                            *
 * Author: mafo (maximilian.fossler@teamshufflr.com)"                          *
 * Last Modified: Fri Dec 17 2021                                              *
 * -----                                                                       *
 * Copyright (C) 2021, teamshufflr                                             *
 * All rights reserved.                                                        *
 * -----                                                                       *
 * Unauthorized copying of this file, via any medium is strictly prohibited    *
 * Proprietary and confidential                                                *
 */

import { SessionUserModel } from './sessionUser';

/**
 * The type of the {@link SessionModel}.
 */
export enum SESSION_TYPE {
  SIMPLE_CARDS = 'SIMPLE_CARDS',
}

/**
 * A session consisting of one {@link host} and
 * several {@link participants} that collaborative
 * operate together in this session.
 */
export interface SessionModel {
  /**
   * The session´s unique ID.
   */
  id: string;

  /**
   * the session´s {@link SESSION_TYPE type}.
   */
  type: SESSION_TYPE;

  /**
   * The session´s encrypted passphrase, necessary for joining
   * this session.
   */
  passphrase: string;

  /**
   * The session´s host, hence
   * the user this session was
   * created by.
   */
  host: Omit<SessionUserModel, 'sessionID' | 'isHost'>;

  /**
   * The expected number of persons,
   * participating in this session.
   */
  expectedAmountOfParticipants: number;

  /**
   * The individual name of the session
   * as set by the {@link host}.
   */
  name: string;

  /**
   * The individual description of the session
   * as set by the {@link host}.
   */
  description?: string;

  /**
   * The total amount of members, including
   * the session´s host.
   */
  amountOfMembers: number;
}
