/*
 * File: session-provider.service.ts                                           *
 * Author: mafo (maximilian.fossler@teamshufflr.com)"                          *
 * Last Modified: Tue Jan 25 2022
 * -----                                                                       *
 * Copyright (C) 2021, teamshufflr                                             *
 * All rights reserved.                                                        *
 * -----                                                                       *
 * Unauthorized copying of this file, via any medium is strictly prohibited    *
 * Proprietary and confidential                                                *
 */

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TsSessionDatabaseRepository } from '@teamshufflr/common/repositories/database';
import { TsSessionFirestoreRepository } from '@teamshufflr/common/repositories/firestore';
import {
  TEAMSHUFFLR_PAGE,
  TsPageRouteTable,
} from '@teamshufflr/common/routing';
import { TsAuthenticationService } from '@teamshufflr/common/services/authentication';
import {
  SessionDatabaseModel,
  SessionModel,
  SessionUserModel,
  SessionUserPreviewDatabaseModel,
  TeamshufflrFeatureConfigurationsDatabaseModel,
  TeamshufflrFeatureTeamsDatabaseModel,
  TeamshufflrSettingsDatabaseModel,
} from '@teamshufflr/core-api-models';
import {
  TeamshufflrResult,
  TeamshufflrResultFeatureSummary,
} from '@teamshufflr/core-interfaces';
import {
  BehaviorSubject,
  firstValueFrom,
  Observable,
  Subscription,
} from 'rxjs';
import { distinctUntilChanged, filter } from 'rxjs/operators';

/**
 * Service for providing and handling the currently
 * active {@link SessionModel}.
 *
 * This service is provided in the application´s ROOT module.
 */
@Injectable({
  providedIn: 'root',
})
export class TsSessionProviderService {
  private _currentMembers: BehaviorSubject<Array<SessionUserModel>>;
  private _currentSession: BehaviorSubject<SessionModel | null>;
  private _currentSessionDatabaseModel: BehaviorSubject<SessionDatabaseModel | null>;
  /**
   * Whether or not there is a new {@link SessionDatabaseModel.streamingFeatureSummary}
   * the client has not seen.
   *
   * This is always false if the client is the session´s host.
   */
  private _hasUnseenResult: BehaviorSubject<boolean>;
  private _initialized = false;

  /**
   * Holds references to all subscriptions created in this provider.
   */
  private _subscriptions: Subscription;

  get currentMembers(): Array<SessionUserModel> {
    return this._currentMembers.value;
  }

  get currentMembers$(): Observable<Array<SessionUserModel>> {
    return this._currentMembers.asObservable();
  }

  /**
   * The currently active session.
   *
   * May be null if there is none.
   */
  get currentSession(): SessionModel | null {
    return this._currentSession.value;
  }

  /**
   * The currently active session.
   *
   * May be null if there is none.
   */
  get currentSession$(): Observable<SessionModel | null> {
    return this._currentSession.asObservable();
  }

  /**
   * The currently active {@link SessionDatabaseModel}.
   *
   * May be null if there is none.
   */
  get currentSessionDatabaseModel$(): Observable<SessionDatabaseModel | null> {
    return this._currentSessionDatabaseModel.asObservable();
  }

  /**
   * Whether or not there is a new {@link SessionDatabaseModel.streamingFeatureSummary}
   * the client has not seen.
   *
   * This is always false if the client is the session´s host.
   *
   */
  get hasUnseenResult(): boolean {
    return this._hasUnseenResult.value;
  }
  /**
   * Observable of whether or not there is a
   * new {@link SessionDatabaseModel.streamingFeatureSummary}
   * the client has not seen.
   *
   * This is always false if the client is the session´s host.
   */
  get hasUnseenResult$(): Observable<boolean> {
    return this._hasUnseenResult.pipe(distinctUntilChanged());
  }

  constructor(
    private sessionFirestoreRepository: TsSessionFirestoreRepository,
    private sessionDatabaseRepository: TsSessionDatabaseRepository,
    private _authenticationService: TsAuthenticationService,
    private router: Router
  ) {
    this._subscriptions = new Subscription();
    this._currentSession = new BehaviorSubject<SessionModel | null>(null);
    this._currentMembers = new BehaviorSubject<Array<SessionUserModel>>([]);
    this._hasUnseenResult = new BehaviorSubject<boolean>(false);
    this._currentSessionDatabaseModel =
      new BehaviorSubject<SessionDatabaseModel | null>(null);

    this._subscribeToUserChanges();
  }
  /**
   * The currently active session from the Firebase Database.
   *
   * May be null if there is none.
   */
  getSessionDatabaseModel(): SessionDatabaseModel | null {
    return this._currentSessionDatabaseModel.value;
  }
  /**
   * The currently active session from the Firebase Database.
   *
   * May be null if there is none.
   */
  getSessionDatabaseModel$(): Observable<SessionDatabaseModel | null> {
    return this._currentSessionDatabaseModel.asObservable();
  }

  /**
   * Fetches the {@link TeamshufflrSettingsDatabaseModel} of an
   * online session corresponding to `sessionID`.
   *
   * @param sessionID The ID of the session for which to fetch the {@link TeamshufflrSettingsDatabaseModel}
   * @returns
   */
  async getTeamshufflrSettings(
    sessionID: string
  ): Promise<TeamshufflrSettingsDatabaseModel | null> {
    return this.sessionDatabaseRepository.getSessionSettings(sessionID);
  }

  /**
   * Initializes subscriptions to all {@link SessionModel session}-related data.
   *
   * @param sessionID The ID of the {@link SessionModel} for which to initialize the
   * subscriptions to.
   *
   * @returns Promise that resolves on the {@link currentSession$} first valid value.
   */
  async initialize(
    sessionID: string,
    allowReset = true
  ): Promise<SessionModel> {
    if (this._initialized) {
      if (!allowReset) {
        throw `[TsSessionProviderService] is already initialized! \n\nTo avoid this error, call [TsSessionProviderService.reset] before initialization.`;
      }
      const _currentSession = this._currentSession.value;
      const didSessionIDChange = _currentSession?.id !== sessionID;
      if (didSessionIDChange) {
        this.reset();
      } else {
        return _currentSession;
      }
    }

    this._initializeSessionModelSnapshotSubscription(sessionID);
    this._initializeSessionMemberSnapshotSubscription(sessionID);
    this._initializeSessionDatabaseModelSnapshotSubscription(sessionID);

    this._initializeStreamingFeatureChangeSubscription();

    const session = await firstValueFrom(
      this._currentSession.pipe(
        filter((res): res is SessionModel => res != null)
      )
    );
    this._initialized = true;
    return session;
  }

  /**
   * Whether or not a SessionModel is currently
   * present and matching the `sessionID`.
   *
   * @param sessionID
   */
  isSessionActive(sessionID: string): boolean {
    return this._currentSession.value?.id == sessionID;
  }

  async joinSession(
    sessionID: string,
    passphrase: string,
    user: SessionUserModel
  ): Promise<void> {
    await this.sessionFirestoreRepository.joinSession(
      sessionID,
      passphrase,
      user
    );
  }

  /**
   * Resets this TsSessionProviderService by both unsubscribing
   * to current and re-initializing all existing Subscriptions.
   */
  reset(): void {
    this._subscriptions.unsubscribe();
    this._subscriptions = new Subscription();

    this._currentSession.next(null);
    this._currentMembers.next([]);
    this._hasUnseenResult.next(false);

    this._initialized = false;
  }
  /**
   * Updates the currently active session.
   *
   * @param session
   */
  updateCurrentSession(session: SessionModel): void {
    this._currentSession.next(session);
  }
  updateHasSeenTeamSummaryResult(hasUnseenResults: boolean): void {
    this._hasUnseenResult.next(hasUnseenResults);
  }
  async updateInDatabase(session: SessionDatabaseModel): Promise<void> {
    await this.sessionDatabaseRepository.updateSession(session);
  }
  async updateStreamingFeature(
    sessionID: string,
    featureSummary?: TeamshufflrResultFeatureSummary
  ): Promise<void> {
    await this.sessionDatabaseRepository.updateStreamingFeature(
      sessionID,
      featureSummary
    );
  }
  async updateTeamshufflrSettings(
    teamshufflrResult: TeamshufflrResult,
    sessionID: string
  ): Promise<void> {
    await this.sessionDatabaseRepository.setSessionSettings({
      sessionID,
      participantsType: teamshufflrResult.teamshufflrSettings.participantsType,
      memberIDs: teamshufflrResult.teamshufflrSettings.participants.map(
        (participant) => participant.id ?? null
      ),
      featureConfigurations: Object.values(
        teamshufflrResult.summary
      ).reduce<TeamshufflrFeatureConfigurationsDatabaseModel>(
        (featureConfigurations, currentFeature) => {
          featureConfigurations[currentFeature.gimmickID] = {
            gimmickID: currentFeature.gimmickID,
            amountOfMembersPerTeam: currentFeature.amountOfMembersPerTeam,
            generatedTeams: Object.values(
              currentFeature.generatedTeams
            ).reduce<TeamshufflrFeatureTeamsDatabaseModel>(
              (teams, currentTeam) => {
                teams[currentTeam.gimmickOptionID] = {
                  gimmickOptionID: currentTeam.gimmickOptionID,
                  memberIDs: currentTeam.members.map(
                    (member) => member.id ?? ''
                  ),
                };
                return teams;
              },
              {}
            ),
          };
          return featureConfigurations;
        },
        {}
      ),
    });
  }

  async updateUserInDatabase(
    sessionID: string,
    user: SessionUserPreviewDatabaseModel
  ): Promise<void> {
    await this.sessionDatabaseRepository.updateSessionUser(sessionID, user);
  }

  /**
   * Initializes a snapshot-subscription to the {@link SessionDatabaseModel}
   * corresponding to `sessionID` which updates the local {@link _currentSessionDatabaseModel} subject.
   *
   * @param sessionID The ID of the {@link SessionDatabaseModel} to initialize a snapshot-subscription for.
   */
  private _initializeSessionDatabaseModelSnapshotSubscription(
    sessionID: string
  ): void {
    this._subscriptions.add(
      this.sessionDatabaseRepository
        .getSession$(sessionID)
        .subscribe((session) => this._currentSessionDatabaseModel.next(session))
    );
  }

  /**
   * Initializes a snapshot-subscription to all {@link SessionUserModel members} of
   * the {@link SessionModel session} corresponding to `sessionID` which updates the local {@link _currentMembers} subject.
   *
   * @param sessionID The ID of the {@link SessionModel} for which to initialize a snapshot-subscription to all {@link SessionUserModel members}.
   */
  private _initializeSessionMemberSnapshotSubscription(
    sessionID: string
  ): void {
    this._subscriptions.add(
      this.sessionFirestoreRepository
        .getMembersSnapshots(sessionID)
        .subscribe((sessionMembers) =>
          this._currentMembers.next(sessionMembers)
        )
    );
  }

  /**
   * Initializes a snapshot-subscription to the {@link SessionModel}
   * corresponding to `sessionID` which updates the local {@link _currentSession} subject.
   *
   * @param sessionID The ID of the {@link SessionModel} to initialize a snapshot-subscription for.
   */
  private _initializeSessionModelSnapshotSubscription(sessionID: string): void {
    this._subscriptions.add(
      this.sessionFirestoreRepository
        .getSessionSnapshots(sessionID)
        .subscribe((res) => this._currentSession.next(res ?? null))
    );
  }

  /**
   * Initializes a snapshot-subscription to the {@link SessionModel}
   * corresponding to `sessionID`.
   */
  private _initializeStreamingFeatureChangeSubscription(): void {
    this._subscriptions.add(
      this._currentSessionDatabaseModel.subscribe((session) => {
        const currentStreamingFeatureSummary = session?.streamingFeatureSummary;

        const didStreamingFeatureSummaryChange =
          JSON.stringify(currentStreamingFeatureSummary) !==
          JSON.stringify(
            this._currentSessionDatabaseModel.value?.streamingFeatureSummary
          );

        const isOnResultPage = this.router.url.includes(
          TsPageRouteTable.get(
            TEAMSHUFFLR_PAGE.TEAMSHUFFLR_SESSION_SHUFFLE_RESULT_PAGE
          ).slug
        );

        const isUserSessionHost =
          this._currentSession.value?.host?.id ===
          this._authenticationService.currentUser?.uid;

        if (
          currentStreamingFeatureSummary != null &&
          didStreamingFeatureSummaryChange &&
          isOnResultPage === false &&
          isUserSessionHost === false
        ) {
          this.updateHasSeenTeamSummaryResult(true);
        } else {
          this.updateHasSeenTeamSummaryResult(false);
        }
      })
    );
  }

  private async _subscribeToUserChanges(): Promise<void> {
    this._authenticationService.currentUser$.subscribe(async (user) => {
      const _currentSessionID = this._currentSession.value?.id;
      if (user == null) {
        this.reset();
      } else if (_currentSessionID != null) {
        this.initialize(_currentSessionID);
      }
    });
  }
}
