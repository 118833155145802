<ng-container
  *transloco="
    let t;
    read: 'recentSessionsListModule.TsRecentSessionsListComponent'
  "
>
  <ng-container
    *ngIf="sessionUserProviderService.recentSessions$ | async as recentSessions"
  >
    <ng-container *ngIf="recentSessions.length > 0">
      <p
        [innerHTML]="
          t('descriptionHTML', {
            amountOfRecentSessions: recentSessions.length
          })
        "
      ></p>
      <div
        id="tsSelectRecentSessionDialogScrollContainer"
        class="ts-flex-c ts-gap-s ts-align-start ts-fade-element ts-rounded-card ts-light-background ts-padding-m"
      >
        <div
          id="tsSelectRecentSessionDialogScrollContent"
          class="ts-flex-r ts-gap-m ts-flex-wrap ts-align-start"
        >
          <ts-recent-session-overview-card
            *ngFor="let recentSession of recentSessions"
            matRipple
            [session]="recentSession"
            [routerLink]="getUrlOfRecentSession(recentSession.id)"
            (click)="sessionSelected.emit(recentSession)"
            class="ts-light-background-1 ts-card"
            [attr.title]="
              t('selectRecentSessionButtonTitle', {
                sessionName: recentSession.name
              })
            "
            cdkFocusInitial
          >
          </ts-recent-session-overview-card>
        </div>
      </div>
    </ng-container>
  </ng-container>
</ng-container>
