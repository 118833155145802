/*
 * File: routerAnimation.animation.ts                                          *
 * Author: mafo (maximilian.fossler@teamshufflr.com)"                          *
 * Last Modified: Sun Jan 09 2022
 * -----                                                                       *
 * Copyright (C) 2021, teamshufflr                                             *
 * All rights reserved.                                                        *
 * -----                                                                       *
 * Unauthorized copying of this file, via any medium is strictly prohibited    *
 * Proprietary and confidential                                                *
 */

import {
  animate,
  AnimationTriggerMetadata,
  group,
  query,
  stagger,
  style,
  transition,
  trigger,
} from '@angular/animations';

export function routerAnimation(): AnimationTriggerMetadata {
  return trigger('routerAnimation', [
    /**
     * PAGE TRANSITION FROM LANDING- TO OTHER PAGE.
     */
    transition('1 => *', [
      query(
        ':not(.ts-is-mobile):enter',
        [
          style({
            position: 'absolute',
            'z-index': 1,
          }),
          group([
            query(
              '#leftColumn',
              [
                style({
                  opacity: 0,
                  transform: 'translateX(-100%)',
                }),
                animate(
                  '0.4s ease',
                  style({
                    opacity: 1.0,
                    transform: 'translateX(0)',
                  })
                ),
              ],
              { optional: true }
            ),
            query(
              '.ts-fade-element',
              [
                style({
                  opacity: 0.0,
                  // transform: 'translateX(-300px)',
                }),
                stagger(100, [
                  animate(
                    '0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275)',
                    style({
                      opacity: 1.0,
                      // transform: 'translateX(0)',
                    })
                  ),
                ]),
              ],
              { optional: true }
            ),
          ]),
        ],
        { optional: true }
      ),
    ]),
    /**
     * PAGE TRANSITION FROM OTHER- TO LANDING-PAGE.
     */
    transition('* => 1', [
      group([
        query(
          ':leave',
          [
            style({
              position: 'absolute',
              top: 0,
              'z-index': 0,
            }),
            group([
              query(
                '#leftColumn',
                [
                  style({
                    opacity: 1.0,
                    transform: 'translateX(0)',
                  }),
                  animate(
                    '.4s ease',
                    style({
                      opacity: 0,
                      transform: 'translateX(-100%)',
                    })
                  ),
                ],
                { optional: true }
              ),
              query('.ts-fade-element', [
                style({
                  opacity: 1.0,
                  // transform: 'translateX(-300px)',
                }),
                animate(
                  '0.4s ease',
                  style({
                    opacity: 0.0,
                    // transform: 'translateX(0)',
                  })
                ),
              ]),
            ]),
          ],
          { optional: true }
        ),
      ]),
    ]),
    /**
     * DEFAULT PAGE DECREMENT TRANSITION
     */
    transition(':decrement', [
      // set new page X location to be -100%
      query(
        ':enter',
        [
          style({
            position: 'absolute',
          }),
          query(
            '.ts-fade-element',
            [
              style({
                opacity: 0.0,
                // transform: 'translateX(-300px)',
              }),
              stagger(100, [
                animate(
                  '0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275)',
                  style({
                    opacity: 1.0,
                    // transform: 'translateX(0)',
                  })
                ),
              ]),
            ],
            { optional: true }
          ),
        ],
        { optional: true }
      ),
    ]),
    /**
     * DEFAULT PAGE INCREMENT TRANSITION
     */
    transition(':increment', [
      // set new page X location to be 100%
      query(
        ':enter',
        [
          style({
            position: 'absolute',
          }),
          query(
            '.ts-fade-element',
            [
              style({
                opacity: 0.0,
              }),
              stagger(100, [
                animate(
                  '0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275)',
                  style({
                    opacity: 1.0,
                  })
                ),
              ]),
            ],
            { optional: true }
          ),
        ],
        { optional: true }
      ),
    ]),
  ]);
}
