/*
 * File: route-parameter.ts
 * Author: mafo (maximilian.fossler@teamshufflr.com)"
 * Last Modified: Sat Jan 22 2022
 * -----
 * Copyright (C) 2021 - 2022, teamshufflr
 * All rights reserved.
 * -----
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

/**
 * All keys used as route-parameters
 * when defining and/or parsing routes.
 */
export enum ROUTE_PARAMETER {
  SESSION_ID = 'SESSION_ID',
}
