/*
 * File: session-user.firestore.paths.ts                                       *
 * Author: mafo (maximilian.fossler@teamshufflr.com)"                          *
 * Last Modified: Fri Dec 17 2021                                              *
 * -----                                                                       *
 * Copyright (C) 2021, teamshufflr                                             *
 * All rights reserved.                                                        *
 * -----                                                                       *
 * Unauthorized copying of this file, via any medium is strictly prohibited    *
 * Proprietary and confidential                                                *
 */

/**
 * All Firestore paths related to {@link SessionUserModel}s.
 */
export abstract class SessionUserFirestorePaths {
  /**
   * Path of the {@link SessionUserModel}s collection.
   */
  static userCollection = 'user';

  /**
   * Path of a single {@link SessionUserModel} document
   * corresponding to `userID`.
   *
   * @param userID The ID of the {@link SessionUserModel} to get the Firestore path for.
   * @returns
   */
  static userDocument(userID: string): string {
    return SessionUserFirestorePaths.userCollection + '/' + userID;
  }
}
