/*
 * File: fadeInOutWidth.animation.ts                                           *
 * Author: mafo (maximilian.fossler@teamshufflr.com)"                          *
 * Last Modified: Tue Dec 28 2021
 * -----                                                                       *
 * Copyright (C) 2021, teamshufflr                                             *
 * All rights reserved.                                                        *
 * -----                                                                       *
 * Unauthorized copying of this file, via any medium is strictly prohibited    *
 * Proprietary and confidential                                                *
 */

import { animate, style, transition, trigger } from '@angular/animations';

/**
 * Creates an animation that fades the animation´s host using
 * a css-transform animation with a set of x- and y-parameters.
 *
 * To define the animation´s transform-parameters, see the following example
 * usage.
 *
 * ___
 * Example usage:
 * ```html
 * <div
 *  [@fadeInOutTransform]="{
 *    params: {
 *      x1: 0.0,
 *      x2: 0.0,
 *      y1: 0.0,
 *      y2: 0.0,
 *    }
 *   }"
 *  >
 * </div>
 * ```
 */
export const fadeInOutTransformAnimation = trigger('fadeInOutTransform', [
  transition(
    ':enter',
    [
      style({
        opacity: 0,
        transform: 'translate3d({{x1}}, {{y1}}, 0)',
      }),
      animate(
        '350ms ease-in-out',
        style({
          opacity: 1.0,
          transform: 'translate3d({{x2}}, {{y2}}, 0)',
        })
      ),
    ],
    {
      params: {
        x1: 0.0,
        x2: 0.0,
        y1: 0.0,
        y2: 0.0,
      },
    }
  ),
  transition(
    ':leave',
    [
      style({
        opacity: 1.0,
        transform: 'translate3d({{x2}}, {{y2}}, 0)',
      }),
      animate(
        '350ms ease-in-out',
        style({
          opacity: 0,
          transform: 'translate3d({{x1}}, {{y1}}, 0)',
        })
      ),
    ],
    {
      params: {
        x1: 0.0,
        x2: 0.0,
        y1: 0.0,
        y2: 0.0,
      },
    }
  ),
]);
