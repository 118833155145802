/*
 * File: cookie-type.ts                                                        *
 * Author: mafo (maximilian.fossler@teamshufflr.com)"                          *
 * Last Modified: Sun Jan 23 2022
 * -----                                                                       *
 * Copyright (C) 2021, teamshufflr                                             *
 * All rights reserved.                                                        *
 * -----                                                                       *
 * Unauthorized copying of this file, via any medium is strictly prohibited    *
 * Proprietary and confidential                                                *
 */

/**
 * All custom cookies set by the application.
 */
export enum COOKIE_NAME {
  PREFERRED_LANGUAGE = 'teamshufflr_lang',
  CONSENT = 'teamshufflr_cookie_consent',
  IS_DARK_THEME_PREFERRED = 'teamshufflr_dark_theme',
}
