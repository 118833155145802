/*
 * File: analytics.module.ts                                                   *
 * Author: mafo (maximilian.fossler@teamshufflr.com)"                          *
 * Last Modified: Wed Jan 19 2022
 * -----                                                                       *
 * Copyright (C) 2021, teamshufflr                                             *
 * All rights reserved.                                                        *
 * -----                                                                       *
 * Unauthorized copying of this file, via any medium is strictly prohibited    *
 * Proprietary and confidential                                                *
 */

import { NgModule } from '@angular/core';
import { initializeAnalytics, provideAnalytics } from '@angular/fire/analytics';
import { getApp } from '@angular/fire/app';
import { version } from '@teamshufflr/core-environments';
import { TsAnalyticsService } from './analytics.service';

@NgModule({
  exports: [],
  imports: [
    provideAnalytics(() => {
      const app = getApp();
      return initializeAnalytics(app, {
        config: {
          app_version: `v${version}`,
          send_page_view: false,
          allow_ad_personalization_signals: false,
          anonymize_ip: true,
        },
      });
    }),
  ],
  providers: [],
})
export class TsAnalyticsModule {
  constructor(public tsAnalyticsService: TsAnalyticsService) {}
}
