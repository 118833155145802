/*
 * File: page-identity.service.ts
 * Author: mafo (maximilian.fossler@teamshufflr.com)"
 * Last Modified: Sat Jan 22 2022
 * -----
 * Copyright (C) 2021 - 2022, teamshufflr
 * All rights reserved.
 * -----
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { Injectable } from '@angular/core';
import { Meta, MetaDefinition, Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { TsRouteData } from '@teamshufflr/common/routing';
import {
  filter,
  first,
  map,
  mergeMap,
  startWith,
  Subscription,
  switchMap,
  tap,
} from 'rxjs';
import { DEFAULT_PAGE_IDENTITY, PageIdentity } from './page-identity';

/**
 * Service to conveniently update the document´s {@link PageIdentity}
 * using Angular´s {@link Meta} & {@link Title} services.
 */
@Injectable()
export class TsPageIdentityService {
  private _currentPageIdentity: PageIdentity;
  private _subscriptions: Subscription;

  constructor(
    private titleService: Title,
    private metaTagsService: Meta,
    private translocoService: TranslocoService,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {
    this._currentPageIdentity = DEFAULT_PAGE_IDENTITY;
    this._subscriptions = new Subscription();
    this._subscribeToLangChanges();
  }

  /**
   * Updates both the document´s title and meta-tags, provided by {@link identity}.
   * @param identity The {@link PageIdentity} to update the document´s title and meta-tags with.
   */
  setSiteIdentity(identity: PageIdentity = this._currentPageIdentity): void {
    const normalizedPageIdentity = this._normalizePageIdentity(identity);

    this.titleService.setTitle(
      'teamshufflr - ' +
        this.translocoService.translate(normalizedPageIdentity.title)
    );

    normalizedPageIdentity.tags.forEach((tag) => {
      this.metaTagsService.updateTag({
        ...tag,
        content:
          tag.content != null
            ? (tag.property === 'og:title' ? 'teamshufflr - ' : '') +
              this.translocoService.translate(tag.content)
            : '',
      });
    });

    this._currentPageIdentity = normalizedPageIdentity;
  }

  /**
   * Concatenates the {@link PageIdentity.tags identity.tags} with {@link DEFAULT_PAGE_IDENTITY.tags default values}.
   *
   * Normalizes the tags of the provided `identity` by setting all values which are
   * not included to their corresponding {@link DEFAULT_PAGE_IDENTITY.tags default values}. It furthermore adds
   * default meta-tags for `og:title`, `description` and `og:title` to the provided identity´s title and description.
   *
   * @param identity The identity for which to {@link PageIdentity.tags identity.tags} with {@link DEFAULT_PAGE_IDENTITY default values}.
   */
  private _normalizeMetaTags(identity: PageIdentity): MetaDefinition[] {
    const metaDefinitions: Array<MetaDefinition> =
      DEFAULT_PAGE_IDENTITY.tags.map((defaultMetaTag) => {
        const overridingMetaTag = identity.tags?.find(
          (tag) =>
            (!!tag.name && tag.name === defaultMetaTag.name) ||
            (!!tag.property && tag.property === defaultMetaTag.property)
        );
        if (overridingMetaTag != null) {
          return overridingMetaTag;
        }

        return defaultMetaTag;
      });

    metaDefinitions.unshift(
      {
        property: 'og:title',
        content: identity.title,
      },
      {
        name: 'description',
        content: identity.description ?? DEFAULT_PAGE_IDENTITY.description,
      },
      {
        property: 'og:description',
        content: identity.description ?? DEFAULT_PAGE_IDENTITY.description,
      }
    );

    return metaDefinitions;
  }

  /**
   * Concatenates the `identity` with {@link DEFAULT_PAGE_IDENTITY default values}.
   *
   * Normalizes the provided `identity` by setting all values which are
   * not included in `identity` to their corresponding {@link DEFAULT_PAGE_IDENTITY default values}.
   *
   * @param identity The identity to concat with {@link DEFAULT_PAGE_IDENTITY default values}.
   */
  private _normalizePageIdentity(
    identity: PageIdentity
  ): Required<PageIdentity> {
    const normalizedPageIdentity: Required<PageIdentity> = {
      title: identity.title,
      description: identity.description ?? DEFAULT_PAGE_IDENTITY.description,
      tags: this._normalizeMetaTags(identity),
    };

    return normalizedPageIdentity;
  }

  /**
   * Subscribes to {@link TranslocoService.langChanges$} to eventually
   * update the page´s meta tags.
   */
  private _subscribeToLangChanges(): void {
    const subscription = this.translocoService.events$
      .pipe(
        first((event) => event.type === 'translationLoadSuccess'),
        tap(() => this._subscribeToNavigationEvents()),
        switchMap(() => this.translocoService.langChanges$)
      )
      .subscribe(() => this.setSiteIdentity());
    this._subscriptions.add(subscription);
  }

  /**
   * Subscribes to {@link Router.events} to eventually update the page´s
   * identity whenever a route has changed.
   */
  private _subscribeToNavigationEvents(): void {
    const subscription = this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        startWith(this.activatedRoute),
        map(() => this.activatedRoute),
        map((route) => {
          while (route.firstChild) route = route.firstChild;
          return route;
        }),
        filter((route) => route.outlet === 'primary'),
        mergeMap((route) => route.data)
      )
      .subscribe((routeData) => {
        const pageIdentity = (routeData as TsRouteData)?.pageIdentity;
        this.setSiteIdentity(pageIdentity ?? DEFAULT_PAGE_IDENTITY);
      });

    this._subscriptions.add(subscription);
  }
}
