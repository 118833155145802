<!--
File: teamshufflr-info.component.html
Author: mafo (maximilian.fossler@teamshufflr.com)"
Last Modified: Tue Jan 25 2022
-----
Copyright (C) 2021, teamshufflr
All rights reserved.
-----
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
-->

<ng-container
  *transloco="let t; read: 'teamshufflrInfoModule.TsTeamshufflrInfoComponent'"
>
  <button
    class="ts-privacy-settings-button"
    mat-button
    (click)="cookieConsentsService.openConsentBanner(); itemClicked.emit()"
  >
    <div class="ts-flex-r ts-gap-s ts-align-start-center">
      <mat-icon color="primary">privacy_tip</mat-icon>
      {{ t("privacySettingsButton") }}
    </div>
  </button>
  <div id="routingActions">
    <a
      mat-button
      (click)="itemClicked.emit()"
      href="https://teamshufflr.com/imprint"
      target="_blank"
      >{{ t("imprint") }}</a
    >
    |
    <a
      mat-button
      (click)="itemClicked.emit()"
      href="https://teamshufflr.com/privacy"
      target="_blank"
      >{{ t("privacy") }}</a
    >
  </div>

  <mat-divider> </mat-divider>

  <div class="mat-caption" id="bottomSection">
    Made w/ <mat-icon [inline]="true" color="primary">favorite</mat-icon> in
    Nuremberg. - v{{ version }}
  </div>
</ng-container>
