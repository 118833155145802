/*
 * File: translation-root.module.ts                                                 *
 * Author: mafo (maximilian.fossler@teamshufflr.com)"                          *
 * Last Modified: Tue Jan 25 2022
 * -----                                                                       *
 * Copyright (C) 2021, teamshufflr                                             *
 * All rights reserved.                                                        *
 * -----                                                                       *
 * Unauthorized copying of this file, via any medium is strictly prohibited    *
 * Proprietary and confidential                                                *
 */

import { NgModule } from '@angular/core';
import { TranslocoModule, provideTransloco } from '@ngneat/transloco';
import {
  TranslocoLocaleModule,
  provideTranslocoLocale,
} from '@ngneat/transloco-locale';
import { provideTranslocoMessageformat } from '@ngneat/transloco-messageformat';

@NgModule({
  imports: [TranslocoModule, TranslocoLocaleModule],
  providers: [
    provideTransloco({
      config: {},
    }),
    provideTranslocoLocale({
      langToLocaleMapping: {
        en: 'en-US',
        de: 'de-DE',
      },
    }),
    provideTranslocoMessageformat({}),
  ],
  exports: [TranslocoModule, TranslocoLocaleModule],
})
export class TsTranslationRootModule {}
