/*
 * File: theme.service.ts                                                      *
 * Author: mafo (maximilian.fossler@teamshufflr.com)"                          *
 * Last Modified: Tue Jan 25 2022
 * -----                                                                       *
 * Copyright (C) 2021, teamshufflr                                             *
 * All rights reserved.                                                        *
 * -----                                                                       *
 * Unauthorized copying of this file, via any medium is strictly prohibited    *
 * Proprietary and confidential                                                *
 */

import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, OnDestroy, PLATFORM_ID } from '@angular/core';
import {
  COOKIE_NAME,
  TsCookieConsentService,
} from '@teamshufflr/common/services/cookie';
import { BehaviorSubject, fromEvent, Observable, Subscription } from 'rxjs';
import { auditTime, distinctUntilChanged } from 'rxjs/operators';

export const MOBILE_MAX_WIDTH_CONSTRAINT = 750;

/**
 * The css-class that is being added
 * to the document´s body, if the app´s dark-mode
 * is active.
 */
export const DARK_MODE_CLASS = 'ts-dark-mode';

/**
 * Service for handling the app´s
 * current theme.
 *
 * This service is provided in the application´s `root`.
 */
@Injectable({
  providedIn: 'root',
})
export class TsThemeService implements OnDestroy {
  /**
   * Whether or not the app´s dark mode is active.
   */
  private _isDarkTheme$: BehaviorSubject<boolean>;
  /**
   * Whether or not the mobile theme is active.
   */
  private _isMobile$: BehaviorSubject<boolean>;
  /**
   * Subject of resize-events on the {@link Document}´s body.
   */
  private _onResize$: BehaviorSubject<void>;
  /**
   * Subscription of the body´s ResizeObserver
   * created by {@link _addWindowResizeObserver}.
   */
  private _resizeSubscription: Subscription;
  private _subscriptions: Subscription;

  /**
   * Distinct Observable of whether the app´s
   * dark mode is active or not.
   *
   * Immediately fires it´s last value.
   */
  public get isDarkTheme(): Observable<boolean> {
    return this._isDarkTheme$.pipe(distinctUntilChanged());
  }
  /**
   * Whether or not the window
   * matches the mobile size-constraints.
   */
  public get isMobile(): boolean {
    return this._isMobile$.value;
  }
  /**
   * Distinct Observable of whether or not the window
   * matches the mobile size-constraints
   *
   * Immediately fires it´s last value.
   */
  public get isMobile$(): Observable<boolean> {
    return this._isMobile$.pipe(distinctUntilChanged());
  }

  /**
   * Observable of resize-events on the {@link Document}´s body.
   */
  get onResize$(): Observable<void> {
    return this._onResize$.asObservable();
  }

  constructor(
    @Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) protected platformID: Object,
    private cookieConsentService: TsCookieConsentService
  ) {
    this._subscriptions = new Subscription();

    const isDarkModePreferred = this._isDarkModePreferred();
    this._onResize$ = new BehaviorSubject<void>(void undefined);
    this._isMobile$ = new BehaviorSubject(this._matchesMobileSizeConstraints());
    this._isDarkTheme$ = new BehaviorSubject(isDarkModePreferred);

    this._toggleClass(isDarkModePreferred);
    this._resizeSubscription = this._addWindowResizeObserver();
    this._subscribeToCookieConsent();
  }

  /**
   * Unsubscribes from the {@link _resizeSubscription}.
   */
  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
    this._resizeSubscription?.unsubscribe();
  }
  /**
   * Toggling the app's theme between dark-
   * and light mode.
   *
   * @param forceDarkMode Whether or not the dark-mode should be forced.
   */
  toggleTheme(forceDarkMode?: boolean): boolean {
    const isDarkModeActive =
      forceDarkMode === true ? true : !this._isDarkTheme$.value;
    this._isDarkTheme$.next(isDarkModeActive);
    this._toggleClass(isDarkModeActive);
    this.cookieConsentService.updateCookie(
      COOKIE_NAME.IS_DARK_THEME_PREFERRED,
      isDarkModeActive ? '1' : '0'
    );

    return isDarkModeActive;
  }

  /**
   * Attaches a new EventListener via {@link EventManager} to the document´s body to listen to
   * resize events, hence to update both the {@link _onResize$} the {@link _isMobile$} Observable.
   */
  private _addWindowResizeObserver(): Subscription {
    const isBrowser = isPlatformBrowser(this.platformID) && this.document;

    if (!isBrowser) {
      return new Subscription();
    }

    return fromEvent(window, 'resize')
      .pipe(auditTime(50))
      .subscribe(() => {
        this._onResize$.next();
        this._isMobile$.next(this._matchesMobileSizeConstraints());
      });
  }

  /**
   * Whether or not the dark-mode is preferred in the
   * browser window settings.
   */
  private _isDarkModePreferred(): boolean {
    const isBrowser = isPlatformBrowser(this.platformID) && this.document;
    const isDarkThemePreferredCookie = this.cookieConsentService.getCookie(
      COOKIE_NAME.IS_DARK_THEME_PREFERRED
    );

    if (isBrowser) {
      const browserPreferredColorScheme =
        window.matchMedia &&
        window.matchMedia('(prefers-color-scheme: dark)').matches;
      if (isDarkThemePreferredCookie == null) {
        this.cookieConsentService.updateCookie(
          COOKIE_NAME.IS_DARK_THEME_PREFERRED,
          browserPreferredColorScheme ? '1' : '0'
        );
      }
      return browserPreferredColorScheme;
    }

    return isDarkThemePreferredCookie === '1';
  }

  /**
   * Whether or not the {@link width} does not exceed the
   * {@link MOBILE_MAX_WIDTH_CONSTRAINT}
   *
   * @param width The width that is tested against {@link MOBILE_MAX_WIDTH_CONSTRAINT}.
   * @returns
   */
  private _matchesMobileSizeConstraints(
    width = this.document.body.clientWidth
  ): boolean {
    return width <= MOBILE_MAX_WIDTH_CONSTRAINT;
  }

  private _setDarkThemeCookie(hasConsent: boolean): void {
    if (hasConsent) {
      const isDarkThemePreferredCookie = this.cookieConsentService.getCookie(
        COOKIE_NAME.IS_DARK_THEME_PREFERRED
      );
      if (isDarkThemePreferredCookie == null) {
        const browserPreferredColorScheme =
          window.matchMedia &&
          window.matchMedia('(prefers-color-scheme: dark)').matches;
        this.cookieConsentService.updateCookie(
          COOKIE_NAME.IS_DARK_THEME_PREFERRED,
          browserPreferredColorScheme ? '1' : '0'
        );
      }
    }
  }

  private async _subscribeToCookieConsent(): Promise<void> {
    this._subscriptions.add(
      this.cookieConsentService.cookieConsents$.subscribe((cookieConsents) => {
        this._setDarkThemeCookie(
          cookieConsents.FUNCTIONAL.FUNCTIONAL_TEAMSHUFFLR_COOKIES ?? false
        );
      })
    );
  }
  /**
   *
   * @param darkThemeActive
   */
  private _toggleClass(darkThemeActive: boolean): void {
    if (darkThemeActive) {
      this.document.body.classList.add(DARK_MODE_CLASS);
    } else {
      this.document.body.classList.remove(DARK_MODE_CLASS);
    }
  }
}
