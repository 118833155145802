/*
 * File: teamshufflr-info.component.ts                                         *
 * Author: mafo (maximilian.fossler@teamshufflr.com)"                          *
 * Last Modified: Tue Jan 25 2022
 * -----                                                                       *
 * Copyright (C) 2021, teamshufflr                                             *
 * All rights reserved.                                                        *
 * -----                                                                       *
 * Unauthorized copying of this file, via any medium is strictly prohibited    *
 * Proprietary and confidential                                                *
 */

import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Output,
} from '@angular/core';
import { TsCookieConsentService } from '@teamshufflr/common/services/cookie';
import { version } from '@teamshufflr/core-environments';

@Component({
  selector: 'ts-teamshufflr-info',
  templateUrl: './teamshufflr-info.component.html',
  styleUrls: ['./teamshufflr-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TsTeamshufflrInfoComponent {
  /**
   * Fired if one of the info´s links is clicked.
   */
  @Output()
  itemClicked = new EventEmitter<void>();

  version = version;

  constructor(public cookieConsentsService: TsCookieConsentService) {}
}
