/*
 * File: session-user.database.paths.ts                                        *
 * Author: mafo (maximilian.fossler@teamshufflr.com)"                          *
 * Last Modified: Sun Jan 02 2022
 * -----                                                                       *
 * Copyright (C) 2021, teamshufflr                                             *
 * All rights reserved.                                                        *
 * -----                                                                       *
 * Unauthorized copying of this file, via any medium is strictly prohibited    *
 * Proprietary and confidential                                                *
 */

/**
 * All Firebase Database paths related to {@link SessionModel}s.
 */
export abstract class SessionUserDatabasePaths {
  /**
   * Firebase Database Path of the {@link SessionModel}s collection.
   */
  static userCollection = 'user';

  /**
   * Firebase Database Path of the user´s {@link SessionUserChatPreviewsDatabaseModel}s document.
   *
   * @param userID The ID of the {@link SessionUserModel} for which to get the Firebase Database path to the
   * {@link SessionUserChatPreviewsDatabaseModel}s document.
   */
  static chatPreviewsDocument(userID: string): string {
    return SessionUserDatabasePaths.userDocument(userID) + '/' + 'chatPreviews';
  }

  /**
   * Firebase Database Path of a user´s {@link SessionUserChatRoomPreviewDatabaseModel}s document
   * corresponding to `chatRoomID`.
   *
   * @param userID The ID of the {@link SessionUserModel} for which to get the Firebase Database path to the
   * {@link SessionUserChatRoomPreviewDatabaseModel}s document corresponding to `chatRoomID`.
   * @param chatRoomID The ID of the {@link SessionUserChatRoomPreviewDatabaseModel} for which
   * to get Firebase Database path.
   */
  static chatRoomPreviewDocument(userID: string, chatRoomID: string): string {
    return (
      SessionUserDatabasePaths.chatRoomPreviewsCollection(userID) +
      '/' +
      chatRoomID
    );
  }

  /**
   * Firebase Database Path of a user´s {@link SessionUserChatPreviewsDatabaseModel.chatRoom chat-room previews} collection
   * corresponding to `userID`.
   *
   * @param userID The ID of the {@link SessionUserModel} for which to get the Firebase Database path to the
   * {@link SessionUserChatPreviewsDatabaseModel.chatRoom chat-room previews} collection.
   * @returns
   */
  static chatRoomPreviewsCollection(userID: string): string {
    return (
      SessionUserDatabasePaths.chatPreviewsDocument(userID) +
      '/' +
      'chatPreviews'
    );
  }

  static sessionDocument(sessionID: string, userID: string): string {
    return SessionUserDatabasePaths.userDocument(userID) + '/' + sessionID;
  }

  /**
   * Firebase Database Path of a single {@link SessionUserModel} document
   * corresponding to `userID`.
   *
   * @param userID The ID of the {@link SessionUserModel} to get the Firebase Database path.
   */
  static userDocument(userID: string): string {
    return SessionUserDatabasePaths.userCollection + '/' + userID;
  }
}
