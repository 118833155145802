<!--
File: consent-banner.component.html
Author: mafo (maximilian.fossler@teamshufflr.com)"
Last Modified: Tue Jan 25 2022
-----
Copyright (C) 2021, teamshufflr
All rights reserved.
-----
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
-->

<ng-container
  *transloco="let t; read: 'cookieConsentModule.TsConsentBannerComponent'"
>
  <div class="ts-flex-r ts-gap-s ts-align-center">
    <div id="cookie" class="mat-headline-2">🍪</div>
    <span id="description">{{ t("description") }}</span>
  </div>
  <div class="ts-flex-c ts-gap-s">
    <ts-cookie-consent-settings
      *ngIf="isCookieConsentSettingsVisible | async"
      [@fadeInOutHeight]
      [cookieConsents]="cookieConsentService.cookieConsents$ | async"
    ></ts-cookie-consent-settings>
    <div class="ts-flex-r ts-gap-s ts-align-center">
      <ng-container
        [ngTemplateOutlet]="rejectAllowCookiesButtonTemplate"
      ></ng-container>
      <ng-container
        *ngIf="isCookieConsentSettingsVisible | async"
        [ngTemplateOutlet]="saveCurrentConsentsButtonTemplate"
      ></ng-container>
      <ng-container
        [ngTemplateOutlet]="allowAllowCookiesButtonTemplate"
      ></ng-container>
    </div>
  </div>
  <mat-divider></mat-divider>

  <div
    id="secondaryActionsSection"
    class="ts-flex-r ts-align-center ts-wrap-reverse"
  >
    <div id="tsLegalLinksRow" class="ts-flex-r ts-align-start-center">
      <a mat-button href="https://teamshufflr.com/imprint" target="_blank">
        {{ t("imprintButtonTitle") }}
      </a>
      <a mat-button href="https://teamshufflr.com/privacy" target="_blank">
        {{ t("policyButtonTitle") }}
      </a>
    </div>
    <ng-container>
      <button
        id="tsOpenPrivacySettingsDialogButton"
        mat-button
        (click)="openPrivacySettingsDialog()"
      >
        {{ t("privacySettingsButtonTitle") }}
      </button>
    </ng-container>
  </div>

  <ng-template #rejectAllowCookiesButtonTemplate>
    <button mat-button (click)="rejectAllCookies()">
      {{ t("deny") }}
    </button>
  </ng-template>

  <ng-template #saveCurrentConsentsButtonTemplate>
    <button [@fadeInOutWidth] mat-raised-button (click)="saveCurrentConsents()">
      {{ t("saveCurrentSettingsButton") }}
    </button>
  </ng-template>

  <ng-template #allowAllowCookiesButtonTemplate>
    <button
      id="tsAllowAllowCookiesButton"
      color="primary"
      mat-raised-button
      (click)="acceptAllCookies()"
    >
      {{ t("allow") }}
    </button>
  </ng-template>
</ng-container>
