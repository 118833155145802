import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TsTranslationModule } from '@teamshufflr/common/services/translation';
import { TsPageIdentityService } from './page-identity.service';
/**
 * Provides the {@link TsPageIdentityService} to conveniently update
 * the document´s {@link PageIdentity} using Angular´s {@link Meta} &
 * {@link Title} services.
 *
 * @see {@link TsPageIdentityService}
 */
@NgModule({
  imports: [
    CommonModule,
    TsTranslationModule.withCustomLoader(
      (lang) => import(`./i18n/${lang}.json`),
      {
        translationScope: 'pageIdentityModule',
      }
    ),
  ],
  providers: [TsPageIdentityService],
})
export class TsPageIdentityModule {
  constructor(public tsPageIdentityService: TsPageIdentityService) {}
}
