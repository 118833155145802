/*
 * File: fadeInOutStaggered.animation.ts                                       *
 * Author: mafo (maximilian.fossler@teamshufflr.com)"                          *
 * Last Modified: Fri Dec 17 2021                                              *
 * -----                                                                       *
 * Copyright (C) 2021, teamshufflr                                             *
 * All rights reserved.                                                        *
 * -----                                                                       *
 * Unauthorized copying of this file, via any medium is strictly prohibited    *
 * Proprietary and confidential                                                *
 */

import {
  animate,
  query,
  stagger,
  style,
  transition,
  trigger,
} from '@angular/animations';

export const fadeInOutStaggeredAnimation = trigger('fadeInOutStaggered', [
  transition('* => true', [
    style({
      position: 'absolute',
      top: 0,
      left: 0,
    }),
    query('.ts-fade-element', [
      style({
        opacity: 0.0,
      }),
      stagger(100, [
        animate(
          '200ms ease-in-out',
          style({
            opacity: 1.0,
          })
        ),
      ]),
    ]),
  ]),
  transition('* => false', [
    style({
      position: 'absolute',
      top: 0,
      left: 0,
    }),
    query('.ts-fade-element', [
      style({
        opacity: 1.0,
      }),
      stagger(100, [
        animate(
          '200ms ease-in-out',
          style({
            opacity: 0.0,
          })
        ),
      ]),
    ]),
  ]),
]);
