/*
 * File: cookie-consent.service.ts                                             *
 * Author: mafo (maximilian.fossler@teamshufflr.com)"                          *
 * Last Modified: Tue Jan 25 2022
 * -----                                                                       *
 * Copyright (C) 2021, teamshufflr                                             *
 * All rights reserved.                                                        *
 * -----                                                                       *
 * Unauthorized copying of this file, via any medium is strictly prohibited    *
 * Proprietary and confidential                                                *
 */

import { isPlatformServer } from '@angular/common';
import { Inject, Injectable, OnDestroy, PLATFORM_ID } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { COOKIE_REGISTER, TsCookieInfo } from '..';
import { TsConsentBannerComponent } from '../components/consent-banner/consent-banner.component';
import { COOKIE_CATEGORY } from '../enums/cookie-category';
import { COOKIE_ID } from '../enums/cookie-id';
import { COOKIE_NAME } from '../enums/cookie-name';
import { TsCookieConsents } from '../interfaces/cookie-consents';
import { TsCookieDefinition } from '../interfaces/cookie-definition';
import { TsCookieService } from './cookie.service';

export const DEFAULT_COOKIE_CONSENTS: TsCookieConsents = {
  [COOKIE_CATEGORY.ESSENTIAL]: {
    [COOKIE_ID.ESSENTIAL_TEAMSHUFFLR_COOKIES]: true,
  },
  [COOKIE_CATEGORY.FUNCTIONAL]: {},
  [COOKIE_CATEGORY.STATISTIC]: {},
};

/**
 * Service for handling the user´s
 * cookie consents.
 *
 * If the user´s consent is unknown, the {@link TsConsentBannerComponent}
 * is being opened as MatBottomSheet to ask for cookie consent.
 *
 * For persisting the user´s cookie consent, a custom cookie ({@link COOKIE_NAME.CONSENT})
 * is set.
 */
@Injectable()
export class TsCookieConsentService implements OnDestroy {
  /**
   * BehaviorSubject of the cookie consent´s current status.
   *
   * Defaults to false.
   */
  private _cookieConsents: BehaviorSubject<TsCookieConsents>;
  private _subscriptions: Subscription;
  /**
   * Whether or not the user gave cookie consent.
   */
  get cookieConsents(): TsCookieConsents {
    return this._cookieConsents.value;
  }

  /**
   * Whether or not the user gave cookie consent as
   * Observable.
   */
  get cookieConsents$(): Observable<TsCookieConsents> {
    return this._cookieConsents.asObservable();
  }

  constructor(
    private matBottomSheet: MatBottomSheet,
    @Inject(PLATFORM_ID) private platformID: Object,
    private cookieService: TsCookieService
  ) {
    this._subscriptions = new Subscription();
    this._cookieConsents = new BehaviorSubject(this._fetchConsentCookie());
  }

  /**
   * Deletes the desired cookie.
   * @param name The name of the cookie to delete.
   */
  deleteCookie(name: string): void {
    this.cookieService.remove(name);
  }

  /**
   * Returns the cookie corresponding to `name`.
   *
   * @param name The name of the cookie to return.
   * @returns
   */
  getCookie(name: COOKIE_NAME): string | undefined {
    const cookieToUpdate = this._getCookieDefinitionByName(name);
    if (
      cookieToUpdate?.cookieDefinition != null &&
      this._hasConsentToSetCookie(cookieToUpdate.cookieDefinition)
    ) {
      return this.cookieService.get(name);
    }
    return undefined;
  }

  /**
   * Fetches the cookie consent cookie and eventually triggers
   * the {@link TsConsentBannerComponent} if the consent status is unknown.
   */
  initialize(): void {
    if (isPlatformServer(this.platformID)) {
      return;
    }
    const consentCookie = this.cookieService.get(COOKIE_NAME.CONSENT);
    if (consentCookie == null || consentCookie.length === 0) {
      this.openConsentBanner();
    } else {
      this._cookieConsents.next(JSON.parse(consentCookie));
    }
  }

  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

  /**
   * Opens the {@link TsConsentBannerComponent} as MatBottomSheet
   * to both ask for cookie consent and optionally configure the corresponding
   * {@link TsCookieConsents}.
   */
  openConsentBanner(): void {
    this.matBottomSheet
      .open<TsConsentBannerComponent, undefined, TsCookieConsents | undefined>(
        TsConsentBannerComponent,
        {
          disableClose: true,
          closeOnNavigation: false,
        }
      )
      .afterDismissed()
      .subscribe((cookieConsents) => {
        if (cookieConsents != null) {
          this._updateGlobalConsent(cookieConsents);
        }
      });
  }

  /**
   * Setting / updating a cookie.
   *
   * @param name The name of the cookie.
   * @param value THe value of the cookie.
   * @param expireDays The cookie´s expiration in days. Defaults to one year (365 days).
   * @param path The cookie´s path.
   */
  setCookie(
    name: string,
    value: string,
    expireDays: number = 365,
    path: string = ''
  ): void {
    const expiryDate: Date = new Date();
    expiryDate.setTime(expiryDate.getTime() + expireDays * 24 * 60 * 60 * 1000);

    this.cookieService.put(name, value, {
      expires: expiryDate,
      secure: true,
      sameSite: 'strict',
      path: path,
    });
  }

  updateCookie(cookie: COOKIE_NAME, value: string): void {
    const cookieToUpdate = this._getCookieDefinitionByName(cookie);
    if (cookieToUpdate != null) {
      if (this._hasConsentToSetCookie(cookieToUpdate.cookieDefinition)) {
        const expiryDate: Date = new Date();
        expiryDate.setTime(
          expiryDate.getTime() + cookieToUpdate.cookieInfo.duration
        );

        this.cookieService.put(cookie, value, {
          expires: expiryDate,
          secure: true,
          sameSite: 'strict',
          path: '/',
        });
      }
    }
  }

  /**
   * Fetches the {@link COOKIE_NAME.CONSENT} and updates the
   * {@link _hasConsent} stream.
   *
   * @returns Whether or not the user gave cookie consent.
   */
  private _fetchConsentCookie(): TsCookieConsents {
    const consentCookie = this.cookieService.get(COOKIE_NAME.CONSENT);

    if (consentCookie != null && consentCookie.length > 0) {
      let parsedConsents: TsCookieConsents;
      try {
        parsedConsents = JSON.parse(consentCookie) as TsCookieConsents;
        return parsedConsents;
      } catch (e) {
        // Return default cookies.
      }
    }

    return DEFAULT_COOKIE_CONSENTS;
  }

  private _getCookieDefinitionByName(cookie: COOKIE_NAME):
    | {
        cookieDefinition: TsCookieDefinition;
        cookieInfo: TsCookieInfo;
      }
    | undefined {
    let cookieToUpdate:
      | {
          cookieDefinition: TsCookieDefinition;
          cookieInfo: TsCookieInfo;
        }
      | undefined;

    for (const category of Object.keys(COOKIE_REGISTER)) {
      for (const cookieDefinition of COOKIE_REGISTER[
        category as COOKIE_CATEGORY
      ]) {
        const cookieInfo = cookieDefinition.cookieInfos.find(
          (cookieInfo) => cookieInfo.name === cookie
        );
        if (cookieInfo != null) {
          cookieToUpdate = {
            cookieDefinition,
            cookieInfo,
          };
          break;
        }
      }
    }
    return cookieToUpdate;
  }

  private _hasConsentToSetCookie(
    cookieDefinition: TsCookieDefinition
  ): boolean {
    const hasConsentToSetCookie =
      this._cookieConsents.value[cookieDefinition.category][
        cookieDefinition.id
      ] === true;

    return hasConsentToSetCookie;
  }

  /**
   * Updates the consent status.
   *
   * @param cookieConsents The updated {@link TsCookieConsents}.
   * @returns
   */
  private _updateGlobalConsent(cookieConsents: TsCookieConsents): void {
    const expiryDate: Date = new Date();
    expiryDate.setTime(expiryDate.getTime() + 365 * 24 * 60 * 60 * 1000);

    this.cookieService.put(
      COOKIE_NAME.CONSENT,
      JSON.stringify(cookieConsents),
      {
        expires: expiryDate,
        secure: true,
        sameSite: 'strict',
        path: '/',
      }
    );

    // Remove all cookies that have no consent.
    Object.values(COOKIE_REGISTER).forEach((cookieDefinitions) => {
      cookieDefinitions.forEach((cookieDefinition) => {
        if (
          cookieConsents[cookieDefinition.category][cookieDefinition.id] !==
          true
        ) {
          cookieDefinition.cookieInfos.forEach((cookieInfo) => {
            this.deleteCookie(cookieInfo.name);
          });
        }
      });
    });

    this._cookieConsents.next(cookieConsents);
  }
}
