/*
 * File: session-chat-room-database.repository.ts                              *
 * Author: mafo (maximilian.fossler@teamshufflr.com)"                          *
 * Last Modified: Fri Jan 21 2022
 * -----                                                                       *
 * Copyright (C) 2021, teamshufflr                                             *
 * All rights reserved.                                                        *
 * -----                                                                       *
 * Unauthorized copying of this file, via any medium is strictly prohibited    *
 * Proprietary and confidential                                                *
 */

import { Injectable, Optional } from '@angular/core';
import {
  Database,
  equalTo,
  onValue,
  orderByChild,
  push,
  query,
  ref,
  set,
} from '@angular/fire/database';
import { SessionChatRoomDatabasePaths } from '@teamshufflr/core-api-firebase-paths';
import {
  SessionChatMessageDatabaseModel,
  SessionChatMessagesDatabaseModel,
} from '@teamshufflr/core-api-models';
import { Observable, of } from 'rxjs';

/**fakeChatRoomID
 * Service for handling for manipulating
 * {@link SessionChatRoomDatabaseModel chat-rooms} in the Firebase Database
 * {@link SessionChatRoomDatabasePaths.chatRoomsCollection session-chat-rooms Collection}.
 */
@Injectable({
  providedIn: 'root',
})
export class TsSessionChatRoomDatabaseRepository {
  private constructor(@Optional() private database?: Database) {
    // Empty private constructor.
  }

  /**
   * Adds a {@link SessionChatMessageDatabaseModel message} to the
   * {@link SessionChatRoomDatabasePaths.chatRoomMessagesCollection messages collection}
   * corresponding to {@link chatRoomID} in the Firebase Database.
   *
   * @param chatMessage The {@link SessionChatMessageDatabaseModel message} to add to the
   * {@link SessionChatRoomDatabasePaths.chatRoomMessagesCollection messages collection}.
   * @param chatRoomID The ID of the {@link SessionChatRoomDatabaseModel chat-room} to add the `chatMessage`
   * to.
   *
   * @returns
   */
  async addMessage(
    chatMessage: Omit<
      SessionChatMessageDatabaseModel,
      'id' | 'dateOfSubmission'
    >
  ): Promise<void> {
    if (this.database == null) {
      return;
    }

    const messagesCollectionRef = ref(
      this.database,
      SessionChatRoomDatabasePaths.chatRoomMessagesCollection
    );
    const messageID = push(messagesCollectionRef).key as string;
    const message: SessionChatMessageDatabaseModel = {
      ...chatMessage,
      id: messageID,
      dateOfSubmission: Date.now(),
    };

    const messageDocumentRef = ref(
      this.database,
      SessionChatRoomDatabasePaths.chatRoomMessageDocument(messageID)
    );

    await set(messageDocumentRef, message);
  }

  /**
   *
   * @param chatRoomID
   * @returns
   */
  getMessages$(
    chatRoomID: string
  ): Observable<Array<SessionChatMessageDatabaseModel> | null> {
    if (this.database == null) {
      return of(null);
    }

    const chatRoomMessagesRef = ref(
      this.database,
      SessionChatRoomDatabasePaths.chatRoomMessagesCollection
    );

    const messagesOfChatRoomRef = query(
      chatRoomMessagesRef,
      orderByChild('chatRoomID'),
      equalTo(chatRoomID)
    );

    return new Observable<Array<SessionChatMessageDatabaseModel> | null>(
      (subscriber) => {
        const unsubscribe = onValue(
          messagesOfChatRoomRef,
          (snapshot) => {
            const messagesMap: SessionChatMessagesDatabaseModel =
              snapshot.val() ?? {};

            const messages = Object.values(messagesMap);
            messages.sort((a, b) => b.dateOfSubmission - a.dateOfSubmission);

            subscriber.next(messages);
          },
          (err) => {
            subscriber.error(err);
          }
        );

        subscriber.add(() => unsubscribe());
      }
    );
  }
}
