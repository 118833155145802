/*
 * File: session-chat-room.database.paths.ts                                   *
 * Author: mafo (maximilian.fossler@teamshufflr.com)"                          *
 * Last Modified: Fri Jan 21 2022
 * -----                                                                       *
 * Copyright (C) 2021, teamshufflr                                             *
 * All rights reserved.                                                        *
 * -----                                                                       *
 * Unauthorized copying of this file, via any medium is strictly prohibited    *
 * Proprietary and confidential                                                *
 */

/**
 * All Firebase Database paths related to {@link SessionChatRoomDatabaseModel}s.
 */
export abstract class SessionChatRoomDatabasePaths {
  static baseCollectionPath = 'online-session';
  /**
   * Firebase Database Path of a {@link SessionChatRoomDatabaseModel chat-room´s} messages
   *
   * @returns
   */
  static chatRoomMessagesCollection =
    SessionChatRoomDatabasePaths.baseCollectionPath + '/session-chat-message';

  /**
   *
   * @param messageID
   * @param chatRoomID
   * @returns
   */
  static chatRoomMessageDocument(messageID: string): string {
    return (
      SessionChatRoomDatabasePaths.chatRoomMessagesCollection + '/' + messageID
    );
  }
}
