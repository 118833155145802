import { Injectable } from '@angular/core';
import { TsSessionChatRoomDatabaseRepository } from '@teamshufflr/common/repositories/database';
import { TsSessionFirestoreRepository } from '@teamshufflr/common/repositories/firestore';
import {
  CHAT_ROOM_TYPE,
  SessionChatMessageDatabaseModel,
  SessionChatRoomModel,
} from '@teamshufflr/core-api-models';
import {
  BehaviorSubject,
  firstValueFrom,
  Observable,
  Subscription,
} from 'rxjs';
import { filter } from 'rxjs/operators';

/**
 * Service for providing and handling the currently
 * active {@link _currentChatRooms chat-rooms}.
 */
@Injectable()
export class TsSessionChatRoomProviderService {
  /**
   * BehaviorSubject of all currently available {@link SessionChatRoomDatabaseModel chat-rooms},
   * initialized with `null`.
   */
  private _currentChatRooms: BehaviorSubject<Array<SessionChatRoomModel> | null>;

  /**
   * Holds references to all subscriptions created in this service.
   */
  private _subscriptions: Subscription;

  /**
   * All currently available {@link SessionChatRoomDatabaseModel chat-rooms}
   */
  get currentChatRooms(): Array<SessionChatRoomModel> | null {
    return this._currentChatRooms.value;
  }

  /**
   * Observable of all currently available {@link SessionChatRoomDatabaseModel chat-rooms}
   */
  get currentChatRooms$(): Observable<Array<SessionChatRoomModel> | null> {
    return this._currentChatRooms.asObservable();
  }

  constructor(
    private sessionFirestoreRepository: TsSessionFirestoreRepository,
    private sessionChatRoomDatabaseRepository: TsSessionChatRoomDatabaseRepository
  ) {
    this._subscriptions = new Subscription();
    this._currentChatRooms =
      new BehaviorSubject<Array<SessionChatRoomModel> | null>(null);
  }

  /**
   * Returns an observable to the {@link SessionChatMessageDatabaseModel messages}
   * of the {@link SessionChatRoomModel chat-room} corresponding to {@link chatRoomID}.
   *
   * @param chatRoomID The ID of the {@link SessionChatRoomModel chat-room} for which
   * to return an observable to its {@link SessionChatMessageDatabaseModel messages} to.
   */
  getMessageHistory$(
    chatRoomID: string
  ): Observable<Array<SessionChatMessageDatabaseModel> | null> {
    return this.sessionChatRoomDatabaseRepository.getMessages$(chatRoomID);
  }

  /**
   * Initializes a snapshot-subscription to the chat-rooms
   * of the user corresponding to `userID`.
   *
   * @param userID The ID of the {@link SessionUserModel user} for which to
   * initialize the session chat.
   * @param sessionID The ID of the {@link SessionModel session} for which to
   * initialize the session chat.
   *
   * @returns Promise that resolves on the stream´s first value.
   */
  initialize(
    userID: string,
    sessionID: string
  ): Promise<Array<SessionChatRoomModel>> {
    this._subscribeToChatRooms(userID, sessionID);

    return firstValueFrom(
      this._currentChatRooms.pipe(
        filter((res): res is Array<SessionChatRoomModel> => res != null)
      )
    );
  }

  /**
   * Resets this TsSessionChatRoomProviderService by both unsubscribing
   * to current and re-initializing all existing Subscriptions.
   */
  reset(): void {
    this._subscriptions.unsubscribe();

    this._subscriptions = new Subscription();
    this._currentChatRooms.next(null);
  }

  /**
   * Publishes the provided {@link message} in the {@link SessionChatRoomDatabaseModel chat-room}
   * corresponding to {@link SessionChatMessageDatabaseModel.chatRoomID}.
   *
   * @param message The {@link SessionChatMessageDatabaseModel message} to publish.
   */
  submitMessage(
    message: Omit<SessionChatMessageDatabaseModel, 'id' | 'dateOfSubmission'>
  ): Promise<void> {
    return this.sessionChatRoomDatabaseRepository.addMessage(message);
  }

  /**
   * Initializes a snapshot-subscription to the {@link SessionChatRoomModel chat-rooms}
   * the {@link SessionUserModel user} corresponding to {@link userID} has access to.
   *
   * @param userID The ID of the {@link SessionUserModel user} for which to initialize
   * a snapshot-subscription to the {@link SessionChatRoomModel chat-rooms} is
   * has access to.
   * @param sessionID The ID of the {@link SessionModel}.
   */
  private _subscribeToChatRooms(userID: string, sessionID: string): void {
    this._subscriptions.add(
      this.sessionFirestoreRepository
        .getChatRooms$(userID, sessionID)
        .subscribe((chatRooms) => {
          chatRooms?.sort((a, b) => {
            if (a.type !== b.type) {
              if (a.type === CHAT_ROOM_TYPE.LOBBY_CHAT) {
                return -1;
              } else if (a.type === CHAT_ROOM_TYPE.TEAM_CHAT) {
                return -1;
              }
            }
            return a.archived === b.archived ? 0 : a.archived ? 1 : -1;
          });

          this._currentChatRooms.next(chatRooms);
        })
    );
  }
}
