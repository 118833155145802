/*
 * File: routingProgressIndicator.module.ts                                    *
 * Author: mafo (maximilian.fossler@teamshufflr.com)"                          *
 * Last Modified: Fri Dec 17 2021                                              *
 * -----                                                                       *
 * Copyright (C) 2021, teamshufflr                                             *
 * All rights reserved.                                                        *
 * -----                                                                       *
 * Unauthorized copying of this file, via any medium is strictly prohibited    *
 * Proprietary and confidential                                                *
 */

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { RouterModule } from '@angular/router';
import { TsRoutingProgressIndicatorComponent } from './routing-progress-indicator.component';

@NgModule({
  declarations: [TsRoutingProgressIndicatorComponent],
  imports: [CommonModule, MatProgressBarModule, RouterModule],
  exports: [TsRoutingProgressIndicatorComponent],
})
export class TsRoutingProgressIndicatorModule {}
