/*
 * File: loading-spinner.module.ts                                             *
 * Author: mafo (maximilian.fossler@teamshufflr.com)"                          *
 * Last Modified: Fri Dec 17 2021                                              *
 * -----                                                                       *
 * Copyright (C) 2021, teamshufflr                                             *
 * All rights reserved.                                                        *
 * -----                                                                       *
 * Unauthorized copying of this file, via any medium is strictly prohibited    *
 * Proprietary and confidential                                                *
 */

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TsLoadingSpinnerComponent } from './loading-spinner.component';

/**
 * Consists of an animated loading spinner using css keyframe animations,
 * animating the elements of the teamshufflr-logo.
 *
 * ```html
 *  <ts-loading-spinner
 *    [elevated]=true
 *  >
 *  </ts-loading-spinner>
 * ```
 */
@NgModule({
  declarations: [TsLoadingSpinnerComponent],
  imports: [CommonModule],
  providers: [],
  exports: [TsLoadingSpinnerComponent],
})
export class TsLoadingSpinnerModule {}
