import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { TsCookieUtil } from '../cookie-util';
import { TsCookieOptions } from '../interfaces/cookie-options';
import { ITsCookieWriterService } from '../interfaces/cookie-writer-service';

@Injectable()
export class TsCookieWriterService implements ITsCookieWriterService {
  constructor(@Inject(DOCUMENT) private document: any) {}

  readAllAsString(): string {
    return this.document.cookie || '';
  }

  write(
    name: string,
    value: string | undefined,
    options?: TsCookieOptions
  ): void {
    this.document.cookie = TsCookieUtil.buildCookieString(name, value, options);
  }
}
