/*
 * File: cookie-type.ts
 * Author: mafo (maximilian.fossler@teamshufflr.com)"
 * Last Modified: Sun Jan 23 2022
 * -----
 * Copyright (C) 2021 - 2022, teamshufflr
 * All rights reserved.
 * -----
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

/**
 * The type of a cookie regarding its technical definition.
 */
export enum COOKIE_TYPE {
  HTTP_COOKIE = 'HTTP_COOKIE',
}
