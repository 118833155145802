/*
 * File: loading-spinner.component.ts                                          *
 * Author: mafo (maximilian.fossler@teamshufflr.com)"                          *
 * Last Modified: Fri Dec 17 2021                                              *
 * -----                                                                       *
 * Copyright (C) 2021, teamshufflr                                             *
 * All rights reserved.                                                        *
 * -----                                                                       *
 * Unauthorized copying of this file, via any medium is strictly prohibited    *
 * Proprietary and confidential                                                *
 */

import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
} from '@angular/core';
import { TsThemeService } from '@teamshufflr/common/services/theme-service';

/**
 * A loading spinner created using css keyframe animations,
 * animating the elements of the teamshufflr-logo.
 *
 * ```html
 *  <ts-loading-spinner
 *    [elevated]=true
 *  >
 *  </ts-loading-spinner
 * ```
 */
@Component({
  selector: 'ts-loading-spinner',
  templateUrl: './loading-spinner.component.html',
  styleUrls: ['./loading-spinner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TsLoadingSpinnerComponent {
  /**
   * Whether or not the loading spinner
   * should drop a shadow.
   *
   * Defaults to true.
   */
  @HostBinding('class.ts-loading-spinner-elevated')
  @Input()
  elevated = true;
  /**
   * Whether or not the spinner´s
   * theme should be inverted.
   */
  @Input()
  inverted = false;

  constructor(public themeService: TsThemeService) {}
}
