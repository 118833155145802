/*
 * File: is-session-host.directive.ts                                          *
 * Author: mafo (maximilian.fossler@teamshufflr.com)"                          *
 * Last Modified: Sat Nov 04 2023
 * -----                                                                       *
 * Copyright (C) 2021, teamshufflr                                             *
 * All rights reserved.                                                        *
 * -----                                                                       *
 * Unauthorized copying of this file, via any medium is strictly prohibited    *
 * Proprietary and confidential                                                *
 */

import {
  Directive,
  EmbeddedViewRef,
  Input,
  OnDestroy,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { TsPermissionService } from '../permission.service';

/**
 * A structural directive that conditionally includes a template based on whether or not
 * the {@link SessionUser} corresponding to the provided `userID` is the current {@link SessionModel}´s host.
 *
 * If the user´s the session´s host,  the template is rendered in the provided
 * `then`, and when false or null, in an optional `else` clause. The default
 * template for the `else` clause is blank.
 *
 * ___
 * Example usage with shorthand syntax:
 *
 * ```html
 *  <div *tsIsSessionHost></div>
 * ```
 *
 *
 * Example usage with shorthand syntax and else block:
 *
 * ```html
 *  <div *tsIsSessionHost="this; else elseBlock">Content to render when user is host.</div>
 *  <ng-template #elseBlock>Content to render when condition is false.</ng-template>
 * ```
 *
 *
 * Simple form with expanded syntax:
 *
 * ```html
 *  <div [tsIsSessionHost]>Content to render when user is host.</div>
 * ```
 *
 *
 * Simple form with expanded syntax and else block:
 *
 * ```html
 *  <div [tsIsSessionHost] [tsIsSessionHostElse]="elseBlock">Content to render when user is host.</div>
 *  <ng-template #elseBlock>Content to render when condition is false.</ng-template>
 * ```
 */
@Directive({
  selector: '[tsIsSessionHost]',
})
export class TsIsSessionHostDirective<T = unknown> implements OnDestroy {
  private _context: TsIsSessionHostDirectiveContext<T> =
    new TsIsSessionHostDirectiveContext<T>();

  private _elseTemplateRef: TemplateRef<
    TsIsSessionHostDirectiveContext<T>
  > | null = null;
  private _elseViewRef: EmbeddedViewRef<
    TsIsSessionHostDirectiveContext<T>
  > | null = null;
  private _isUserSessionHost = false;
  private _subscriptions: Subscription;
  private _thenTemplateRef: TemplateRef<
    TsIsSessionHostDirectiveContext<T>
  > | null = null;
  private _thenViewRef: EmbeddedViewRef<
    TsIsSessionHostDirectiveContext<T>
  > | null = null;

  constructor(
    private _viewContainer: ViewContainerRef,
    templateRef: TemplateRef<TsIsSessionHostDirectiveContext<T>>,
    private permissionService: TsPermissionService
  ) {
    this._thenTemplateRef = templateRef;
    this._subscriptions = this.permissionService
      .isSessionHost$()
      .subscribe((res) => {
        this._isUserSessionHost = res;
        this._updateView(res);
      });
  }

  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

  @Input()
  set tsIsSessionHost(_value: any) {
    this._subscriptions.unsubscribe();
    this._subscriptions = this.permissionService
      .isSessionHost$()
      .subscribe((res) => {
        this._isUserSessionHost = res;
        this._updateView(res);
      });
  }

  /**
   * A template to show if the condition expression evaluates to false.
   */
  @Input()
  set tsIsSessionHostElse(
    templateRef: TemplateRef<TsIsSessionHostDirectiveContext<T>>
  ) {
    this._elseTemplateRef = templateRef;
    this._elseViewRef = null; // clear previous view if any.
    this._updateView(this._isUserSessionHost);
  }

  private _updateView(isSessionHost: boolean) {
    this._context.isSessionHost = isSessionHost;

    if (isSessionHost) {
      if (!this._thenViewRef) {
        this._viewContainer.clear();
        this._elseViewRef = null;

        if (this._thenTemplateRef) {
          this._thenViewRef = this._viewContainer.createEmbeddedView(
            this._thenTemplateRef,
            this._context
          );
        }
      }
    } else {
      if (!this._elseViewRef) {
        this._viewContainer.clear();
        this._thenViewRef = null;

        if (this._elseTemplateRef) {
          this._thenViewRef = this._viewContainer.createEmbeddedView(
            this._elseTemplateRef,
            this._context
          );
        }
      }
    }
  }
}

/**
 * The context for the {@link TsIsSessionHostDirective }.
 */
export class TsIsSessionHostDirectiveContext<T = unknown> {
  public $implicit?: T;

  /**
   * Whether the user is the current {@link SessionModel}´s host.
   */
  public isSessionHost?: boolean;
}
