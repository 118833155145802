/*
 * File: recent-session-overview-card.component.ts                             *
 * Author: mafo (maximilian.fossler@teamshufflr.com)"                          *
 * Last Modified: Fri Dec 17 2021                                              *
 * -----                                                                       *
 * Copyright (C) 2021, teamshufflr                                             *
 * All rights reserved.                                                        *
 * -----                                                                       *
 * Unauthorized copying of this file, via any medium is strictly prohibited    *
 * Proprietary and confidential                                                *
 */

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TsSessionUserProviderService } from '@teamshufflr/common/providers';
import { SessionModel } from '@teamshufflr/core-api-models';

/**
 * A card-style component consisting of a brief overview of
 * the provided {@link session} that consists of the session´s name,
 * a description of how many members are currently left.
 *
 * ___
 * Example usage:
 *
 * ```html
 *  <ts-recent-session-overview-card
 *    [session]="session"
 *   >
 *  </ts-recent-session-overview-card>
 * ```
 */
@Component({
  selector: 'ts-recent-session-overview-card',
  templateUrl: './recent-session-overview-card.component.html',
  styleUrls: ['./recent-session-overview-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    class: 'ts-flex-c ts-gap-s ts-rounded-card ts-padding-m',
  },
})
export class TsRecentSessionOverviewCardComponent {
  @Input()
  session?: SessionModel;
  constructor(
    public sessionUserProviderService: TsSessionUserProviderService
  ) {}
}
