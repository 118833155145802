/*
 * File: routing-progress-indicator.component.ts                               *
 * Author: mafo (maximilian.fossler@teamshufflr.com)"                          *
 * Last Modified: Mon Dec 27 2021
 * -----                                                                       *
 * Copyright (C) 2021, teamshufflr                                             *
 * All rights reserved.                                                        *
 * -----                                                                       *
 * Unauthorized copying of this file, via any medium is strictly prohibited    *
 * Proprietary and confidential                                                *
 */

import { Component } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { fadeInOutAnimation } from '@teamshufflr/common/animations';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Component({
  selector: 'ts-routing-progress-indicator',
  templateUrl: './routing-progress-indicator.component.html',
  styleUrls: ['./routing-progress-indicator.component.scss'],
  animations: [fadeInOutAnimation],
})
export class TsRoutingProgressIndicatorComponent {
  showProgressBar: Observable<boolean>;
  constructor(private router: Router) {
    this.showProgressBar = this.router.events.pipe(
      filter(
        (event): event is NavigationStart | NavigationEnd =>
          event instanceof NavigationStart || event instanceof NavigationEnd
      ),
      map((event) => {
        if (event instanceof NavigationStart) {
          return true;
        } else {
          return false;
        }
      })
    );
  }
}
