/*
 * File: loading-wall.module.ts                                                *
 * Author: mafo (maximilian.fossler@teamshufflr.com)"                          *
 * Last Modified: Mon Jan 10 2022
 * -----                                                                       *
 * Copyright (C) 2021, teamshufflr                                             *
 * All rights reserved.                                                        *
 * -----                                                                       *
 * Unauthorized copying of this file, via any medium is strictly prohibited    *
 * Proprietary and confidential                                                *
 */

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TsLoadingSpinnerModule } from '@teamshufflr/common/components/loading-spinner';
import { TsTranslationModule } from '@teamshufflr/common/services/translation';
import { TsLoadingWallComponent } from './loading-wall.component';
import { TsLoadingWallService } from './loading-wall.service';

/**
 * Creates a wall that is overlaying the application
 * indication a loading process with dynamic contents
 * corresponding to {@link TS_LOADING_WALL}.
 *
 * The TsLoadingWallComponent is automatically shown,
 * whenever {@link TsLoadingWallService.isLoadingWallVisible} is not null.
 *
 * This component should only be used inside the application´s root component.
 */
@NgModule({
  declarations: [TsLoadingWallComponent],
  imports: [
    CommonModule,
    TsLoadingSpinnerModule,
    TsTranslationModule.withCustomLoader(
      (lang) => import(`../i18n/${lang}.json`),
      {
        translationScope: 'loadingWallModule',
      }
    ),
  ],
  providers: [TsLoadingWallService],
  exports: [TsLoadingWallComponent],
})
export class TsLoadingWallModule {}
