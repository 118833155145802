/*
 * File: language-picker.component.ts                                          *
 * Author: mafo (maximilian.fossler@teamshufflr.com)"                          *
 * Last Modified: Tue Jan 25 2022
 * -----                                                                       *
 * Copyright (C) 2021, teamshufflr                                             *
 * All rights reserved.                                                        *
 * -----                                                                       *
 * Unauthorized copying of this file, via any medium is strictly prohibited    *
 * Proprietary and confidential                                                *
 */

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import {
  COOKIE_NAME,
  TsCookieConsentService,
} from '@teamshufflr/common/services/cookie';
import { SUPPORTED_LANGUAGE } from '@teamshufflr/core-constants';
import { Subscription } from 'rxjs';

/**
 * Simple LanguagePicker Component
 *
 *
 * Provides a [MatMenu] for changing the app's language.
 *
 * Currently supported languages: 'de' & 'en'
 */
@Component({
  selector: 'ts-language-picker',
  templateUrl: './language-picker.component.html',
  styleUrls: ['./language-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TsLanguagePickerComponent implements OnInit, OnDestroy {
  SUPPORTED_LANGUAGE = SUPPORTED_LANGUAGE;

  private _subscriptions: Subscription;
  selectedLanguage?: string;

  constructor(
    private translocoService: TranslocoService,
    private cd: ChangeDetectorRef,
    private cookieConsentService: TsCookieConsentService
  ) {
    this._subscriptions = new Subscription();
  }
  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

  ngOnInit(): void {
    this._initializeLangChangeSubscription();
  }

  setActiveLang(newLang: 'de' | 'en'): void {
    this.translocoService.setActiveLang(newLang);
    this.cookieConsentService.updateCookie(
      COOKIE_NAME.PREFERRED_LANGUAGE,
      newLang
    );
  }

  private _initializeLangChangeSubscription() {
    this._subscriptions.add(
      this.translocoService.langChanges$.subscribe((lang) => {
        this.selectedLanguage = lang;
        this.cd.detectChanges();
      })
    );
  }
}
