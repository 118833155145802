<!--
File: language-picker.component.html
Author: mafo (maximilian.fossler@teamshufflr.com)"
Last Modified: Tue Oct 10 2023
-----
Copyright (C) 2021, teamshufflr
All rights reserved.
-----
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
-->

<ng-container *transloco="let t; read: 'languagePickerModule'">
  <mat-select
    [(value)]="selectedLanguage"
    class="ts-flex-c ts-align-center"
    (selectionChange)="setActiveLang($event.value)"
    panelClass="ts-language-picker-panel"
    panelWidth="null"
    hideSingleSelectionIndicator="true"
  >
    <mat-select-trigger>
      <button
        title="{{ t('menuTriggerButtonTitle') }}"
        [attr.aria-label]="t('menuTriggerButtonTitle')"
        id="langMenuTriggerButton"
        mat-icon-button
      >
        <div class="lang-icon {{ selectedLanguage + '_icon' }}"></div>
      </button>
    </mat-select-trigger>
    <mat-option
      *ngFor="let language of SUPPORTED_LANGUAGE | keyvalue"
      [value]="language.value"
    >
      <div class="ts-flex-r ts-gap-s ts-language-option-container">
        <div [id]="" class="lang-icon {{ language.value + '_icon' }}"></div>
        <span>{{ t("language_" + language.value) }}</span>
      </div>
    </mat-option>
  </mat-select>
</ng-container>
