/*
 * File: session.firestore.paths.ts                                            *
 * Author: mafo (maximilian.fossler@teamshufflr.com)"                          *
 * Last Modified: Fri Jan 21 2022
 * -----                                                                       *
 * Copyright (C) 2021, teamshufflr                                             *
 * All rights reserved.                                                        *
 * -----                                                                       *
 * Unauthorized copying of this file, via any medium is strictly prohibited    *
 * Proprietary and confidential                                                *
 */

/**
 * All Firestore paths related to {@link SessionModel}s.
 */
export abstract class SessionFirestorePaths {
  /**
   * Slug of the path to the {@link SessionUserChatRoomModel}s sub-collection
   * located at `/session/<sessionID>/chat-room`.
   */
  static chatRoomCollectionSlug = 'chat-room';

  /**
   * Path of the {@link SessionModel}s collection.
   */
  static sessionCollection = 'session';

  /**
   * Path of a single {@link SessionUserChatRoomModel} document
   * corresponding to `chatRoomID`.
   *
   * @param chatRoomID The ID of the {@link SessionUserChatRoomModel} to get the Firestore path for.
   * @param sessionID The ID of the {@link SessionModel} the chat-room belongs to.
   * @returns
   */
  static sessionChatRoomDocument(
    chatRoomID: string,
    sessionID: string
  ): string {
    return (
      SessionFirestorePaths.sessionChatRoomsCollection(sessionID) +
      '/' +
      chatRoomID
    );
  }
  /**
   * Path of the {@link SessionUserChatRoomModel}s sub-collection
   * corresponding to `sessionID`.
   *
   * @param sessionID
   */
  static sessionChatRoomsCollection(sessionID: string): string {
    return (
      SessionFirestorePaths.sessionDocument(sessionID) +
      '/' +
      SessionFirestorePaths.chatRoomCollectionSlug
    );
  }

  /**
   * Path of a single {@link SessionModel} document
   * corresponding to `sessionID`.
   *
   * @param sessionID The ID of the {@link SessionModel} to get the Firestore path for.
   * @returns
   */
  static sessionDocument(sessionID: string): string {
    return SessionFirestorePaths.sessionCollection + '/' + sessionID;
  }

  /**
   * Path of the {@link sessionUserModel}s sub-collection
   * corresponding to `sessionID`.
   *
   * @param sessionID
   */
  static userCollection(sessionID: string): string {
    return SessionFirestorePaths.sessionDocument(sessionID) + '/user';
  }

  /**
   * Path of a single {@link sessionUserModel} document
   * corresponding to `userID`.
   *
   * @param userID The ID of the {@link sessionUserModel} to get the Firestore path for.
   * @param sessionID The ID of the {@link SessionModel} the user is in.
   *
   * @returns
   */
  static userDocument(userID: string, sessionID: string): string {
    return SessionFirestorePaths.userCollection(sessionID) + '/' + userID;
  }
}
