/*
 * File: cookie.service.ts
 * Author: mafo (maximilian.fossler@teamshufflr.com)"
 * Last Modified: Sat Jan 15 2022
 * -----
 * Copyright (C) 2021 - 2022, teamshufflr
 * All rights reserved.
 * -----
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { Inject, Injectable } from '@angular/core';
import { TsCookieUtil } from '../cookie-util';
import { TsCookieOptions } from '../interfaces/cookie-options';
import { ITsCookieService } from '../interfaces/cookie-service';
import { ITsCookieWriterService } from '../interfaces/cookie-writer-service';
import { TS_COOKIE_WRITER } from '../tokens';
import { TsCookieOptionsProvider } from './cookie-options-provider.service';

@Injectable()
export class TsCookieService implements ITsCookieService {
  protected options: TsCookieOptions;

  constructor(
    private optionsProvider: TsCookieOptionsProvider,
    @Inject(TS_COOKIE_WRITER)
    private cookieWriterService: ITsCookieWriterService
  ) {
    this.options = this.optionsProvider.options;
  }

  get(key: string): string | undefined {
    const regExp: RegExp = TsCookieUtil.getCookieRegExp(key);
    const result: RegExpExecArray | null = regExp.exec(
      this.cookieWriterService.readAllAsString()
    );

    return result?.[1]
      ? TsCookieUtil.safeDecodeURIComponent(result[1])
      : undefined;
  }

  put(key: string, value: string | undefined, options?: TsCookieOptions): void {
    const opts = TsCookieUtil.mergeOptions(this.options, options);
    this.cookieWriterService.write(key, value, opts);
  }

  remove(key: string, options?: TsCookieOptions): void {
    this.put(key, undefined, options);
  }
}
