/*
 * File: customPreloadingStrategy.ts
 * Author: mafo (maximilian.fossler@teamshufflr.com)"
 * Last Modified: Sat Jan 22 2022
 * -----
 * Copyright (C) 2021, teamshufflr
 * All rights reserved.
 * -----
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { Injectable } from '@angular/core';
import { PreloadingStrategy } from '@angular/router';
import { TsPermissionService } from '@teamshufflr/common/services/permission';
import { Observable, of } from 'rxjs';
import { TsPageRouteTable } from './app.routing.table';
import { TEAMSHUFFLR_PAGE } from './enums/teamshufflr-page';
import { TsCustomRoute } from './interfaces/custom-route';

@Injectable({ providedIn: 'root' })
export class TsCustomPreloadingStrategy implements PreloadingStrategy {
  constructor(private permissionService: TsPermissionService) {}

  preload(
    route: TsCustomRoute,
    fn: () => Observable<unknown>
  ): Observable<unknown> {
    if (route.data && route.data.preload != null) {
      switch (route.path) {
        case TsPageRouteTable.get(
          TEAMSHUFFLR_PAGE.TEAMSHUFFLR_SESSION_SHUFFLE_TEAMS_PAGE
        ).slug:
          if (
            typeof route.data.preload === 'object' &&
            route.data.preload.onlySessionHost === true &&
            this.permissionService.isSessionHost() === false
          ) {
            return of(null);
          }
          return fn();
        default:
          return fn();
      }
    }
    return of(null);
  }
}
