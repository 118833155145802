import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Output,
} from '@angular/core';
import { TsSessionUserProviderService } from '@teamshufflr/common/providers';
import {
  ROUTE_PARAMETER,
  TEAMSHUFFLR_PAGE,
  TsPageRouteTable,
} from '@teamshufflr/common/routing';
import { SessionModel } from '@teamshufflr/core-api-models';

/**
 * A Component to conveniently display all {@link SessionModel recent sessions}
 * the currently signed in user is a member of, using {@link TsSessionUserProviderService.recentSessions$}.
 * Hereby, each session is displayed as {@link TsRecentSessionOverviewCardComponent}.
 *
 * Clicking on one of the cards triggers a navigation to the desired session´s
 * {@link TEAMSHUFFLR_PAGE.TEAMSHUFFLR_SESSION_LOBBY_PAGE}.
 *
 * ___
 * Example usage:
 *
 * ```html
 * <ts-recent-sessions-list></ts-recent-sessions-list>
 * ```
 */
@Component({
  selector: 'ts-recent-sessions-list',
  templateUrl: './recent-sessions-list.component.html',
  styleUrls: ['./recent-sessions-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TsRecentSessionsListComponent {
  @Output()
  sessionSelected = new EventEmitter<SessionModel>();

  constructor(
    public sessionUserProviderService: TsSessionUserProviderService
  ) {}

  /**
   * Returns the resolve page route to the
   * {@link TEAMSHUFFLR_PAGE.TEAMSHUFFLR_SESSION_LOBBY_PAGE}
   * @param sessionID The ID of the {@link SessionUserModel} for which
   * to resolve the page route.
   *
   * @returns
   */
  getUrlOfRecentSession(sessionID: string): string {
    return TsPageRouteTable.resolve(
      TEAMSHUFFLR_PAGE.TEAMSHUFFLR_SESSION_LOBBY_PAGE,
      {
        [ROUTE_PARAMETER.SESSION_ID]: sessionID,
      }
    );
  }
}
