/*
 * File: session-teamshufflr-settings.database.paths.ts
 * Author: mafo (maximilian.fossler@teamshufflr.com)"
 * Last Modified: Wed Dec 22 2021
 * -----
 * Copyright (C) 2021, teamshufflr
 * All rights reserved.
 * -----
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

/*
 * File: session.database.paths.ts                                             *
 * Author: mafo (maximilian.fossler@teamshufflr.com)"                          *
 * Last Modified: Wed Dec 22 2021
 * -----                                                                       *
 * Copyright (C) 2021, teamshufflr                                             *
 * All rights reserved.                                                        *
 * -----                                                                       *
 * Unauthorized copying of this file, via any medium is strictly prohibited    *
 * Proprietary and confidential                                                *
 */

/**
 * All Firebase Database paths related to {@link SessionModel}s.
 */
export abstract class SessionTeamshufflrSettingsDatabasePaths {
  /**
   * Firebase Database Path of the {@link SessionModel}s collection.
   */
  static settingsCollection = 'online-session/teamshufflr-settings';

  /**
   * Firebase Database Path of a single {@link SessionModel} document
   * corresponding to `sessionID`.
   *
   * @param sessionID The ID of the {@link SessionModel} to get the Firebase Database path for.
   * @returns
   */
  static settingsDocument(sessionID: string): string {
    return (
      SessionTeamshufflrSettingsDatabasePaths.settingsCollection +
      '/' +
      sessionID
    );
  }
}
