/*
 * File: cookie-consent-settings.component.ts
 * Author: mafo (maximilian.fossler@teamshufflr.com)"
 * Last Modified: Tue Jan 25 2022
 * -----
 * Copyright (C) 2021 - 2022, teamshufflr
 * All rights reserved.
 * -----
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { KeyValue } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { fadeInOutHeightAnimation } from '@teamshufflr/common/animations';
import { SUPPORTED_LANGUAGE } from '@teamshufflr/core-constants';
import { COOKIE_REGISTER } from '../../constants/cookie-register';
import { COOKIE_CATEGORY } from '../../enums/cookie-category';
import { TsCookieConsents } from '../../interfaces/cookie-consents';
import { TsCookieDefinition } from '../../interfaces/cookie-definition';

@Component({
  selector: 'ts-cookie-consent-settings',
  templateUrl: './cookie-consent-settings.component.html',
  styleUrls: ['./cookie-consent-settings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [fadeInOutHeightAnimation],
})
export class TsCookieConsentSettingsComponent {
  COOKIE_CATEGORY = COOKIE_CATEGORY;
  COOKIE_REGISTER = COOKIE_REGISTER;

  /**
   * The current {@link TsCookieConsents} which may be updated
   * in this component.
   */
  @Input()
  cookieConsents?: TsCookieConsents | null;

  /**
   * The identifier of the currently expanded category section.
   *
   * May be `undefined`, if no section is currently opened.
   */
  expandedCategory?: COOKIE_CATEGORY;

  constructor(public translocoService: TranslocoService) {}

  isCategorySelected(category: string): boolean {
    if (category === COOKIE_CATEGORY.ESSENTIAL) {
      return true;
    } else if (this.cookieConsents != null) {
      const _typedCategory = category as COOKIE_CATEGORY;
      return Object.values(COOKIE_REGISTER[_typedCategory]).every(
        (cookieDefinition) =>
          this.cookieConsents?.[_typedCategory][cookieDefinition.id] === true
      );
    }
    return false;
  }

  /**
   * Whether or not the provided {@link TsCookieDefinition} has currently enabled consent.
   *
   * Defaults to `true` for `COOKIE_CATEGORY.ESSENTIAL`.
   *
   * @param cookieDefinition The {@link TsCookieDefinition} for which to determine whether it has enabled consent.
   */
  isCookieDefinitionEnabled(cookieDefinition: TsCookieDefinition): boolean {
    if (cookieDefinition.category === COOKIE_CATEGORY.ESSENTIAL) {
      return true;
    }

    return (
      this.cookieConsents?.[cookieDefinition.category]?.[
        cookieDefinition.id
      ] === true
    );
  }

  toggleCategoryExpansion(category: string): void {
    if (this.expandedCategory === category) {
      this.expandedCategory = undefined;
    } else {
      this.expandedCategory = category as COOKIE_CATEGORY;
    }
  }

  toggleCategorySelection(category: string): void {
    if (this.cookieConsents == null) {
      return;
    }

    const _typedCategory = category as COOKIE_CATEGORY;

    if (this.isCategorySelected(_typedCategory)) {
      // Disable all cookies of this category.
      this.cookieConsents[_typedCategory] = {};
    } else {
      // Enable all cookies of this category.
      COOKIE_REGISTER[_typedCategory].forEach((cookieDefinition) => {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        this.cookieConsents![_typedCategory][cookieDefinition.id] = true;
      });
    }
  }

  /**
   * Toggles the `cookieDefinition`´s consent status.
   *
   * @param cookieDefinition The {@link TsCookieDefinition} for which to toggle its consent status.
   */
  toggleCookieDefinitionSelection(cookieDefinition: TsCookieDefinition): void {
    if (this.cookieConsents != null) {
      const _currentValue =
        this.cookieConsents?.[cookieDefinition.category]?.[
          cookieDefinition.id
        ] === true;

      this.cookieConsents[cookieDefinition.category][cookieDefinition.id] =
        !_currentValue;
    }
  }

  trackCategorySectionByKey(
    _index: number,
    category: KeyValue<string, Array<TsCookieDefinition>>
  ): string {
    return category.key;
  }

  trackCookieDefinitionByID(
    _index: number,
    cookieDefinition: TsCookieDefinition
  ): string {
    return cookieDefinition.id;
  }

  /**
   * Translates the name of the provided {@link TsCookieDefinition}
   * using the currently active language.
   *
   * @param cookieDefinition THe {@link TsCookieDefinition} which´s name to translate.
   * @returns
   */
  translateCookieDefinitionName(
    cookieDefinition: TsCookieDefinition
  ): string | undefined {
    if (cookieDefinition.name != null) {
      return cookieDefinition.name[
        this.translocoService.getActiveLang() as SUPPORTED_LANGUAGE
      ];
    }
    return;
  }
}
