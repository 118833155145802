/*
 * File: environment.dev.ts                                                    *
 * Author: mafo (maximilian.fossler@teamshufflr.com)"                          *
 * Last Modified: Mon Jan 10 2022
 * -----                                                                       *
 * Copyright (C) 2021, teamshufflr                                             *
 * All rights reserved.                                                        *
 * -----                                                                       *
 * Unauthorized copying of this file, via any medium is strictly prohibited    *
 * Proprietary and confidential                                                *
 */

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { version } from './version';

export const environment = {
  production: true,
  domain: 'https://dev.app.teamshufflr.com',
  version: version,
  firebase: {
    apiKey: 'AIzaSyD7bHk6B_IoP-uCsHfrZFTu9pQyM0mlUzs',
    authDomain: 'teamshufflr-staging.firebaseapp.com',
    databaseURL:
      'https://teamshufflr-staging-default-rtdb.europe-west1.firebasedatabase.app/',
    projectId: 'teamshufflr-staging',
    storageBucket: 'teamshufflr-staging.appspot.com',
    messagingSenderId: '1051681531910',
    appId: '1:1051681531910:web:742f29166f59b45f36fb87',
    measurementId: 'G-D3TC81FDV8',
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
