import { MetaDefinition } from '@angular/platform-browser';

/*
 * File: site-identity.ts
 * Author: mafo (maximilian.fossler@teamshufflr.com)"
 * Last Modified: Sat Jan 22 2022
 * -----
 * Copyright (C) 2021 - 2022, teamshufflr
 * All rights reserved.
 * -----
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
export interface PageIdentity {
  title: string;
  description?: string;
  tags?: MetaDefinition[];
}

export const DEFAULT_PAGE_IDENTITY: Required<PageIdentity> = {
  title: 'pageIdentityModule.default.og:title',
  description: 'pageIdentityModule.default.og:description',
  tags: [
    {
      property: 'og:image',
      content: 'pageIdentityModule.default.og:image',
    },
  ],
};
