/*
 * File: cookies-to-consider.ts
 * Author: mafo (maximilian.fossler@teamshufflr.com)"
 * Last Modified: Mon Jan 24 2022
 * -----
 * Copyright (C) 2021 - 2022, teamshufflr
 * All rights reserved.
 * -----
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { environment } from '@teamshufflr/core-environments';
import { COOKIE_CATEGORY } from '../enums/cookie-category';
import { COOKIE_NAME } from '../enums/cookie-name';
import { COOKIE_TYPE } from '../enums/cookie-type';
import { COOKIE_ID } from '../enums/cookie-id';
import { TsCookieDefinition } from '../interfaces/cookie-definition';

export const COOKIE_REGISTER: {
  [key in COOKIE_CATEGORY]: [TsCookieDefinition];
} = {
  [COOKIE_CATEGORY.ESSENTIAL]: [
    {
      id: COOKIE_ID.ESSENTIAL_TEAMSHUFFLR_COOKIES,
      category: COOKIE_CATEGORY.ESSENTIAL,
      name: {
        de: 'Teamshufflr Essential',
        en: 'Teamshufflr Essential',
      },
      cookieInfos: [
        {
          name: COOKIE_NAME.CONSENT,
          type: COOKIE_TYPE.HTTP_COOKIE,
          duration: 1000 * 60 * 60 * 24 * 365,
          providerUrl: environment.domain,
        },
        {
          name: COOKIE_NAME.PREFERRED_LANGUAGE,
          type: COOKIE_TYPE.HTTP_COOKIE,
          duration: 1000 * 60 * 60 * 24 * 365,
          providerUrl: environment.domain,
        },
      ],
    },
  ],
  [COOKIE_CATEGORY.FUNCTIONAL]: [
    {
      id: COOKIE_ID.FUNCTIONAL_TEAMSHUFFLR_COOKIES,
      category: COOKIE_CATEGORY.FUNCTIONAL,
      name: {
        de: 'Teamshufflr Functional',
        en: 'Teamshufflr Functional',
      },
      cookieInfos: [
        {
          name: COOKIE_NAME.IS_DARK_THEME_PREFERRED,
          type: COOKIE_TYPE.HTTP_COOKIE,
          duration: 1000 * 60 * 60 * 24 * 365,
          providerUrl: environment.domain,
        },
      ],
    },
  ],
  [COOKIE_CATEGORY.STATISTIC]: [
    {
      id: COOKIE_ID.GOOGLE_ANALYTICS_COOKIES,
      category: COOKIE_CATEGORY.STATISTIC,
      name: {
        de: 'Google Analytics',
        en: 'Google Analytics',
      },
      cookieInfos: [
        {
          name: '_ga',
          type: COOKIE_TYPE.HTTP_COOKIE,
          duration: 1000 * 60 * 60 * 24 * 365 * 2,
          providerUrl: environment.domain,
          description: {
            de: '',
            en: '',
          },
        },
        {
          name: '_ga_' + environment.firebase.measurementId.split('G-')[1],
          type: COOKIE_TYPE.HTTP_COOKIE,
          duration: 1000 * 60 * 60 * 24 * 365 * 2,
          providerUrl: environment.domain,
          description: {
            de: '',
            en: '',
          },
        },
        {
          name: '_gid',
          type: COOKIE_TYPE.HTTP_COOKIE,
          duration: 1000 * 60 * 60 * 24,
          providerUrl: environment.domain,
          description: {
            de: '',
            en: '',
          },
        },
        {
          name: '_gat',
          type: COOKIE_TYPE.HTTP_COOKIE,
          duration: 1000 * 60 * 1,
          providerUrl: environment.domain,
          description: {
            de: '',
            en: '',
          },
        },
      ],
    },
  ],
};
