import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TsTranslationModule } from '@teamshufflr/common/services/translation';
import { TsTimeAgoPipe } from './time-ago.pipe';

/**
 * Provides the {@link TsTimeAgoPipe} for transforming a date into
 * a human readable string of how long the provided date is ago
 * (e.g. "4 minutes ago", "2 weeks ago")
 *
 * The resulting readable string is translated using Transloco.
 *
 * ___
 * Example Usage:
 * ```html
 * {{ timestamp | timeAgo }}
 * ```
 *
 * @see {@link TsTimeAgoPipe}
 */
@NgModule({
  declarations: [TsTimeAgoPipe],
  imports: [
    CommonModule,
    TsTranslationModule.withCustomLoader(
      (lang) => import(`../i18n/${lang}.json`),
      {
        translationScope: 'timeAgoModule',
      }
    ),
  ],
  exports: [TsTimeAgoPipe],
})
export class TsTimeAgoModule {}
