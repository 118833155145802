/*
 * File: cookie-util.ts
 * Author: mafo (maximilian.fossler@teamshufflr.com)"
 * Last Modified: Sat Jan 15 2022
 * -----
 * Copyright (C) 2021 - 2022, teamshufflr
 * All rights reserved.
 * -----
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { TsCookieOptions } from './interfaces/cookie-options';

export abstract class TsCookieUtil {
  static buildCookieString(
    name: string,
    value: string | undefined,
    options?: TsCookieOptions
  ): string {
    let expires: string | Date | undefined = options?.expires;
    let val: string;
    if (value == null) {
      expires = 'Thu, 01 Jan 1970 00:00:00 GMT';
      val = '';
    } else {
      val = value as string;
    }
    if (typeof expires == 'string') {
      expires = new Date(expires);
    }
    const cookieValue = options?.storeUnencoded
      ? value
      : encodeURIComponent(val);
    let str = encodeURIComponent(name) + '=' + cookieValue;
    str += options?.path ? ';path=' + options.path : '';
    str += options?.domain ? ';domain=' + options.domain : '';
    str += expires ? ';expires=' + expires.toUTCString() : '';
    str += options?.sameSite ? '; SameSite=' + options.sameSite : '';
    str += options?.secure ? ';secure' : '';
    str += options?.httpOnly ? '; HttpOnly' : '';

    // per http://www.ietf.org/rfc/rfc2109.txt browser must allow at minimum:
    // - 300 cookies
    // - 20 cookies per unique domain
    // - 4096 bytes per cookie
    const cookieLength = str.length + 1;
    if (cookieLength > 4096) {
      console.log(
        "Cookie '" +
          name +
          "' possibly not set or overflowed because it was too large (" +
          cookieLength +
          ' > 4096 bytes)!'
      );
    }
    return str;
  }

  /**
   * Returns the cookie corresponding to `name`.
   *
   * @param name The name of the cookie to return.
   * @returns
   */
  static getCookie(name: string, cookieString?: string): string | null {
    if (cookieString == null) {
      return null;
    }

    const ca: Array<string> = cookieString.split(';');
    const caLen: number = ca.length;
    const cookieName = `${name}=`;
    let c: string;

    for (let i = 0; i < caLen; i += 1) {
      c = ca[i].replace(/^\s+/g, '');
      if (c.indexOf(cookieName) == 0) {
        return c.substring(cookieName.length, c.length);
      }
    }

    return null;
  }
  /**
   * Get cookie Regular Expression
   *
   * @param name Cookie name
   * @returns property RegExp
   */
  static getCookieRegExp(name: string): RegExp {
    const escapedName: string = name.replace(
      // eslint-disable-next-line no-useless-escape
      /([\[\]\{\}\(\)\|\=\;\+\?\,\.\*\^\$])/gi,
      '\\$1'
    );

    return new RegExp(
      '(?:^' + escapedName + '|;\\s*' + escapedName + ')=(.*?)(?:;|$)',
      'g'
    );
  }

  static mergeOptions(
    oldOptions: TsCookieOptions,
    newOptions?: TsCookieOptions
  ): TsCookieOptions {
    if (!newOptions) {
      return oldOptions;
    }
    return {
      path: newOptions.path != null ? newOptions.path : oldOptions.path,
      domain: newOptions.domain != null ? newOptions.domain : oldOptions.domain,
      expires:
        newOptions.expires != null ? newOptions.expires : oldOptions.expires,
      secure: newOptions.secure != null ? newOptions.secure : oldOptions.secure,
      sameSite:
        newOptions.sameSite != null ? newOptions.sameSite : oldOptions.sameSite,
      httpOnly:
        newOptions.httpOnly != null ? newOptions.httpOnly : oldOptions.httpOnly,
      storeUnencoded:
        newOptions.storeUnencoded != null
          ? newOptions.storeUnencoded
          : oldOptions.storeUnencoded,
    };
  }

  /**
   * Gets the unencoded version of an encoded component of a Uniform Resource Identifier (URI).
   *
   * @param encodedURIComponent A value representing an encoded URI component.
   *
   * @returns The unencoded version of an encoded component of a Uniform Resource Identifier (URI).
   */
  static safeDecodeURIComponent(encodedURIComponent: string): string {
    try {
      return decodeURIComponent(encodedURIComponent);
    } catch {
      // probably it is not uri encoded. return as is
      return encodedURIComponent;
    }
  }
}
