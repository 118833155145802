/*
 * File: repositories.module.ts                                                *
 * Author: mafo (maximilian.fossler@teamshufflr.com)"                          *
 * Last Modified: Wed Jan 19 2022
 * -----                                                                       *
 * Copyright (C) 2021, teamshufflr                                             *
 * All rights reserved.                                                        *
 * -----                                                                       *
 * Unauthorized copying of this file, via any medium is strictly prohibited    *
 * Proprietary and confidential                                                *
 */

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { getApp } from '@angular/fire/app';
import {
  connectDatabaseEmulator,
  getDatabase,
  provideDatabase,
} from '@angular/fire/database';
import {
  connectFirestoreEmulator,
  initializeFirestore,
  provideFirestore,
} from '@angular/fire/firestore';
import {
  connectFunctionsEmulator,
  getFunctions,
  provideFunctions,
} from '@angular/fire/functions';
import { environment } from '@teamshufflr/core-environments';

@NgModule({
  imports: [
    CommonModule,
    provideFunctions(() => {
      const functions = getFunctions(getApp(), 'europe-west3');
      if (!environment.production) {
        connectFunctionsEmulator(functions, 'localhost', 5001);
      }
      return functions;
    }),
    provideDatabase(() => {
      const database = getDatabase(getApp());
      if (!environment.production) {
        connectDatabaseEmulator(database, 'localhost', 9000);
      }
      return database;
    }),
    provideFirestore(() => {
      const firestore = initializeFirestore(getApp(), {});
      if (!environment.production) {
        connectFirestoreEmulator(firestore, 'localhost', 8080);
      }
      return firestore;
    }),
  ],
})
export class TsRepositoriesModule {}
