/*
 * File: cookie-options-provider.service.ts
 * Author: mafo (maximilian.fossler@teamshufflr.com)"
 * Last Modified: Sat Jan 15 2022
 * -----
 * Copyright (C) 2021 - 2022, teamshufflr
 * All rights reserved.
 * -----
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { APP_BASE_HREF } from '@angular/common';
import { Inject, Injectable, Injector } from '@angular/core';
import { TsCookieUtil } from '../cookie-util';
import { TsCookieOptions } from '../interfaces/cookie-options';
import { TS_COOKIE_OPTIONS } from '../tokens';

@Injectable()
export class TsCookieOptionsProvider {
  private readonly defaultOptions: TsCookieOptions;
  readonly options: TsCookieOptions;

  constructor(
    @Inject(TS_COOKIE_OPTIONS) options: TsCookieOptions = {},
    private injector: Injector
  ) {
    this.defaultOptions = {
      path: this.injector.get(APP_BASE_HREF, '/'),
      domain: undefined,
      expires: undefined,
      secure: false,
      httpOnly: false,
    };
    this.options = TsCookieUtil.mergeOptions(this.defaultOptions, options);
  }
}
